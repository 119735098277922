import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Typography } from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import { getCycleSelected } from '../../../store/home/fetchSlice';
import { getReports } from '../../../store/groups/thunks';
import { selectReports } from '../../../store/groups/fetchSlice';
import { selectComponentGroupsReportsStatusServer } from '../../../store/groups/componentStatusSelectors';
import { SkeletonGroupsTables } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';

const ReportsTable = () => {
    const [pageSize, setPageSize] = useState(5);
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentGroupsReportsStatusServer
    );
    const Reports = useFetchResource(
        () =>
            getReports({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectReports,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getReports({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectReports,
            [cycleSelected, schoolId]
        );
    };

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbarColumnsButton {...props} />
                <GridToolbarFilterButton {...props} />
                <GridToolbarExport
                    csvOptions={{
                        utf8WithBom: true,
                        delimiter: ',',
                    }}
                />
            </GridToolbarContainer>
        );
    };

    const renderDataGrid = () => {
        const { value } = Reports || {};
        if (!value || typeof value !== 'object') return null;

        const rows = Object.keys(value).map((key, index) => ({
            id: index,
            group: value[key].label,
            report: value[key].data,
        }));

        const columns = [
            {
                field: 'group',
                headerName: 'Grupo',
                flex: 1,
            },
            {
                field: 'report',
                headerName: 'Reporte',
                flex: 2,
            },
        ];

        return (
            <Box sx={{ minHeight: 690, width: '100%', mt: 2 }}>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[]}
                    disableSelectionOnClick
                    disableDensitySelector
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{
                        panel: {
                            placement: 'bottom-end',
                        },
                    }}
                    getRowHeight={() => 'auto'}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            overflow: 'visible',
                            lineHeight: '2',
                        },
                        '&.MuiDataGrid-root': {
                            border: 'none',
                        },
                    }}
                    autoHeight
                />
            </Box>
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && <SkeletonGroupsTables items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        maxWidth: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                    }}
                >
                    <CardContent>
                        <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            sx={{ color: '#333' }}
                        >
                            {Reports?.title ||
                                'Reporte más frecuente por grupo'}
                        </Typography>
                        {renderDataGrid()}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default ReportsTable;
