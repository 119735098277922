import { useSelector } from 'react-redux';
import useThemeColors from './themes';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { useMemo } from 'react';

function reverseTokens(tokens) {
    const reversedTokens = {};
    Object.entries(tokens).forEach(([key, val]) => {
        const keys = Object.keys(val);
        const values = Object.values(val);
        const length = keys.length;
        const reversedObj = {};
        for (let i = 0; i < length; i++) {
            reversedObj[keys[i]] = values[length - i - 1];
        }
        reversedTokens[key] = reversedObj;
    });
    return reversedTokens;
}

const createThemeSettings = (mode, tokensDark, tokensLight) => {
    const applyStyles = (currentMode, styles) => {
        if (currentMode === mode) {
            return styles;
        }
        return {};
    };

    return {
        palette: {
            mode,
            ...(mode === 'dark'
                ? {
                      background: {
                          default: '#28243d',
                          paper: '#28243d',
                      },
                      neutral: {
                          main: tokensDark.neutral[100],
                      },
                  }
                : {
                      background: {
                          default: '#ffffff',
                          paper: '#ffffff',
                      },
                      neutral: {
                          main: tokensLight.neutral[800],
                      },
                  }),
        },

        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        border: 0,
                        WebkitFontSmoothing: 'auto',
                        letterSpacing: 'normal',
                        '& .MuiDataGrid-columnsContainer': {
                            backgroundColor: '#1d1d1d',
                            ...applyStyles('light', {
                                backgroundColor: '#fafafa',
                                color: '#000000ff',
                            }),
                        },
                        '& .MuiDataGrid-columnHeadersInner': {
                            background: '#dedede44',
                        },
                        '& .MuiDataGrid-columnHeaderRow': {
                            backgroundColor: '#1d1d1d',
                        },
                        '& .MuiDataGrid-iconSeparator': {
                            display: 'none',
                        },

                        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                            borderBottom: '1px solid #303030',
                            ...applyStyles('light', {
                                borderBottomColor: '#f0f0f0',
                            }),
                        },
                        '& .MuiDataGrid-cell': {
                            color: 'rgba(255,255,255,0.65)',
                            ...applyStyles('light', {
                                color: 'rgba(0,0,0,.85)',
                            }),
                        },
                        '& .MuiPaginationItem-root': {
                            borderRadius: 0,
                        },
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        '& svg': {
                            width: 16,
                            height: 16,
                            backgroundColor: 'transparent',
                            border: '1px solid #d9d9d9',
                            borderRadius: 2,
                            ...applyStyles('dark', {
                                borderColor: 'rgb(67, 67, 67)',
                            }),
                        },
                        '& svg path': {
                            display: 'none',
                        },
                        '&.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
                            backgroundColor: '#1890ff',
                            borderColor: '#1890ff',
                        },
                        '& .MuiIconButton-label:after': {
                            position: 'absolute',
                            display: 'table',
                            border: '2px solid #fff',
                            borderTop: 0,
                            borderLeft: 0,
                            transform: 'rotate(45deg) translate(-50%,-50%)',
                            opacity: 1,
                            transition:
                                'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
                            content: '""',
                            top: '50%',
                            left: '39%',
                            width: 5.71428571,
                            height: 9.14285714,
                        },
                        '&.MuiCheckbox-indeterminate .MuiIconButton-label:after':
                            {
                                width: 8,
                                height: 8,
                                backgroundColor: '#1890ff',
                                transform: 'none',
                                top: '39%',
                                border: 0,
                            },
                    },
                },
            },
        },
    };
};

const ThemeProviderWrapper = ({ children }) => {
    const mode = useSelector((state) => state.theme.mode);
    const tokensDark = useThemeColors();
    const tokensLight = reverseTokens(tokensDark);

    const theme = useMemo(
        () => createTheme(createThemeSettings(mode, tokensDark, tokensLight)),
        [mode, tokensDark, tokensLight]
    );

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeProviderWrapper;
