import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Card,
    Tooltip,
    Typography,
    CircularProgress,
    CardContent,
} from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectScoresExtracurricular,
} from '../../../store/groups/fetchSlice';
import { getScoresExtracurricular } from '../../../store/groups/thunks';
import { SkeletonGroupsTables } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentGroupsScoresExtracurricularStatusServer } from '../../../store/groups/componentStatusSelectors';
import { Tag } from 'antd';

const ScoresExtracurricular = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentGroupsScoresExtracurricularStatusServer
    );
    const scoresExtracurricular = useFetchResource(
        () =>
            getScoresExtracurricular({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectScoresExtracurricular,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getScoresExtracurricular({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectScoresExtracurricular,
            [cycleSelected, schoolId]
        );
    };

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const data = scoresExtracurricular?.value
        ? scoresExtracurricular?.value.Grado.map((_, index) => ({
              id: index,
              grado: scoresExtracurricular?.value.Grado[index].value,
              grupo: scoresExtracurricular?.value.Grupo[index].value,
              promedio: scoresExtracurricular?.value.Promedio[index].value,
              turno: scoresExtracurricular?.value.Turno[index].value,
          }))
        : [];

    const columns = [
        {
            field: 'grado',
            headerName: 'Grado',
            flex: 0.5,
        },
        {
            field: 'grupo',
            headerName: 'Grupo',
            flex: 1,
        },
        {
            field: 'promedio',
            headerName: 'Promedio',
            flex: 0.7,
            renderCell: (params) => (
                <Tag
                    color="success"
                    style={{
                        width: '100px',
                        textAlign: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                    }}
                >
                    {params.value || '0'}
                </Tag>
            ),
        },
        {
            field: 'turno',
            headerName: 'Turno',
            flex: 0.8,
        },
    ];

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbarColumnsButton {...props} />
                <GridToolbarFilterButton {...props} />
                <GridToolbarExport
                    csvOptions={{
                        utf8WithBom: true,
                        delimiter: ',',
                    }}
                />
            </GridToolbarContainer>
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && <SkeletonGroupsTables items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        maxWidth: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                        mt: -4,
                    }}
                >
                    <CardContent sx={{ padding: '2px' }}>
                        {/* <Tooltip
                            title={
                                scoresExtracurricular?.description ||
                                'Mayores promedios de grupos extraescolares en la escuela'
                            }
                            followCursor
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ color: '#333' }}
                            >
                                {scoresExtracurricular?.title ||
                                    'Mayores promedios de grupos extraescolares'}
                            </Typography>
                        </Tooltip> */}
                        {scoresExtracurricular?.loading ? (
                            <CircularProgress
                                sx={{
                                    margin: '20px',
                                    display: 'block',
                                    mx: 'auto',
                                }}
                            />
                        ) : scoresExtracurricular?.error ? (
                            <Typography
                                color="error"
                                sx={{ textAlign: 'center' }}
                            >
                                Error: {scoresExtracurricular?.error.message}
                            </Typography>
                        ) : (
                            <Box sx={{ minHeight: 575, width: '100%' }}>
                                <DataGrid
                                    localeText={
                                        esES.components.MuiDataGrid.defaultProps
                                            .localeText
                                    }
                                    rows={data}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[]}
                                    disableSelectionOnClick
                                    disableDensitySelector
                                    components={{
                                        Toolbar: CustomToolbar,
                                    }}
                                    componentsProps={{
                                        panel: {
                                            placement: 'bottom-end',
                                        },
                                    }}
                                    getRowHeight={() => 'auto'}
                                    sx={{
                                        '& .MuiDataGrid-cell': {
                                            whiteSpace: 'normal',
                                            wordWrap: 'break-word',
                                            overflow: 'visible',
                                            lineHeight: '3',
                                        },
                                        '&.MuiDataGrid-root': {
                                            border: 'none',
                                        },
                                    }}
                                    autoHeight
                                />
                            </Box>
                        )}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default ScoresExtracurricular;
