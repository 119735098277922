import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Card, CardContent } from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbar,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectAllIncidents,
} from '../../../store/home/fetchSlice';
import { getAllIncidents } from '../../../store/home/thunks';
import { SkeletongGrafics } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeAllIncidentsStatusServer } from '../../../store/home/componentStatusSelectors';
import { ErrorBoundary } from 'react-error-boundary';

const AllIncidents = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeAllIncidentsStatusServer
    );

    const allIncidents = useFetchResource(
        () =>
            getAllIncidents({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectAllIncidents,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getAllIncidents({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectAllIncidents,
            [cycleSelected, schoolId]
        );
    };

    const sortRowsByDate = (rows) => {
        return rows.sort((a, b) => {
            const dateA = new Date(a['Fecha de reporte']);
            const dateB = new Date(b['Fecha de reporte']);
            return dateB - dateA;
        });
    };

    const columns = [
        {
            field: 'Folio de atención',
            headerName: 'Folio de atención',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'Descripción',
            headerName: 'Descripción',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'Estatus',
            headerName: 'Estatus',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <span
                    style={{
                        color: params.value === 'ATENDIDO' ? 'green' : 'red',
                    }}
                >
                    {params.value}
                </span>
            ),
        },
        {
            field: 'Fecha de reporte',
            headerName: 'Fecha de reporte',
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                const date = new Date(params.value);
                return date.toLocaleDateString('es-ES');
            },
        },
        {
            field: 'Usuario',
            headerName: 'Usuario',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'Catálogo',
            headerName: 'Catálogo',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
    ];

    let rows =
        allIncidents?.value && columns.length > 0
            ? allIncidents.value[columns[0].field].map((_, index) => {
                  const row = { id: index };
                  columns.forEach((column) => {
                      row[column.field] =
                          allIncidents.value[column.field][index]?.value;
                  });
                  return row;
              })
            : [];

    rows = sortRowsByDate(rows);

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbarColumnsButton {...props} />
                <GridToolbarFilterButton {...props} />
                <GridToolbarExport
                    csvOptions={{
                        utf8WithBom: true,
                        delimiter: ',',
                    }}
                />
            </GridToolbarContainer>
        );
    };

    return (
        <ErrorBoundary>
            <Box>
                {fetchingStatus === 'pending' && <SkeletongGrafics items={9} />}

                {fetchingStatus === 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}

                {fetchingStatus === 'fulfilled' && (
                    <Card
                        variant="outlined"
                        sx={{
                            borderRadius: 4,
                            boxShadow: 2,
                            width: '100%',
                            overflow: 'hidden',
                        }}
                    >
                        <CardContent sx={{ padding: 3 }}>
                            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                                {allIncidents?.title}
                            </Typography>
                            {rows.length === 0 ? (
                                <Typography
                                    variant="h5"
                                    sx={{ textAlign: 'center' }}
                                >
                                    Información no disponible
                                </Typography>
                            ) : (
                                <Box sx={{ width: '100%' }}>
                                    <DataGrid
                                        localeText={
                                            esES.components.MuiDataGrid
                                                .defaultProps.localeText
                                        }
                                        rows={rows}
                                        columns={columns}
                                        pageSize={10}
                                        rowsPerPageOptions={[]}
                                        disableSelectionOnClick
                                        disableDensitySelector
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                        componentsProps={{
                                            panel: {
                                                placement: 'bottom-end',
                                            },
                                        }}
                                        getRowHeight={() => 'auto'}
                                        sx={{
                                            '& .MuiDataGrid-cell': {
                                                whiteSpace: 'normal',
                                                wordWrap: 'break-word',
                                                overflow: 'visible',
                                                lineHeight: '1.5',
                                            },
                                            '&.MuiDataGrid-root': {
                                                border: 'none',
                                            },
                                        }}
                                        autoHeight
                                    />
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default AllIncidents;
