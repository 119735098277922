import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { useAuth, useFeedback } from '../../../../hooks';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { CreateCatConcepts } from '../../../store/cat_concepts/operations';
import palomita from '../../../../public/assets/palomita.svg';
import { Typography as AntTypography } from 'antd';

const CreateCatalog = ({ open, onclose }) => {
    ///////////// SHERD STATE /////////////

    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const feedbackApp = useFeedback();

    ///////////// LOCAL STATE /////////////

    ///////////// FORM CONFIGURATION /////////////

    const formik = useFormik({
        initialValues: {
            concepts: [{ concept: '', value: '' }],
        },
        validationSchema: Yup.object({
            concepts: Yup.array().of(
                Yup.object({
                    concept: Yup.string().required(
                        'El concepto es obligatorio'
                    ),
                    value: Yup.number()
                        .required('El valor es obligatorio')
                        .positive('El valor debe ser positivo'),
                })
            ),
        }),

        onSubmit: async (values, { resetForm }) => {
            await dispatch(
                CreateCatConcepts({ schoolId, data: values.concepts })
            )
                .unwrap()
                .then(() => {
                    feedbackApp.showFeedback({
                        title: 'Catálogo de conceptos creado',
                    });
                    formik.resetForm();
                    onclose();
                })
                .catch((error) => {
                    feedbackApp.showFeedback({ title: error.feedback.title });
                });
        },
    });

    ///////////// RENDER /////////////

    return (
        <>
            <Dialog
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onclose();
                    }
                }}
                disableEscapeKeyDown
                sx={{
                    '& .MuiDialog-paper': {
                        borderRadius: 5,
                        width: '60%',
                        maxWidth: 700,
                        height: '80%',
                        maxHeight: 500,
                    },
                }}>
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 1,
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                        }}>
                        <Box
                            component="img"
                            src={palomita}
                            alt="Descripción de la imagen"
                            sx={{
                                width: '15%',
                                height: 'auto',
                            }}
                        />
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            sx={{
                                display: 'flex',
                                ml: 2,
                                mr: 2,
                            }}
                        />
                        <Box>
                            <AntTypography.Title level={5}>
                                Agregar Catalogo
                            </AntTypography.Title>
                            <Typography
                                variant="body2"
                                sx={{
                                    width: '100%',
                                    mt: -1,
                                }}>
                                Aquí podrás agregar un nuevo catálogo
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                        }}>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            sx={{
                                display: 'flex',
                                ml: 2,
                                mr: 2,
                            }}
                        />
                        <IconButton
                            onClick={() => {
                                formik.resetForm();
                                onclose();
                            }}>
                            <CloseIcon
                                sx={{
                                    color: '#000000ff',
                                    width: 20,
                                }}
                            />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Divider
                    sx={{
                        width: '95%',
                    }}
                    flexItem
                    variant="middle"
                />
                <FormikProvider value={formik}>
                    <DialogContent>
                        <FieldArray name="concepts">
                            {({ push, remove }) => (
                                <>
                                    {formik.values.concepts.map(
                                        (concept, index) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    backgroundColor:
                                                        theme.palette.background
                                                            .paper,
                                                    padding: 2,
                                                    borderRadius: 2,
                                                    marginBottom: 2,
                                                    boxShadow:
                                                        '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                                    position: 'relative',
                                                }}>
                                                <Box
                                                    sx={{
                                                        display: 'grid',
                                                        gridTemplateColumns:
                                                            '1fr 1fr',
                                                        gap: 2,
                                                    }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Nombre del Concepto"
                                                        variant="outlined"
                                                        id={`concepts.${index}.concept`}
                                                        name={`concepts.${index}.concept`}
                                                        value={
                                                            formik.values
                                                                .concepts[index]
                                                                .concept
                                                        }
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        error={
                                                            formik.touched
                                                                .concepts?.[
                                                                index
                                                            ]?.concept &&
                                                            Boolean(
                                                                formik.errors
                                                                    .concepts?.[
                                                                    index
                                                                ]?.concept
                                                            )
                                                        }
                                                        helperText={
                                                            formik.touched
                                                                .concepts?.[
                                                                index
                                                            ]?.concept &&
                                                            formik.errors
                                                                .concepts?.[
                                                                index
                                                            ]?.concept
                                                        }
                                                        margin="dense"
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        id={`concepts.${index}.value`}
                                                        name={`concepts.${index}.value`}
                                                        placeholder="0.00"
                                                        type="number"
                                                        label="$ Valor Monetario"
                                                        variant="outlined"
                                                        value={
                                                            formik.values
                                                                .concepts[index]
                                                                .value
                                                        }
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        error={
                                                            formik.touched
                                                                .concepts?.[
                                                                index
                                                            ]?.value &&
                                                            Boolean(
                                                                formik.errors
                                                                    .concepts?.[
                                                                    index
                                                                ]?.value
                                                            )
                                                        }
                                                        helperText={
                                                            formik.touched
                                                                .concepts?.[
                                                                index
                                                            ]?.value &&
                                                            formik.errors
                                                                .concepts?.[
                                                                index
                                                            ]?.value
                                                        }
                                                        margin="dense"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    $
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Box>
                                                {formik.values.concepts.length >
                                                    1 && (
                                                    <IconButton
                                                        onClick={() =>
                                                            remove(index)
                                                        }
                                                        sx={{
                                                            position:
                                                                'absolute',
                                                            top: -7,
                                                            right: -2,
                                                            color: theme.palette
                                                                .error.main,
                                                        }}>
                                                        <CloseIcon
                                                            sx={{
                                                                fontSize: 15,
                                                            }}
                                                        />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        )
                                    )}
                                    <Button
                                        type="button"
                                        onClick={() =>
                                            push({ concept: '', value: '' })
                                        }
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        sx={{ mt: 2, borderRadius: 2 }}>
                                        + Agregar Catalogo
                                    </Button>
                                </>
                            )}
                        </FieldArray>
                    </DialogContent>
                </FormikProvider>
                <Divider
                    sx={{
                        width: '95%',
                    }}
                    flexItem
                    variant="middle"
                />
                <DialogActions
                    sx={{
                        justifyContent: 'space-between',
                    }}>
                    <Box sx={{ mb: 2 }}>
                        <Button
                            sx={{ mt: 1, mr: 1 }}
                            onClick={() => {
                                formik.resetForm();
                                onclose();
                            }}>
                            Cancelar
                        </Button>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="small"
                            onClick={formik.handleSubmit}
                            disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.dirty
                            }>
                            {formik.isSubmitting ? (
                                <CircularProgress size={24} />
                            ) : (
                                'Registrar Catálogo'
                            )}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CreateCatalog;
