import React, { useRef } from 'react';
import ImageView from './ImageView';
import { useImage } from '../hooks/useImage';

export const SchoolImageContainer = () => {
    const { schoolId, imageSrc, changePhotoEvent, isLoading } =
        useImage('theme-logo-school');
    const refInputFile = useRef(null);

    const onChangeImage = () => {
        refInputFile.current.click();
    };

    return (
        <>
            <ImageView
                title="Imagen escolar"
                schoolId={schoolId}
                imageSrc={imageSrc}
                onChangeImage={onChangeImage}
                isLoading={isLoading}
                showId={true}
            />
            <input
                ref={refInputFile}
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={changePhotoEvent}
            />
        </>
    );
};

export default SchoolImageContainer;
