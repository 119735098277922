import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Typography,
    Box,
    Card,
    CardContent,
    Tooltip,
    Chip,
} from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectLagCalifications,
} from '../../../store/professors/fetchSlice';
import { getLagCalifications } from '../../../store/professors/thunks';
import { selectComponentProfessorsLagCalificationsStatusServer } from '../../../store/professors/componentsStatusSelectors';
import { SkeletonProfessorsSmallTables } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import _ from 'lodash';
import { Tag } from 'antd';

const LagCalifications = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentProfessorsLagCalificationsStatusServer
    );

    const lagCalifications = useFetchResource(
        () =>
            getLagCalifications({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectLagCalifications,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getLagCalifications({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectLagCalifications,
            [cycleSelected, schoolId]
        );
    };

    const [pageSize, setPageSize] = useState(10);

    const columns = [
        {
            field: 'profesor',
            headerName: 'Profesor',
            flex: 1,
            renderCell: (params) => (
                <Box
                    sx={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                    }}
                >
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'rezago',
            headerName: 'Rezago de Calificaciones',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            renderCell: (params) => (
                <Tag
                    color="error"
                    style={{
                        width: '100px',
                        textAlign: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                    }}
                >
                    {params.value}
                </Tag>
            ),
        },
    ];

    const rows = lagCalifications?.value
        ? lagCalifications.value.map((row, index) => ({
              id: index + 1,
              profesor: row.label,
              rezago: Number(row.data),
          }))
        : [];

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbarColumnsButton {...props} />
                <GridToolbarFilterButton {...props} />
                <GridToolbarExport
                    csvOptions={{
                        utf8WithBom: true,
                        delimiter: ',',
                    }}
                />
            </GridToolbarContainer>
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonProfessorsSmallTables items={9} />
            )}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        borderRadius: '10px',
                        boxShadow: 'none',
                        maxWidth: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                        mt: -4,
                    }}
                >
                    <CardContent
                        sx={{
                            width: '100%',
                            padding: '24px',
                        }}
                    >
                        <Box sx={{ height: 704, width: '100%' }}>
                            <DataGrid
                                localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                        .localeText
                                }
                                rows={rows}
                                columns={columns}
                                pageSize={pageSize}
                                rowsPerPageOptions={[]}
                                disableDensitySelector
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                                componentsProps={{
                                    panel: {
                                        placement: 'bottom-end',
                                    },
                                }}
                                sx={{
                                    '&.MuiDataGrid-root': {
                                        border: 'none',
                                    },
                                    '& .MuiDataGrid-cell': {
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                    },
                                }}
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default LagCalifications;
