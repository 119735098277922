import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    DataGrid,
    esES,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid';
import { Avatar, Box, Card, Typography } from '@mui/material';
import { useFeedback } from '../../../../hooks';
import { selectTableData } from '../../../store/concepts/selectors';
import { CheckOutlined, CloseOutlined } from '@mui/icons-material';
import CreateConcept from './CreateConcept';
import CatalogModal from './CatalogModal';
import { DeleteConcept } from '../../../store/concepts/operations';
import ModalUpdateConcept from './ModalUpdateConcept';
import useThemeColors from '../../../../theme/themes';
import {
    selectStatus_ConceptsFilter,
    setStatusFilter,
} from '../../../store/concepts/ui';

import DeleteSweepTwoToneIcon from '@mui/icons-material/DeleteSweepTwoTone';
import EditRoadTwoToneIcon from '@mui/icons-material/EditRoadTwoTone';
import RunningWithErrorsOutlinedIcon from '@mui/icons-material/RunningWithErrorsOutlined';
import CustomPagination from '../../../components/utilities/CustomPagination';
import { GridToolBar } from '../../../components/utilities/GridToolBar';
import femaleStudent from '../../../assets/img/female_student.png';
import MaleStudent from '../../../assets/img/male_student.png';
import defaultStudent from '../../../assets/img/default_student.png';
import { CONCEPTS_TYPE } from '../../../../service/const';
import { DateTime } from 'luxon';
import NoDataOverlay from '../../../components/utilities/NoDataOverlay';

const ConceptTable = () => {
    ///////////// SHARED STATE /////////////
    const dispatch = useDispatch();
    const colors = useThemeColors();
    const feedbackApp = useFeedback();

    const datatable = useSelector(selectTableData);
    const statusFilter = useSelector(selectStatus_ConceptsFilter);

    ///////////// LOCAL STATE /////////////

    const [open, setOpen] = useState(false);
    const [openCatalog, setOpenCatalog] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    ///////////// HANDLERS /////////////
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenCatalog = () => setOpenCatalog(true);
    const handleCloseCatalog = () => setOpenCatalog(false);

    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    const handleTabChange = (event) => {
        dispatch(setStatusFilter(event));
    };

    const handleSelectionChange = (selection) => {
        const selectedData = datatable.filter((row) =>
            selection.includes(row.concept_id)
        );
        setSelectedRows(selectedData);
        setSelectedIds(selection);
    };

    const handleDeleteSelected = async () => {
        const data = selectedIds.map((concept_id) => ({
            concept_id: concept_id,
        }));

        dispatch(DeleteConcept({ data }))
            .unwrap()
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'Concepto Cancelado',
                    severity: 'success',
                });
                setSelectedRows([]);
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title:
                        error.feedback?.title ||
                        'Error al cancelar el concepto',
                    severity: 'error',
                });
            });
    };

    /////////// COLUMN CONFIG /////////////
    const getStatusColor = (status) => {
        switch (status) {
            case 1:
                return {
                    color: colors.green[200],
                    backgroundColor: colors.green[600],
                    description: 'Pagado',
                    icon: (
                        <CheckOutlined
                            size="small"
                            sx={{
                                color: colors.green[600],
                            }}
                        />
                    ),
                };
            case 2:
                return {
                    color: colors.orange[200],
                    backgroundColor: colors.orange[600],
                    description: 'Pendiente',
                    icon: (
                        <RunningWithErrorsOutlinedIcon
                            size="small"
                            sx={{
                                color: colors.orange[600],
                            }}
                        />
                    ),
                };
            case 3:
                return {
                    color: colors.red[200],
                    backgroundColor: colors.red[600],
                    description: 'Cancelado',
                    icon: (
                        <CloseOutlined
                            size="small"
                            sx={{
                                color: colors.red[600],
                            }}
                        />
                    ),
                };
            default:
                return { color: colors.grey[300], description: 'Desconocido' };
        }
    };

    const columns = [
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            headerName: '',
            flex: 0.1,
            maxWidth: 30,
        },

        {
            field: 'concept_id',
            headerName: 'Folio',
            flex: 0.5,
            minWidth: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                            }}>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <Typography
                                    color="primary"
                                    sx={{
                                        fontWeight: 'bold',
                                    }}>
                                    {params.value}
                                </Typography>
                            </Box>
                        </Box>
                    </>
                );
            },
        },

        {
            field: 'user',
            headerName: 'Alumno',
            flex: 2,
            minWidth: 360,
            renderCell: (params) => {
                const { user } = params.row;

                const avatarSrc =
                    user.url_photo_profile ||
                    (user.gender === 'M'
                        ? femaleStudent
                        : user.gender === 'H'
                            ? MaleStudent
                            : defaultStudent);

                return (
                    <Box display="flex" alignItems="center">
                        <Avatar src={avatarSrc} sx={{ marginRight: 2 }} />
                        <Box>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                title={`${user.name} ${user.last_name} ${user.second_last_name}`}>
                                {`${user.name} ${user.last_name} ${user.second_last_name}`}
                            </Typography>

                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: '0.875rem',
                                    color: 'textSecondary',
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                title={`ID: ${user.student_id}`}>
                                ID: {user.student_id}
                            </Typography>

                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: '0.875rem',
                                    color: 'textSecondary',
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                title={`Teléfono: ${user.cellphone}`}>
                                Teléfono: {user.cellphone}
                            </Typography>
                        </Box>
                    </Box>
                );
            },
            valueGetter: (params) => {
                const { user } = params.row;
                return `${user.name} ${user.last_name} ${user.second_last_name}`;
            },
        },
        {
            field: 'status',
            headerName: 'Estado',
            flex: 1,
            headerAlign: 'center',
            minWidth: 200,
            align: 'center',
            valueGetter: (params) => getStatusColor(params.value).description,

            renderCell: (params) => {
                const getStatusColorCell = (status) => {
                    switch (status) {
                        case 'Pagado':
                            return {
                                color: colors.green[200],
                                backgroundColor: colors.green[600],
                                description: 'Pagado',
                                icon: (
                                    <CheckOutlined
                                        size="small"
                                        sx={{
                                            color: colors.green[600],
                                        }}
                                    />
                                ),
                            };
                        case 'Pendiente':
                            return {
                                color: colors.orange[200],
                                backgroundColor: colors.orange[600],
                                description: 'Pendiente',
                                icon: (
                                    <RunningWithErrorsOutlinedIcon
                                        size="small"
                                        sx={{
                                            color: colors.orange[600],
                                        }}
                                    />
                                ),
                            };
                        case 'Cancelado':
                            return {
                                color: colors.red[200],
                                backgroundColor: colors.red[600],
                                description: 'Cancelado',
                                icon: (
                                    <CloseOutlined
                                        size="small"
                                        sx={{
                                            color: colors.red[600],
                                        }}
                                    />
                                ),
                            };
                        default:
                            return {
                                color: colors.grey[300],
                                description: 'Desconocido',
                            };
                    }
                };
                const termType = getStatusColorCell(params.value);
                return (
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: termType.color,
                        }}>
                        {termType.icon} &nbsp;
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                color: termType.backgroundColor,
                            }}>
                            {termType.description}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'Ultimo_Pago',
            headerName: 'Último Pago',
            flex: 1.5,
            minWidth: 250,
            valueFormatter: (params) => {
                if (!params.value) {
                    return '';
                }
                const date = DateTime.fromSQL(params.value);
                return date.setLocale('es').toLocaleString(DateTime.DATE_FULL);
            },
        },
        {
            field: 'Fecha_limite',
            headerName: 'Fecha Límite',
            flex: 1.5,
            minWidth: 250,
            valueFormatter: (params) => {
                const date = DateTime.fromSQL(params.value);
                return date.setLocale('es').toLocaleString(DateTime.DATE_FULL);
            },
        },

        {
            field: 'concept',
            headerName: 'Concepto',
            flex: 2,
            minWidth: 300,
            headerAlign: 'center',
            renderCell: (params) => {
                const {
                    value: title,
                    row: { description },
                } = params;

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}>
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                color: colors.deepOcean[500],
                                textAlign: 'left',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            title={title}>
                            {title}
                        </Typography>

                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: '0.875rem',
                                color: colors.grey[600],
                                textAlign: 'left',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            title={description}>
                            {description}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'Subtotal',
            headerName: 'Subtotal',
            flex: 1,
            minWidth: 100,
            headerAlign: 'center',

            renderCell: (params) => (
                <Box display="flex" justifyContent="center" width="100%">
                    <Typography
                        variant="h6"
                        sx={{
                            width: '100%',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            textAlign: 'center',
                        }}>
                        ${params.value}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'Recargo',
            headerName: 'Recargo',
            flex: 1,
            minWidth: 100,
            headerAlign: 'center',

            renderCell: (params) => (
                <Box display="flex" justifyContent="center" width="100%">
                    <Typography
                        variant="h6"
                        sx={{
                            color: colors.red[500],
                            width: '100%',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            textAlign: 'center',
                        }}>
                        + ${params.value}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'Descuento',
            headerName: 'Descuento',
            flex: 1,
            minWidth: 100,
            headerAlign: 'center',

            renderCell: (params) => (
                <Box display="flex" justifyContent="center" width="100%">
                    <Typography
                        variant="h6"
                        sx={{
                            color: colors.green[500],
                            width: '100%',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            textAlign: 'center',
                        }}>
                        - ${params.value}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'Total',
            headerName: 'Total',
            flex: 1,
            minWidth: 100,
            headerAlign: 'center',

            renderCell: (params) => (
                <Typography
                    variant="h6"
                    sx={{
                        width: '100%',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        textAlign: 'center',
                    }}>
                    ${params.value}
                </Typography>
            ),
        },
    ];

    const filteredRows = datatable
        .filter((row) => {
            if (statusFilter === CONCEPTS_TYPE.ALL) return true;
            return row.status === statusFilter;
        })
        .map((row) => ({
            in_agreement: row.in_agreement,
            id: row.concept_id,
            user: row.user,
            status: row.status,
            concept_id: row.concept_id,
            Ultimo_Pago: row.last_payment_date,
            Fecha_limite: row.limit_date,
            concept: row.catConcept.concept,
            description: row.description,
            Subtotal: row.subtotal,
            Recargo: row.surcharge_total,
            Descuento: row.discount_total,
            Total: row.net_total,
            date: row.created_at,
        }))
        .sort((a, b) => new Date(b.date) - new Date(a.date));

    const hasCancelledConcepts = selectedRows.some((row) => row.status !== 2);

    const massActions = [
        {
            tooltip: 'Editar',
            icon: <EditRoadTwoToneIcon />,

            color: selectedRows.length > 0 ? '#ffc107' : '#BDBDBD',
            disabled: selectedIds.length === 0 || hasCancelledConcepts,
            onClick: handleOpenEdit,
        },
        {
            tooltip: 'Cancelar',
            icon: <DeleteSweepTwoToneIcon />,

            color:
                selectedIds.length > 0 && !hasCancelledConcepts
                    ? '#f44336'
                    : '#BDBDBD',
            disabled: selectedIds.length === 0 || hasCancelledConcepts,
            onClick: handleDeleteSelected,
        },
    ];

    const tabs = [
        {
            label: 'Todos',
            value: CONCEPTS_TYPE.ALL,
            color: '#1565c0',
            chips: statusFilter,
            onClick: () => handleTabChange(CONCEPTS_TYPE.ALL),
        },
        {
            label: 'Pagados',
            value: CONCEPTS_TYPE.PAID,
            color: colors.green[600],
            chips: statusFilter,
            onClick: () => handleTabChange(CONCEPTS_TYPE.PAID),
        },
        {
            label: 'Pendientes',
            value: CONCEPTS_TYPE.PENDING,
            color: colors.orange[600],
            chips: statusFilter,
            onClick: () => handleTabChange(CONCEPTS_TYPE.PENDING),
        },
        {
            label: 'Cancelados',
            value: CONCEPTS_TYPE.CANCELED,
            color: colors.red[600],
            chips: statusFilter,
            onClick: () => handleTabChange(CONCEPTS_TYPE.CANCELED),
        },
    ];

    const paginationItems = [
        { color: '#F34213', text: 'Con Convenio' },
        { color: '#545863', text: 'Sin Convenio' },
    ];

    return (
        <>
            <Card
                style={{
                    color: 'white',
                    borderRadius: '15px',
                    width: '100%',
                    height: '100%',
                    paddingTop: '10px',
                }}>
                <DataGrid
                    rowHeight={80}
                    rows={filteredRows}
                    columns={columns}
                    autoHeight
                    pageSize={15}
                    rowsPerPageOptions={[10, 25, 50]}
                    checkboxSelection
                    onSelectionModelChange={(newSelection) => {
                        setSelectedIds(newSelection);
                        handleSelectionChange(newSelection);
                    }}
                    selectionModel={selectedIds}
                    disableColumnReorder
                    localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                        toolbarColumns: '',
                        toolbarFilters: '',
                        toolbarDensity: '',
                        toolbarExport: '',
                    }}
                    components={{
                        Toolbar: GridToolBar,
                        Pagination: CustomPagination,
                        NoRowsOverlay: NoDataOverlay,
                        NoResultsOverlay: NoDataOverlay,
                    }}
                    componentsProps={{
                        toolbar: {
                            onAddButtonClick: handleOpen,
                            ButtonText: 'Concepto',
                            onCatalogButtonClick: handleOpenCatalog,
                            CatalogButtonText: 'Catálogo',
                            massActions: massActions,
                            tabs: tabs,
                        },
                        pagination: {
                            items: paginationItems,
                        },
                        noResultsOverlay: {
                            message:
                                'No se encontraron resultados para la búsqueda',
                        },
                        noRowsOverlay: {
                            message: 'No hay conceptos registrados',
                        },
                    }}
                    getCellClassName={(props) => {
                        if (props.field === '__check__') {
                            return props.row.in_agreement
                                ? 'payment_left_border_color-in_agremmet'
                                : 'payment_left_border_color-not_in_agremmet';
                        }
                    }}
                />
            </Card>
            <CreateConcept open={open} onclose={handleClose} />
            <CatalogModal open={openCatalog} onclose={handleCloseCatalog} />
            <ModalUpdateConcept
                open={openEdit}
                onclose={handleCloseEdit}
                selectedRows={selectedRows}
            />
        </>
    );
};

export default ConceptTable;
