import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Services from '../../../service/Connection';
import { USERSUI_EXPIRE_TIME } from '../../../service/const';
import Feedback from '../../../service/Feedback';
import * as _ from 'lodash';
import Authentication from '../../../service/Login';
import { addOneUser, setAllUsers, upsertOneUser } from '../entities/users';
import { v4 as uuidv4 } from 'uuid';
import { upsertManySubjectcatalog } from '../entities/subjectcatalogs';
import { upsertManySubjects } from '../entities/subjects';

const emptyState = {
    data: {
        assesorsGroups: {},
        parentsStudents: {},
        professorsItems: {},
        professorsSubject: {
            items: {}, // Almacenar datos asociados a los UUIDs
            ui: {}, // Solo UUID
            server: {
                expireIn: null,
                fetchingAt: null,
                statusServer: 'idle',
                statusOperation: 'idle',
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
        },
    },
    server: {
        expireIn: null,
        fetchingAt: null,
        statusServer: 'idle',
        statusOperation: 'idle',
        didInvalidate: true,
        feedback: {
            title: null,
            message: null,
            payload: null,
        },
    },
    ui: {
        maestros: { columnField: '', operatorValue: '', value: '' },
        administradores: { columnField: '', operatorValue: '', value: '' },
        padres: { columnField: '', operatorValue: '', value: '' },
        orientadores: { columnField: '', operatorValue: '', value: '' },
        directores: { columnField: '', operatorValue: '', value: '' },
    },
};

/**
 * Slice para el settings UI
 */
export const usersUISlice = createSlice({
    name: 'usersUI',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.server.didInvalidate = true;
        },
        updateFilter: (state, { payload }) => {
            const { value, filter } = payload;
            state.ui = { ...state.ui, [value]: filter };
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        const pendingServerStatus = (state, action) => {
            state.server.statusServer = 'pending';
        };

        /**
         * Termina la carga de informacion
         */
        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.server.expireIn = new Date().setMinutes(
                new Date().getMinutes() + USERSUI_EXPIRE_TIME
            );
            state.server.ferchingAt = Date.now();
            state.server.didInvalidate = false;

            state.server.statusServer = 'fulfilled';
        });
        builder.addCase(loadUI.pending, pendingServerStatus);
        builder.addCase(loadUI.rejected, (state, action) => {
            state.server.statusServer = 'rejected';
            state.server.feedback = action.payload.feedback;
        });

        /////////////////////// ACTUALIZAR USUARIO /////////////////////////

        builder.addCase(updateUser.rejected, (state, action) => {
            state.server.statusOperation = 'rejected';
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled';
        });
        builder.addCase(updateUser.pending, (state, action) => {
            state.server.statusOperation = 'pending';
        });

        /////////////////////// ACTUALIZAR USUARIO /////////////////////////

        builder.addCase(createUser.rejected, (state, action) => {
            state.server.statusOperation = 'rejected';
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(createUser.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled';
        });
        builder.addCase(createUser.pending, (state, action) => {
            state.server.statusOperation = 'pending';
        });

        /////////////////////// ACTUALIZAR CONTRASEÑA DE USUARIO /////////////////////////

        builder.addCase(updatePasswordUser.rejected, (state, action) => {
            state.server.statusOperation = 'rejected';
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(updatePasswordUser.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled';
        });
        builder.addCase(updatePasswordUser.pending, (state, action) => {
            state.server.statusOperation = 'pending';
        });

        /////////////////////// CARGA DE ASESORES EN MODAL /////////////////////////

        builder.addCase(getAssesorsGroups.rejected, (state, action) => {
            state.server.statusOperation = 'rejected';
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(getAssesorsGroups.fulfilled, (state, action) => {
            const { assesorsId, groups } = action.payload;
            state.server.statusOperation = 'fulfilled';
            state.data.assesorsGroups[assesorsId] = {
                groups,
                server: {
                    expireIn: new Date().setMinutes(
                        new Date().getMinutes() + 60
                    ),
                    fetchingAt: Date.now(),
                    statusServer: 'fulfilled',
                },
            };
        });
        builder.addCase(getAssesorsGroups.pending, (state, action) => {
            state.server.statusOperation = 'pending';
        });

        /////////////////////// CARGA DE PADRES EN MODAL /////////////////////////

        builder.addCase(getParentsStudents.rejected, (state, action) => {
            state.server.statusOperation = 'rejected';
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(getParentsStudents.fulfilled, (state, action) => {
            const { parentIds, student } = action.payload;
            state.server.statusOperation = 'fulfilled';
            state.data.parentsStudents[parentIds] = {
                student,
                server: {
                    expireIn: new Date().setMinutes(
                        new Date().getMinutes() + 60
                    ),
                    fetchingAt: Date.now(),
                    statusServer: 'fulfilled',
                },
            };
        });

        builder.addCase(getParentsStudents.pending, (state, action) => {
            state.server.statusOperation = 'pending';
        });

        /////////////////////// CARGA DE PROFESOR EN MODAL /////////////////////////

        builder.addCase(getProfessorsSubject.rejected, (state, action) => {
            state.data.professorsSubject.server.statusServer = 'rejected';
            state.data.professorsSubject.server.feedback =
                action.payload.feedback;
        });
        builder.addCase(getProfessorsSubject.fulfilled, (state, action) => {
            const { id, professorId, subjectsId, catalogSubjectId } =
                action.payload;
            state.data.professorsSubject.server.statusServer = 'fulfilled';
            const newIndex = Object.keys(
                state.data.professorsSubject.ui
            ).length;
            state.data.professorsSubject.ui[newIndex] = id;
            state.data.professorsSubject.items[id] = {
                professorId,
                subjectsId,
                catalogSubjectId,
            };
        });
        builder.addCase(getProfessorsSubject.pending, (state, action) => {
            state.data.professorsSubject.server.statusServer = 'pending';
        });
    },
});

export const { invalidate, updateFilter } = usersUISlice.actions;

export default usersUISlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 *
 * @param {*} state
 * @returns
 */
export const selectUsersUI = (state) => state.usersUI.ui;

export const selectUsersData = (state) => state.usersUI.data;

export const selectUsersServer = (state) => state.usersUI.server;

export const selectStatusServer = (state) => state.usersUI.server.statusServer;

export const selectStatusOperation = (state) =>
    state.usersUI.server.statusOperation;

//////////////// TRUNCKS /////////////////

/**
 * Cargar informacion de la UI
 */
export const loadUI = createAsyncThunk(
    'usersUI/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        let Auth = new Authentication();

        try {
            let professors = await Services.professorsBySchool(schoolId).then(
                (res) => res.data.data
            );
            let parents = await Services.getResourcesPaginatedFromServer(
                'getParentsBySchool',
                [schoolId]
            ).then((res) => res.data);
            let orientador = await Services.getOrientadoresBySchool(
                schoolId
            ).then((res) => res.data.data);
            let administrators = await Services.getAdministratorssBySchool(
                schoolId
            ).then((res) => res.data.data);
            let directors = await Services.getDirectorsBySchool(schoolId).then(
                (res) => res.data.data
            );
            let spokeman = await Services.getVocerosBySchool(schoolId).then(
                (res) => res.data.data
            );

            //Parenst vms
            // let parentsViewmodels = parents.map((parent) => {
            //     return {
            //         id: uuidv4(),
            //         ui: {
            //             user_id: parent.user_id,
            //         },
            //         server: {
            //             expireIn: null,
            //             fetchingAt: null,
            //             statusServer: 'idle',
            //             statusOperation: 'idle',
            //             didInvalidate: true,
            //             feedback: {
            //                 title: null,
            //                 message: null,
            //                 payload: null,
            //             },
            //         },
            //     };
            // });

            // let parentsUUIDS = parentsViewmodels.map(({ id }) => {
            //     return id;
            // });

            let allUsers = _.concat(
                professors,
                parents,
                orientador,
                administrators,
                directors,
                spokeman
            );

            let users = allUsers.map((i) => ({
                ...i,
                user_id: Auth.getUserID(i),
            }));

            thunkAPI.dispatch(setAllUsers(users));

            return users;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = getState().usersUI.server;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

/**
 * Crear usuario
 */
export const createUser = createAsyncThunk(
    'usersUI/user/create',
    async ({ type, schoolId, userData }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        try {
            let user = null;

            switch (type) {
                case 'ADMINISTER':
                    user = await Services.setUserAdministratorsBySchool(
                        schoolId,
                        userData
                    ).then((res) => res.data.data);
                    break;
                case 'PROFESSOR':
                    user = await Services.setUserProfessorBySchool(
                        schoolId,
                        userData
                    ).then((res) => res.data.data);
                    break;
                case 'ASSESSOR':
                    user = await Services.setUserOrientadorBySchool(
                        schoolId,
                        userData
                    ).then((res) => res.data.data);
                    break;
                case 'PARENT':
                    user = await Services.storeParentSchool(
                        schoolId,
                        userData
                    ).then((res) => res.data.data);
                    break;
                case 'SPOKESMAN':
                    user = await Services.setUserVoceroBySchool(
                        schoolId,
                        userData
                    ).then((res) => res.data.data);
                    break;
                case 'DIRECTOR':
                    user = await Services.setUserDirectorBySchool(
                        schoolId,
                        userData
                    ).then((res) => res.data.data);
                    break;
                case 'ADMINISTRATIVE': //Administrativo
                    throw 'No implementado';
                    //user = await Services.setUserAdministratorsBySchool(schoolId, userData).then(res => res.data.data)
                    break;
                case 'TUTOR': //Tutor
                    throw 'No implementado';
                    //user = await Services.setUserAdministratorsBySchool(schoolId, userData).then(res => res.data.data)
                    break;

                default:
                    throw 'No implementado';
                    break;
            }

            Auth.setUser(user);

            user['user_id'] = Auth.getUserID();

            thunkAPI.dispatch(addOneUser(user));

            return user;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar usuario
 */
export const updateUser = createAsyncThunk(
    'usersUI/user/update',
    async ({ userId, permissions, userData }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        try {
            let userPermissionUpdate = await Services.updateUserById(
                userId,
                permissions
            ).then((res) => res.data.data);

            Auth.setUser(userPermissionUpdate);
            let endpoint = Auth.getEndPoint();

            let user = await Services.updateUserData(
                endpoint,
                userId,
                userData
            ).then((res) => res.data.data);

            user['user_id'] = userId;

            thunkAPI.dispatch(upsertOneUser(user));

            return {
                user,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar contraseña
 */
export const updatePasswordUser = createAsyncThunk(
    'usersUI/user/update-password',
    async ({ userId, password, userData }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        try {
            let user = await Services.updateUserById(userId, password).then(
                (res) => res.data.data
            );

            Auth.setUser(user);
            user['user_id'] = userId;
            thunkAPI.dispatch(upsertOneUser(user));

            return {
                user,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Inhabilitar usuario
 */
export const disableUser = createAsyncThunk(
    'usersUI/user/disable',
    async ({ userId, user, data }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        try {
            Auth.setUser(user);
            let endpoint = Auth.getEndPoint();

            let userRespone = await Services.updateUserData(
                endpoint,
                userId,
                data
            ).then((res) => res.data.data);

            userRespone['user_id'] = userId;

            thunkAPI.dispatch(upsertOneUser(userRespone));
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Activar usuario
 */
export const activateUser = createAsyncThunk(
    'usersUI/user/activate',
    async ({ userId, user, data }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        try {
            Auth.setUser(user);
            let endpoint = Auth.getEndPoint();

            let userRespone = await Services.updateUserData(
                endpoint,
                userId,
                data
            ).then((res) => res.data.data);

            userRespone['user_id'] = userId;

            thunkAPI.dispatch(upsertOneUser(userRespone));
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar usuario
 */
export const updateUsername = createAsyncThunk(
    'usersUI/user/update',
    async ({ userId, cellphone }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            let user = await Services.updateUserById(userId, cellphone).then(
                (res) => res.data.data
            );

            user['user_id'] = userId;

            thunkAPI.dispatch(upsertOneUser(user));

            return {
                user,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

export const getAssesorsGroups = createAsyncThunk(
    'usersUI/data/assesorsGroups',
    async (assesorsId, thunkAPI) => {
        try {
            const response = await Services.getSchoolByAssessor(assesorsId);
            return { assesorsId, groups: response.data.data };
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (assesorsId, { getState }) => {
            const state =
                getState().usersUI.data.assesorsGroups[assesorsId]?.server;
            if (!state) return true;
            const { didInvalidate, expireIn } = state;
            return didInvalidate || expireIn < Date.now();
        },
    }
);

export const getParentsStudents = createAsyncThunk(
    'usersUI/data/parentsStudents',
    async (parentIds, thunkAPI) => {
        try {
            const response = await Services.getStudentsByParent(parentIds);
            return { parentIds, student: response.data.data };
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (parentIds, { getState }) => {
            const state =
                getState().usersUI.data.parentsStudents[parentIds]?.server;
            if (!state) return true;
            const { didInvalidate, expireIn } = state;
            return didInvalidate || expireIn < Date.now();
        },
    }
);

export const getProfessorsSubject = createAsyncThunk(
    'usersUI/data/professorsSubject',
    async (professorId, thunkAPI) => {
        console.log('ALAASTAALO');
        try {
            let catalogSubjects = [];

            //Materias
            const subjects = await Services.getSubjectsByProfessor(
                professorId
            ).then((res) => res.data.data);
            console.log(subjects, 'sub');

            //caTALOGO DE MATERIAS

            for (let subject of subjects) {
                let catalogs = await Services.getSubjectCatalogBySubject(
                    subject.subject_id
                ).then((res) => res.data.data);
                console.log(catalogs, 'cat');
                catalogSubjects.concat(catalogs);
            }

            // const uniqueSubjects = [];
            // const subjectIds = [];
            // subjects.forEach(subject => {
            //     if (!subjectIds.includes(subject.subject_id)) {
            //         subjectIds.push(subject.subject_id);
            //         uniqueSubjects.push(subject);
            //     }
            // });

            // thunkAPI.dispatch(upsertManySubjects(uniqueSubjects));

            // const catalogSubjectIds = [...new Set(uniqueSubjects.map(sub => sub.catalog_subject_id))];
            // console.log(catalogSubjectIds, 'ids');

            // const catalogData = await Promise.all(
            //     catalogSubjectIds.map(catalogId => Services.getSubjectCatalogBySubject(catalogId))
            // );
            // const catalogSubjectsDetails = catalogData.map(response => response.data.data);

            // thunkAPI.dispatch(upsertManySubjectcatalog(catalogSubjectsDetails));

            const uniqueId = uuidv4();
            // console.log(catalogSubjectIds, 'Catalogo sin duplicados')
            // console.log(catalogData, 'API')
            // console.log(catalogSubjectsDetails, 'Extrae datos')
            thunkAPI.dispatch(upsertManySubjects(subjects));
            thunkAPI.dispatch(upsertManySubjectcatalog(catalogSubjects));

            return {
                id: uniqueId,
                professorId: professorId,
                //subjectsId: subjectIds,
                //catalogSubjectId: catalogSubjectIds,
            };
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (professorId, { getState }) => {
            const state =
                getState().usersUI.data.professorsSubject[professorId]?.server;
            if (!state) return true;
            const { didInvalidate, expireIn } = state;
            return didInvalidate || expireIn < Date.now();
        },
    }
);

//////////////////////////////////// SELECTOR ////////////////////////////////////////

export const selectAssesorsGroups = (state, assesorsId) =>
    state.usersUI.data.assesorsGroups[assesorsId]?.groups || [];

export const selectAssesorsGroupsServer = (state, assesorsId) =>
    state.usersUI.data.assesorsGroups[assesorsId]?.server || {};

export const selectParentsStudents = (state, parentIds) =>
    state.usersUI.data.parentsStudents[parentIds]?.student || [];

export const selectParentsStudentsServer = (state, parentIds) =>
    state.usersUI.data.parentsStudents[parentIds]?.server || {};

export const selectProfessorsSubjectServerStatusOperation = (
    state,
    professorId
) => {
    return state.usersUI.data.professorsSubject[professorId]?.server
        .statusServer;
};

export const selectProfessorsSubjectServerStatusServer = (state, professorId) =>
    state.usersUI.data.professorsSubject[professorId]?.server.statusServer;

export const selectProfessorsSubject = (state, professorId) => {
    const professorData = Object.values(
        state.usersUI.data.professorsSubject
    ).find((sub) => sub.professorId === professorId);
    return professorData ? professorData.subjects : [];
};

export const selectSubjectsByProfessor = (state, professorId) => {
    const subjectIds =
        state.usersUI.data.professorsSubject[professorId]?.subjects || [];
    return subjectIds.map((id) => state.subjects.entities[id]);
};

export const selectRecordByUniqueId = (state, uniqueId) => {
    const record = state.usersUI.data.professorsSubject.items[uniqueId];

    if (!record) return null;

    return {
        professorId: record.professorId,
        subjectsId: record.subjectsId,
        catalogSubjectIds: record.catalogSubjectId,
    };
};
