import {
    Alert,
    AlertTitle,
    Button,
    Card,
    CardActions,
    CardContent,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material';
import { DataGrid, esES, GridActionsCellItem } from '@mui/x-data-grid';
import { selectPartialsBySchoolId } from '../../../store/slices/entities/partials';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { getLevelName } from '../../../libs/utils';
import DeletePartialModal from './DeletePartialModal';
import ParcialModal from '../../../components/Modal/ModalParcial';
import { useState } from 'react';
import _ from 'lodash';
import { useAuth } from '../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import useFeedback from '../../../hooks/useFeedback';
import * as constants from './../../../service/const';
import { PartialsToolBar, RegulationsToolBar } from './PartialsToolBar';
import { DateTime } from 'luxon';
import {
    selectsettingsUI,
    updatePartialFilter,
    updateRegulationFilter,
} from '../../../store/slices/settingsUI';
import FeatureFlags from '../../../service/FeatureFlags';

/**
 * Muestra una tabla con todos los parcialse de la escuela
 *
 * @returns
 */
const PartialsCard = () => {
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    ///////////// SHARES STATE /////////////

    const featureFlagWeighing = FeatureFlags.isFeatureFlagActive(
        'NEW_WEIGHING_VALUES'
    );

    const AllPartials = useSelector(selectPartialsBySchoolId(schoolId)).map(
        (partial, index, array) => {
            return {
                ...partial,
                isNormal: !partial.is_regularized,
                isLast: index === array.length - 1,
            };
        }
    );

    const filtersUI = useSelector(selectsettingsUI);

    const [regularized, setRegularized] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    let partials = _.filter(AllPartials, ['is_regularized', false]);
    let regulations = _.filter(AllPartials, 'is_regularized');

    const filterPartials = filtersUI.partials.filter;
    const filterRegulations = filtersUI.regulation.filter;

    const filterPartialsByNumberAndDate = (
        partials,
        partialNumber,
        startDate
    ) => {
        return partials.filter((partial) => {
            return (
                partial.partial === partialNumber &&
                partial.start_date === startDate
            );
        });
    };

    if (
        filterPartials &&
        filterPartials.partialNumber &&
        filterPartials.startDate
    ) {
        partials = filterPartialsByNumberAndDate(
            partials,
            filterPartials.partialNumber,
            filterPartials.startDate
        );
    }

    const ordeningPartials = partials.sort((a, b) => {
        return new Date(a.start_date) - new Date(b.start_date);
    });

    ///////////// LOCAL STATE ////////////////

    const [openModal, setOpenModal] = useState(false);

    const [openDeletePartialModal, setOpenDeletePartialModal] = useState(false);
    const [partialDelete, setPartialDelete] = useState(null);

    const [partialSelected, setPartialSelected] = useState(null);

    const columns = [
        {
            field: 'partial',
            headerName: 'Parcial',
            flex: 0.5,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'nivel',
            headerName: 'Nivel',
            type: 'singleSelect',
            valueOptions: constants.levelsNames.map((item) => item.title),
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return getLevelName(params.row.level);
            },
        },
        ...(featureFlagWeighing
            ? [
                  {
                      field: 'weighing',
                      headerName: 'Ponderación',
                      flex: 0.5,
                      minWidth: 100,
                      editable: false,
                      headerAlign: 'center',
                      align: 'center',
                      valueGetter: ({ value }) => value,
                      renderCell: ({ value }) =>
                          value === 0 ? '-' : `${value}%`,
                  },
              ]
            : []),
        {
            field: 'start_date',
            type: 'date',
            headerName: 'Fecha inicio',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => value && new Date(value),
            valueFormatter: ({ value }) => {
                return moment(value).format('DD MMMM YYYY');
            },
        },
        {
            field: 'end_date',
            type: 'date',
            headerName: 'Fecha fin',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => value && new Date(value),
            valueFormatter: ({ value }) => {
                return moment(value).format('DD MMMM YYYY');
            },
        },
        {
            field: 'limit_date',
            type: 'date',
            headerName: 'Fechas límite',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => value && new Date(value),
            valueFormatter: ({ value }) => {
                return moment(value).format('DD MMMM YYYY');
            },
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            sortable: false,
            flex: 0.5,
            minWidth: 100,
            headerAlign: 'center',
            align: 'center',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon color="primary" />}
                    title="Editar"
                    label="Editar"
                    onClick={() => {
                        showUpdatePartialModal(params.id);
                    }}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon color="error" />}
                    title="Eliminar"
                    label="Eliminar"
                    onClick={() => {
                        showDeletePartialModal(params.id);
                    }}
                    showInMenu
                />,
            ],
        },
    ];

    const regulationsColumns = columns.filter(
        (col) => col.field !== 'weighing'
    );

    /////////////// ACTIONS ////////////////

    /**
     * Muestra el modal para eliminar un parcial
     *
     * @param {*} parcialId Id del parcial
     */
    const showDeletePartialModal = (parcialId) => {
        let itemToDelete = _.find(AllPartials, ['partial_id', parcialId]);

        setPartialDelete(itemToDelete);
        setOpenDeletePartialModal(true);
    };

    /**
     * Muestra el modal para actualizar un parcial
     *
     * @param {*} parcialId Id del parcial
     */
    const showUpdatePartialModal = (parcialId) => {
        let itemToUpdate = _.find(AllPartials, ['partial_id', parcialId]);

        setPartialSelected(itemToUpdate);
        setOpenModal(true);
    };

    /**
     * Abrir modal para crear nuevo parcial
     */
    const createNewPartial = (regularized) => {
        setRegularized(regularized);
        setPartialSelected(null);
        setOpenModal(true);
    };

    /**
     * Cerrar modal para eliminar un parcial
     */
    const closeDeletePartialModal = () => {
        setOpenDeletePartialModal(false);
        setPartialDelete(null);
    };

    const disableByWeighing = () => {
        if (featureFlagWeighing) {
            return AllPartials.some((partial) => Number(partial.weighing) > 0)
                ? true
                : false;
        } else {
            return false;
        }
    };

    const handlePartialFilterChange = (event) => {
        let newFilterValues = {};

        event.items.forEach((filter) => {
            const { columnField, operatorValue, value } = filter;

            newFilterValues = {
                columnField,
                operatorValue,
                value,
            };
        });
        dispatch(updatePartialFilter({ filter: newFilterValues }));
    };

    const handleRegulationFilterChange = (event) => {
        let newFilterValues = {};

        event.items.forEach((filter) => {
            const { columnField, operatorValue, value } = filter;

            newFilterValues = {
                columnField,
                operatorValue,
                value,
            };
        });
        dispatch(updateRegulationFilter({ filter: newFilterValues }));
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Card sx={{ mt: 3 }}>
                <CardContent
                    sx={{
                        pl: 0,
                        pr: 0,
                        pb: 0,
                        ' & .MuiDataGrid-root': {
                            border: 'none',
                        },
                    }}
                >
                    <DataGrid
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        rows={ordeningPartials}
                        columns={columns}
                        getRowId={(row) => row.partial_id}
                        pageSize={10}
                        disableSelectionOnClick
                        autoHeight
                        components={{ Toolbar: PartialsToolBar }}
                        disableDensitySelector
                        componentsProps={{
                            panel: {
                                placement: 'bottom-end',
                            },
                        }}
                        getCellClassName={(params) => {
                            if (params.field == 'partial') {
                                return DateTime.now() >=
                                    DateTime.fromSQL(params.row.start_date) &&
                                    DateTime.now() <=
                                        DateTime.fromSQL(params.row.limit_date)
                                    ? 'left-border-green'
                                    : '';
                            }

                            return '';
                        }}
                        onFilterModelChange={(event) =>
                            handlePartialFilterChange(event)
                        }
                        initialState={{
                            filter: {
                                filterModel: {
                                    items: filterPartials
                                        ? [
                                              {
                                                  columnField:
                                                      filterPartials.columnField ||
                                                      '',
                                                  operatorValue:
                                                      filterPartials.operatorValue ||
                                                      '',
                                                  value:
                                                      filterPartials.value ||
                                                      '',
                                              },
                                          ]
                                        : [],
                                },
                            },
                        }}
                    />
                </CardContent>
                <CardActions
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: featureFlagWeighing
                            ? 'space-between'
                            : 'flex-end',
                    }}
                >
                    {featureFlagWeighing && (
                        <Stack
                            direction={'row'}
                            spacing={1}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Button
                                onClick={handleClick}
                                variant="contained"
                                size="small"
                                startIcon={<HelpOutlineIcon />}
                            >
                                Ponderaciones
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem>
                                    <Alert severity="warning">
                                        <AlertTitle>
                                            Información de ponderaciones
                                        </AlertTitle>
                                        Solo un parcial puede tener ponderación{' '}
                                        <br />
                                        De preferencia solo el más reciente o
                                        último capturado
                                        <br />
                                        El máximo capturable de ponderación es
                                        99
                                        <br />
                                        Una vez exista un parcial con
                                        ponderación no se podrá agregar
                                        parciales nuevos
                                        <br />
                                        Para habilitar la creación de parciales
                                        es necesario quitar las ponderaciones si
                                        existe alguna capturada
                                    </Alert>
                                </MenuItem>
                            </Menu>
                        </Stack>
                    )}

                    <Button
                        onClick={() => {
                            createNewPartial(false);
                        }}
                        disabled={disableByWeighing()}
                        variant="contained"
                        size="small"
                        startIcon={<AddIcon />}
                    >
                        Agregar
                    </Button>
                </CardActions>
            </Card>

            <Card sx={{ mt: 3 }}>
                <CardContent
                    sx={{
                        pl: 0,
                        pr: 0,
                        pb: 0,
                        ' & .MuiDataGrid-root': {
                            border: 'none',
                        },
                    }}
                >
                    <DataGrid
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        rows={regulations}
                        columns={regulationsColumns}
                        getRowId={(row) => row.partial_id}
                        pageSize={10}
                        disableSelectionOnClick
                        autoHeight
                        components={{ Toolbar: RegulationsToolBar }}
                        disableDensitySelector
                        componentsProps={{
                            panel: {
                                placement: 'bottom-end',
                            },
                        }}
                        getCellClassName={(params) => {
                            if (params.field == 'partial') {
                                return DateTime.now() >=
                                    DateTime.fromSQL(params.row.start_date) &&
                                    DateTime.now() <=
                                        DateTime.fromSQL(params.row.limit_date)
                                    ? 'left-border-green'
                                    : '';
                            }

                            return '';
                        }}
                        onFilterModelChange={(event) =>
                            handleRegulationFilterChange(event)
                        }
                        initialState={{
                            filter: {
                                filterModel: {
                                    items: filterRegulations
                                        ? [
                                              {
                                                  columnField:
                                                      filterRegulations.columnField ||
                                                      '',
                                                  operatorValue:
                                                      filterRegulations.operatorValue ||
                                                      '',
                                                  value:
                                                      filterRegulations.value ||
                                                      '',
                                              },
                                          ]
                                        : [],
                                },
                            },
                        }}
                    />
                </CardContent>
                <CardActions
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        onClick={() => {
                            createNewPartial(true);
                        }}
                        variant="contained"
                        size="small"
                        startIcon={<AddIcon />}
                    >
                        Agregar
                    </Button>
                </CardActions>
            </Card>

            <DeletePartialModal
                open={openDeletePartialModal}
                onClose={closeDeletePartialModal}
                partial={partialDelete}
            />

            <ParcialModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                partialItem={partialSelected}
                regularized={regularized}
                allowWeighing={
                    ordeningPartials.filter(
                        (partial) =>
                            partial.isLast &&
                            partial.partial_id ===
                                partialSelected?.partial_id &&
                            partial.isNormal
                    ).length ||
                    (partialSelected === null && regularized === false)
                }
            />
        </>
    );
};

export default PartialsCard;
