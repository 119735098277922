import React, {
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { DataGrid, esES } from '@mui/x-data-grid';
import {
    Button,
    Card,
    CardActions,
    Tooltip,
    IconButton,
    ListItemButton,
    ListItemIcon,
    Collapse,
    ListItemText,
    List,
    TextField,
    FormControl,
    InputLabel,
    Select,
    ButtonGroup,
} from '@mui/material';
import { green, yellow, red } from '@mui/material/colors';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/system';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth } from '../../hooks';
import {
    invalidate,
    loadUI,
    selectStatusServer,
} from '../../store/slices/studentsUI/serverSlice';
import { selectAllStudentsItems } from '../../store/slices/studentsUI/ItemsSlice';
import { selectAllGroups } from '../../store/slices/entities/groups';
import { Error } from '../../components/Feedback';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';
import { selectStudentItem } from '../../store/slices/studentsUI/uiSlice';
import StudentGroupsModal from './components/StudentGroupsModal';
import StudentSubjectsModal from './components/StudentSubjectsModal';
import StudentParentsModal from './components/StudentParentsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChalkboardUser,
    faPeopleRoof,
    faUserCheck,
    faUsersViewfinder,
    faUsersRays,
} from '@fortawesome/free-solid-svg-icons';
import AddStudentModal from './components/AddStudentModal';
import UpdateStudentStepperModal from './components/UpdateStudentStepperModal';
import DeleteStudentModal from '../../components/Modal/DeleteStudentModal';
import AddParentModal from './components/AddParentModal';
import UpdateParentModal from './components/UpdateParentModal';
import DeleteStudentFromGroupModal from '../../components/Modal/DeleteStudentFromGroupModal';
import SkeletonPage from './components/SkeletonPage';
import SyncIcon from '@mui/icons-material/Sync';
import AssignGroupsStudentsModal from './components/AssignGroupsStudentsModal';
import ChangeSpecialGroupsModal from './components/ChangeSpecialGroupsModal';
import useFeedback from '../../hooks/useFeedback';
import AddSpecialGroupsModal from './components/AddSpecialGroupsModal';
import ActivateStudentModal from './components/ActivateStudentModal';
import Avatar from '@mui/material/Avatar';
import DefaultMaleImage from '../../assets/images/default-image-niño.svg';
import DefaultFemaleImage from '../../assets/images/default-image-niña.svg';
import Services from './../../service/Connection';
import { upsertOneStudent } from '../../store/slices/entities/students';
import FeatureFlags from '../../service/FeatureFlags';
import AddTracedStudentModal from './components/AddTracedStudentModal';
import AddStudentStepperModal from './components/AddStudentStepperModal';
import DeleteParentModal from './components/DeleteParentModal';
import ExportableModal from '../../components/Chart/ExportableModal';
import ExportablesModal from '../../components/Chart/ExportableModal/ExportablesModal';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import GroupsIcon from '@mui/icons-material/Groups';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { StudentsToolBar } from './components/StudentsToolBar';
import Switch from '@mui/material/Switch';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { getTurnLevel } from '../../libs/utils';

import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import AssessmentIcon from '@mui/icons-material/Assessment';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import SchoolIcon from '@mui/icons-material/School';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import VpnLockIcon from '@mui/icons-material/VpnLock';

import WeekendIcon from '@mui/icons-material/Weekend';
import BadgeIcon from '@mui/icons-material/Badge';

import ContactMailIcon from '@mui/icons-material/ContactMail';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import FeedIcon from '@mui/icons-material/Feed';

import {
    selectColumnField,
    selectOperatorValue,
    selectValue,
} from '../../store/slices/studentsUI/uiSlice';
import _ from 'lodash';

/**
 * Pagina encargada de los alumnos
 *
 * @returns
 */
const Alumnos = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const Auth = useAuth();
    const feedbackApp = useFeedback();

    //////////////// LOCAL STATE /////////////////////

    /**
     * Estados para modals
     */
    const [openSubjectsModal, setOpenSubjectsModal] = useState(false);
    const [openaddStudentModal, setOpenaddStudentModal] = useState(false);
    const [openAddTracedStudentModal, setAddOpenTracedStudentModal] =
        useState(false);
    const [openAddStudentStepperModal, setOpenAddStudentStepperModal] =
        useState(false);
    const [openRegistaerParentModal, setOpenRegistaerParentModal] =
        useState(false);
    const [openAssignGroupsStudentsModal, setOpenAssignGroupsStudentsModal] =
        useState(false);
    const [openChangeSpecialGroupsModal, setOpenChangeSpecialGroupsModal] =
        useState(false);
    const [openAddSpecialGroupsModal, setOpenAddSpecialGroupsModal] =
        useState(false);
    const [openActivateModal, setOpenActivateModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openParentsModal, setOpenParentsModal] = useState(false);
    const [openUpdateParentModal, setOpenUpdateParentModal] = useState(false);
    const [openDeleteParentModal, setOpenDeleteParentModal] = useState(false);
    const [openGroupsModal, setOpenGroupsModal] = useState(false);
    const [openDeleteStudentGroupModal, setOpenDeleteStudentGroupModal] =
        useState(false);
    const [studentSelected, setStudentSelected] = useState(null);
    const [studentId, setStudentId] = useState(null);
    const [userData, setUserData] = useState({
        userId: null,
        relationshipId: null,
    });
    const [deleteGroupData, setDeleteGroupData] = useState({
        studentId: null,
        groupId: null,
    });

    //////////////////// SHARED STATE ////////////////////

    const students = useSelector(selectAllStudentsItems);
    const groups = useSelector(selectAllGroups);
    const statusServer = useSelector(selectStatusServer);

    const combinedData = students.map((student) => ({
        ...student,
        groupInfo: groups.find((group) => group.group_id === student.group_id),
    }));

    const compareStudents = (studentA, studentB) => {
        const groupA = studentA.groupInfo || {};
        const groupB = studentB.groupInfo || {};

        const turnA = groupA.turn?.toString();
        const turnB = groupB.turn?.toString();

        return !turnA && !turnB
            ? studentA.name.localeCompare(studentB.name)
            : !turnA
            ? 1
            : !turnB
            ? -1
            : turnA !== turnB
            ? turnA.localeCompare(turnB)
            : groupA.grade !== groupB.grade
            ? groupA.grade - groupB.grade
            : groupA.group !== groupB.group
            ? groupA.group.localeCompare(groupB.group)
            : studentA.last_name !== studentB.last_name
            ? studentA.last_name.localeCompare(studentB.last_name)
            : studentA.second_last_name !== studentB.second_last_name
            ? studentA.second_last_name.localeCompare(studentB.second_last_name)
            : studentA.name.localeCompare(studentB.name);
    };

    combinedData.sort(compareStudents);

    const {
        columnField,
        operatorValue,
        value: filterValue,
    } = useSelector((state) => state.studentsUI.ui);

    /////////////////////// FUNCIONES ////////////////////

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate());
        reload();
    };

    /**
     * Funcion para recargar los datos del usuario
     */
    const reload = () => {
        dispatch(loadUI(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    /**
     * Inicia el proceso de recuperacion de datos
     */
    useEffect(() => {
        dispatch(loadUI(Auth.getUser().school_id));
    }, [history]);

    /**
     * Abrir el modal de alumnos
     */
    const showAddStudentModal = (student) => {
        setStudentSelected(student);
        setOpenaddStudentModal(true);
    };

    const showAddTracedStudentModal = () => {
        setAddOpenTracedStudentModal(true);
    };

    /**
     * Abrir el modal para actualizar un alumno
     */
    const showAddStudentStepperModal = (student) => {
        setStudentSelected(student);
        setOpenAddStudentStepperModal(true);
    };

    /**
     * Abrir el modal para asignar un grupo
     */
    const showAssignGroupsStudentsModal = (studentId) => {
        setStudentId(studentId);
        setOpenAssignGroupsStudentsModal(true);
    };

    /**
     * Abrir el modal para activar alumno
     */
    const showActivateStudentModal = (studentId) => {
        setStudentId(studentId);
        setOpenActivateModal(true);
    };

    /**
     * Abrir el modal para asignar un grupo
     */
    const showAssignSpecialGroupsStudentsModal = (studentId) => {
        setStudentId(studentId);
        setOpenChangeSpecialGroupsModal(true);
    };

    /**
     * Abrir el modal para agergar un grupo especial
     */
    const showOpenAddSpecialGroupsModal = (studentId) => {
        setStudentId(studentId);
        setOpenAddSpecialGroupsModal(true);
    };

    /**
     * Muestra los modal disponibles
     */
    const showModal = (studentId, modal) => (event) => {
        dispatch(selectStudentItem(studentId));
        if (modal == 'groups') {
            setOpenGroupsModal(true);
        } else if (modal == 'subjects') {
            setOpenSubjectsModal(true);
        } else if (modal == 'parents') {
            setOpenParentsModal(true);
        }
    };

    /**
     * Mostrar modal para dar de baja un alumno del sistema
     *
     * @param {*} studentId
     */
    const showOpenDeleteStudentModal = (studentId) => {
        setStudentId(studentId);
        setOpenDeleteModal(true);
    };

    /**
     * Mostrar el modal para registrar un padre
     */
    const showOpenRegistaerParentModal = (studentId) => {
        setStudentId(studentId);
        setOpenRegistaerParentModal(true);
    };

    /**
     * Mostrar el modal para editar el padre
     */
    const showEditParentModal = (userId, relationshipId) => {
        setUserData({
            userId,
            relationshipId,
        });
        setOpenUpdateParentModal(true);
    };

    const showDeleteParentModal = (userId, relationshipId) => {
        setUserData({
            userId,
            relationshipId,
        });
        setOpenDeleteParentModal(true);
    };
    /**
     * Mostrar modal para eliminar alumno
     */
    const showDeleteGroupModal = (studentId, groupId) => {
        setDeleteGroupData({
            studentId: studentId,
            groupId: groupId,
        });

        setOpenDeleteStudentGroupModal(true);
    };

    /**
     * Llamado si el alumno fue eliminado del grupo
     */
    const onStudentStudentDeleted = () => {
        feedbackApp.showFeedback({
            title: 'El alumno fue eliminado del grupo',
        });
    };

    /////////////////////////////////////////////////////////////////
    /**
     * Abre modal para seleccionar imagen
     * @param {*} userId
     * @returns
     */
    const handleAvatarClick = (userId) =>
        document.getElementById(`upload-avatar-${userId}`).click();

    /**
     * Actualiza la foto
     * @param {*} studentId
     * @param {*} image
     */
    const saveStudentImage = (studentId, image) => {
        if (image instanceof File && image.size > 0) {
            const formData = new FormData();
            formData.append('studentId', studentId);
            formData.append('url_photo_profile', image);
            formData.append('_method', 'PATCH');

            Services.uploadStudentImage(studentId, formData)
                .then((data) => {
                    dispatch(upsertOneStudent(data.data.data));
                    feedbackApp.showFeedback({
                        title: 'Actualizado correctamente',
                    });
                })
                .catch((error) => {
                    feedbackApp.showFeedback({
                        title: 'Imagen no pudo ser cargada',
                    });
                });
        } else {
            feedbackApp.showFeedback({
                title: 'No se pudo cargar la imagen',
            });
        }
    };

    /**
     * Trae imagen seleccionada
     * @param {*} event
     * @param {*} studentId
     */
    const handleImageUpload = (event, studentId) => {
        const file = event.target.files[0];
        saveStudentImage(studentId, file);
    };

    /////////////// EXPORT ////////////////

    const featureFlagEnabled =
        FeatureFlags.isFeatureFlagActive('NEW_REPORTS_UI');
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [open, setOpen] = useState(false);
    const [reportData, setReportData] = useState({
        report: null,
        title: null,
        query: { student_id: null, group_id: null },
    });

    const onCloseExportableModal = () => {
        setOpen(false);
    };

    /**
     * Exportar la informacion de los Tops Cards
     *
     * @param {*} exportOptions
     */
    const onExport = (exportOptions) => {
        const { id, title, report, groupId } = exportOptions;
        const query = {
            ...reportData.query,
            student_id: id,
            group_id: groupId,
            school_id: Auth.getUser().school_id,
        };
        const pre = { ...reportData, query };
        const data = { ...pre, title, report, ...exportOptions };

        setSelectedTitle(title);
        setReportData(data);
        setOpen(true);
    };

    /////////////////////////// FILTRO SWITCH ///////////////////////////////////

    function SwitchInputValue(props) {
        const { item, applyValue, focusElementRef } = props;

        const switchRef = React.useRef(null);
        React.useImperativeHandle(focusElementRef, () => ({
            focus: () => {
                switchRef.current.focus();
            },
        }));

        const handleFilterChange = (event, newValue) => {
            // Filtra por '1' si el Switch está activado

            const filterValue = newValue ? '1' : '';
            applyValue({ ...item, value: filterValue });
        };

        return (
            <Box
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: 48,
                    pl: '20px',
                }}
            >
                <Switch
                    checked={item.value === '1'} // Mapea el valor '1' a true y cualquier otro valor a false
                    onChange={handleFilterChange}
                    inputProps={{ ref: switchRef }}
                />
            </Box>
        );
    }

    const switchOnlyOperators = [
        {
            label: 'Activo',
            value: 'active',
            getApplyFilterFn: (filterItem) => {
                return (params) => {
                    if (
                        filterItem.value == '1' &&
                        params.formattedValue == '0'
                    ) {
                        // Si filterItem.value es '1' y params.formattedValue es '0', oculta el elemento.
                        return false;
                    }
                    return true;
                };
            },
            InputComponent: SwitchInputValue,
            InputComponentProps: { type: 'boolean' },
            getValueAsString: (value) => (value ? '1' : '0'),
        },
        {
            label: 'Inactivo',
            value: 'disable',
            getApplyFilterFn: (filterItem) => {
                return (params) => {
                    if (filterItem.value == '1') {
                        return (
                            params.formattedValue == '0' ||
                            params.formattedValue == ''
                        );
                    }
                    return true;
                };
            },
            InputComponent: SwitchInputValue,
            InputComponentProps: { type: 'boolean' },
            getValueAsString: (value) => (value ? '1' : '0'),
        },
    ];

    ////////////////////// FOLTRO SWITCH CURP //////////////////////////

    const TextInputValue = (props) => {
        const { item, applyValue, focusElementRef } = props;

        const inputRef = useRef(null);
        const [localValue, setLocalValue] = useState(item.value || '');

        useImperativeHandle(focusElementRef, () => ({
            focus: () => {
                inputRef.current.focus();
            },
        }));

        const handleDebouncedFilterChange = useCallback(
            _.debounce((newValue) => {
                applyValue({ ...item, value: newValue.toLowerCase() });
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 500),
            [item, applyValue]
        );

        const handleFilterChange = (event) => {
            const newValue = event.target.value;
            setLocalValue(newValue);
            handleDebouncedFilterChange(newValue);
        };

        useEffect(() => {
            return () => {
                handleDebouncedFilterChange.cancel();
            };
        }, [handleDebouncedFilterChange]);

        useEffect(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, [localValue]);

        return (
            <Box
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: 48,
                    pl: '5px',
                    mt: '8px',
                }}
            >
                <TextField
                    value={localValue}
                    onChange={handleFilterChange}
                    inputProps={{ ref: inputRef }}
                    variant="standard"
                    placeholder="Buscar CURP"
                />
            </Box>
        );
    };

    const normalizeString = (str) => (str ? str.toLowerCase() : '');

    const seatchCURPOperatos = {
        label: 'Buscar CURP',
        value: 'searchCurp',
        getApplyFilterFn: (filterItem) => {
            return (params) => {
                if (filterItem.value) {
                    const normalizedFilterValue = normalizeString(
                        filterItem.value
                    );
                    const normalizedCurpValue = normalizeString(
                        params.row?.curp
                    );
                    return normalizedCurpValue.includes(normalizedFilterValue);
                }
                return true;
            };
        },
        InputComponent: TextInputValue,
        InputComponentProps: { type: 'text' },
        getValueAsString: (value) => value || '',
    };

    const curpSwitchOperator = [
        {
            label: 'Con CURP',
            value: 'hasCurp',
            getApplyFilterFn: (filterItem) => {
                return (params) => {
                    if (filterItem.value === '1') {
                        return (
                            params.row &&
                            params.row.curp &&
                            params.row.curp.trim() !== ''
                        );
                    }
                    return true;
                };
            },
            InputComponent: SwitchInputValue,
            InputComponentProps: { type: 'boolean' },
            getValueAsString: (value) => (value ? '1' : '0'),
        },
        {
            label: 'Sin CURP',
            value: 'noCurp',
            getApplyFilterFn: (filterItem) => {
                return (params) => {
                    if (filterItem.value === '1') {
                        return (
                            !params.row ||
                            !params.row.curp ||
                            params.row.curp.trim() === ''
                        );
                    }
                    return true;
                };
            },
            InputComponent: SwitchInputValue,
            InputComponentProps: { type: 'boolean' },
            getValueAsString: (value) => (value ? '1' : '0'),
        },
    ];

    ////////////////////// PRINCIPAL GROUP CUSTOMFILTER //////////////////////////

    const getUniqueCombinations = (data) => {
        const uniqueCombinations = new Set();

        data.forEach((element) => {
            const groupInfo = element.groupInfo;

            if (groupInfo) {
                const { group = '', grade = '', turn } = groupInfo;
                const turnLevel = getTurnLevel(turn) || '';
                const combinedValue = `${grade}° ${group} ${turnLevel}`;
                uniqueCombinations.add(combinedValue);
            }
        });

        return [...uniqueCombinations];
    };

    const PrincipalGroupFilterInput = (props) => {
        const { item, applyValue } = props;

        const handleFilterChange = (event) => {
            const inputValue = event.target.value;
            applyValue({ ...item, value: inputValue });
        };

        let inputComponent;
        if (item.operatorValue === 'turn') {
            inputComponent = (
                <FormControl fullWidth variant="standard">
                    <Select
                        label="Selecciona Turno"
                        onChange={handleFilterChange}
                        value={item.value || ''}
                    >
                        <MenuItem value="">Selecciona Turno</MenuItem>
                        <MenuItem value="Matutino">Matutino</MenuItem>
                        <MenuItem value="Vespertino">Vespertino</MenuItem>
                        <MenuItem value="Nocturno">Nocturno</MenuItem>
                    </Select>
                </FormControl>
            );
        } else if (item.operatorValue === 'todos') {
            const uniqueCombinationsArray = getUniqueCombinations(combinedData);
            inputComponent = (
                <FormControl fullWidth variant="standard">
                    <InputLabel id="demo-simple-select-label">
                        Grupos Principales
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Grupos Principales"
                        onChange={handleFilterChange}
                        value={item.value || ''}
                    >
                        <MenuItem value="">Grupos Principales</MenuItem>
                        {uniqueCombinationsArray?.map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        } else {
            inputComponent = (
                <TextField
                    label="Valor"
                    variant="standard"
                    onChange={handleFilterChange}
                    value={item.value || ''}
                />
            );
        }

        return (
            <Box
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: 48,
                    pl: '20px',
                }}
            >
                {inputComponent}
            </Box>
        );
    };

    const PrincipalGroupOperators = [
        {
            label: 'Grado',
            value: 'grade',
            type: 'text',
            getApplyFilterFn: (filterItem) => (params) => {
                const inputValue = filterItem.value;
                const gradeValue = String(params.row.groupInfo?.grade || '');
                return gradeValue.includes(inputValue);
            },
            InputComponent: PrincipalGroupFilterInput,
            getValueAsString: (value) => value.toString(),
        },
        {
            label: 'Grupo',
            value: 'group',
            type: 'text',
            getApplyFilterFn: (filterItem) => (params) => {
                const inputValue = filterItem.value;
                const groupValue = params.row.groupInfo?.group || '';
                return groupValue.includes(inputValue);
            },
            InputComponent: PrincipalGroupFilterInput,
            getValueAsString: (value) => value.toString(),
        },
        {
            label: 'Turno',
            value: 'turn',
            type: 'turn',
            getApplyFilterFn: (filterItem) => (params) => {
                const inputValue = filterItem.value;
                const turnValue =
                    getTurnLevel(params.row.groupInfo?.turn) || '';
                return turnValue.includes(inputValue);
            },
            InputComponent: PrincipalGroupFilterInput,
            getValueAsString: (value) => value.toString(),
        },
        {
            label: 'Todos',
            value: 'todos',
            type: 'todos',
            getApplyFilterFn: (filterItem) => (params) => {
                const inputValue = filterItem.value;
                const gradeValue = String(params.row.groupInfo?.grade || '');
                const groupValue = params.row.groupInfo?.group || '';
                const turnValue =
                    getTurnLevel(params.row.groupInfo?.turn) || '';
                const combinedValue = `${gradeValue}° ${groupValue} ${turnValue}`;
                return combinedValue.includes(inputValue);
            },
            InputComponent: PrincipalGroupFilterInput,
            getValueAsString: (value) => value.toString(),
        },
    ];

    const BasicMenu = ({ params }) => {
        const isFeatureFlagActive =
            FeatureFlags.isFeatureFlagActive('NEW_REPORTS_UI');
        const isFeatureFlagActiveExports = FeatureFlags.isFeatureFlagActive(
            'NEW_EXPORT_DOCUMENTS'
        );
        const [anchorEl, setAnchorEl] = useState(null);
        const [openGrupos, setOpenGrupos] = useState(false);
        const [openExportaciones, setOpenExportaciones] = useState(false);
        const [OpenStudentConfig, setOpenStudentConfig] = useState(false);
        const [OpenDisableStudent, setOpenDisableStudent] = useState(false);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleClickGrupos = () => {
            setOpenGrupos(!openGrupos);
        };

        const handleClickStudentConfig = () => {
            setOpenStudentConfig(!OpenStudentConfig);
        };

        const handleClickExportaciones = () => {
            setOpenExportaciones(!openExportaciones);
        };

        const handleClickDisableStudent = () => {
            setOpenDisableStudent(OpenDisableStudent);
        };

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                    aria-label="more"
                    aria-controls={anchorEl ? 'long-menu' : undefined}
                    aria-expanded={anchorEl ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    style={{ display: anchorEl ? 'block' : 'none' }}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <List
                        sx={{
                            width: '100%',
                            maxWidth: 360,
                            bgcolor: 'background.paper',
                            maxHeight: '300px',
                            overflowY: 'auto',
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <ListItemButton onClick={handleClickStudentConfig}>
                            <ListItemIcon>
                                {params.row.status === 0 ? (
                                    <FontAwesomeIcon
                                        icon={faUserCheck}
                                        size="lg"
                                    />
                                ) : (
                                    <EditIcon />
                                )}
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    params.row.status === 0
                                        ? 'Activar Alumno'
                                        : 'Editar Alumno'
                                }
                                sx={{ fontSize: '14px' }}
                            />
                            {OpenStudentConfig ? (
                                <ExpandLess />
                            ) : (
                                <ExpandMore />
                            )}
                        </ListItemButton>
                        <Collapse
                            in={OpenStudentConfig}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List component="div" disablePadding>
                                {params.row.status === 0 && (
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <FontAwesomeIcon
                                                icon={faUserCheck}
                                                size="lg"
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Activar"
                                            onClick={() =>
                                                showActivateStudentModal(
                                                    params.id
                                                )
                                            }
                                        />
                                    </ListItemButton>
                                )}
                                {params.row.status === 1 && (
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <EditIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Editar"
                                            onClick={() =>
                                                showAddStudentStepperModal(
                                                    params.row
                                                )
                                            }
                                        />
                                    </ListItemButton>
                                )}
                                {params.row.status === 1 && (
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <FontAwesomeIcon
                                                icon={faPeopleRoof}
                                                size="lg"
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Agregar parentesco"
                                            onClick={() =>
                                                showOpenRegistaerParentModal(
                                                    params.id
                                                )
                                            }
                                        />
                                    </ListItemButton>
                                )}
                            </List>
                        </Collapse>
                        {params.row.status === 1 && (
                            <>
                                <ListItemButton onClick={handleClickGrupos}>
                                    <ListItemIcon>
                                        <GroupsIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Grupos"
                                        sx={{ fontSize: '14px' }}
                                    />
                                    {openGrupos ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                <Collapse
                                    in={openGrupos}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                {params.row.group_id ? (
                                                    <FontAwesomeIcon
                                                        icon={faChalkboardUser}
                                                        size="lg"
                                                    />
                                                ) : (
                                                    <GroupAddIcon />
                                                )}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    params.row.group_id
                                                        ? 'Cambiar'
                                                        : 'Asignar'
                                                }
                                                onClick={() =>
                                                    showAssignGroupsStudentsModal(
                                                        params.id
                                                    )
                                                }
                                            />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                {params.row
                                                    .has_special_groups ? (
                                                    <FontAwesomeIcon
                                                        icon={faUsersRays}
                                                        size="lg"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faUsersViewfinder}
                                                        size="lg"
                                                    />
                                                )}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    params.row
                                                        .has_special_groups
                                                        ? 'Cambiar especial/extraescolar'
                                                        : 'Asignar especial/extraescolar'
                                                }
                                                onClick={() =>
                                                    params.row
                                                        .has_special_groups
                                                        ? showAssignSpecialGroupsStudentsModal(
                                                              params.id
                                                          )
                                                        : showOpenAddSpecialGroupsModal(
                                                              params.id
                                                          )
                                                }
                                            />
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            </>
                        )}
                        {isFeatureFlagActive && (
                            <>
                                <ListItemButton
                                    onClick={handleClickExportaciones}
                                >
                                    <ListItemIcon>
                                        <InboxIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Exportaciones"
                                        sx={{ fontSize: '14px' }}
                                    />
                                    {openExportaciones ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                <Collapse
                                    in={openExportaciones}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        {params.row.status === 1 && (
                                            <>
                                                {isFeatureFlagActiveExports && (
                                                    <>
                                                        <ListItemButton
                                                            sx={{ pl: 4 }}
                                                        >
                                                            <ListItemIcon>
                                                                <ConfirmationNumberIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary="Boleta"
                                                                onClick={() =>
                                                                    onExport({
                                                                        id: params.id,
                                                                        title: 'Boleta',
                                                                        report: 'ticket-score',
                                                                    })
                                                                }
                                                            />
                                                        </ListItemButton>
                                                        <ListItemButton
                                                            sx={{ pl: 4 }}
                                                        >
                                                            <ListItemIcon>
                                                                <AssignmentTurnedInIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary="Kardex tamaño carta"
                                                                onClick={() =>
                                                                    onExport({
                                                                        id: params.id,
                                                                        title: 'Kardex tamaño carta',
                                                                        report: 'student-academic-kardex-secundaria',
                                                                    })
                                                                }
                                                            />
                                                        </ListItemButton>
                                                        <ListItemButton
                                                            sx={{ pl: 4 }}
                                                        >
                                                            <ListItemIcon>
                                                                <AssignmentTurnedInIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary="Constancia con Calificaciones"
                                                                onClick={() =>
                                                                    onExport({
                                                                        id: params.id,
                                                                        title: 'Constancia con Calificaciones',
                                                                        report: 'proof-student-score',
                                                                    })
                                                                }
                                                            />
                                                        </ListItemButton>
                                                    </>
                                                )}
                                                {params.row.group_id !==
                                                    null && (
                                                    <>
                                                        <ListItemButton
                                                            sx={{ pl: 4 }}
                                                        >
                                                            <ListItemIcon>
                                                                <BadgeIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary="Credencial"
                                                                onClick={() =>
                                                                    onExport({
                                                                        id: params.id,
                                                                        title: 'Credencial',
                                                                        report: 'student-credential',
                                                                    })
                                                                }
                                                            />
                                                        </ListItemButton>
                                                        <ListItemButton
                                                            sx={{ pl: 4 }}
                                                        >
                                                            <ListItemIcon>
                                                                <FeedIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary="Formato de inscripción"
                                                                onClick={() => {
                                                                    const groupId =
                                                                        params
                                                                            .row
                                                                            .groupInfo
                                                                            ?.group_id;
                                                                    onExport({
                                                                        id: params.id,
                                                                        title: 'Formato de inscripción',
                                                                        report: 'request-student-enrollment',
                                                                        groupId:
                                                                            groupId,
                                                                    });
                                                                }}
                                                            />
                                                        </ListItemButton>
                                                        <ListItemButton
                                                            sx={{ pl: 4 }}
                                                        >
                                                            <ListItemIcon>
                                                                <ContactMailIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary="Etiqueta para archivo de alumno"
                                                                onClick={() =>
                                                                    onExport({
                                                                        id: params.id,
                                                                        title: 'Etiqueta para archivo de alumno',
                                                                        report: 'label-student-file',
                                                                    })
                                                                }
                                                            />
                                                        </ListItemButton>
                                                        <ListItemButton
                                                            sx={{ pl: 4 }}
                                                        >
                                                            <ListItemIcon>
                                                                <ForwardToInboxIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary="Etiqueta para correspondecia"
                                                                onClick={() =>
                                                                    onExport({
                                                                        id: params.id,
                                                                        title: 'Etiqueta para correspondecia',
                                                                        report: 'label-student-correspondence',
                                                                    })
                                                                }
                                                            />
                                                        </ListItemButton>
                                                        <ListItemButton
                                                            sx={{ pl: 4 }}
                                                        >
                                                            <ListItemIcon>
                                                                <SchoolIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary="Constancia de estudio"
                                                                onClick={() =>
                                                                    onExport({
                                                                        id: params.id,
                                                                        title: 'Constancia de estudio',
                                                                        report: 'proof-student-studies',
                                                                    })
                                                                }
                                                            />
                                                        </ListItemButton>
                                                        <ListItemButton
                                                            sx={{ pl: 4 }}
                                                        >
                                                            <ListItemIcon>
                                                                <HowToRegIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary="Constancia de memorándum para alta"
                                                                onClick={() =>
                                                                    onExport({
                                                                        id: params.id,
                                                                        title: 'Constancia de memorándum para alta',
                                                                        report: 'proof-student-enrollment',
                                                                    })
                                                                }
                                                            />
                                                        </ListItemButton>
                                                        <ListItemButton
                                                            sx={{ pl: 4 }}
                                                        >
                                                            <ListItemIcon>
                                                                <VpnLockIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary="Constancia para pasaporte"
                                                                onClick={() =>
                                                                    onExport({
                                                                        id: params.id,
                                                                        title: 'Constancia para pasaporte',
                                                                        report: 'proof-student-passport',
                                                                    })
                                                                }
                                                            />
                                                        </ListItemButton>
                                                        <ListItemButton
                                                            sx={{ pl: 4 }}
                                                        >
                                                            <ListItemIcon>
                                                                <WeekendIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary="Constancia periodo vacaciones"
                                                                onClick={() =>
                                                                    onExport({
                                                                        id: params.id,
                                                                        title: 'Constancia periodo vacaciones',
                                                                        report: 'proof-student-vacations',
                                                                    })
                                                                }
                                                            />
                                                        </ListItemButton>
                                                    </>
                                                )}
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <ListItemIcon>
                                                        <ThumbUpAltIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Constancia de conducta"
                                                        onClick={() =>
                                                            onExport({
                                                                id: params.id,
                                                                title: 'Constancia de conducta',
                                                                report: 'proof-student-good-conduct',
                                                            })
                                                        }
                                                    />
                                                </ListItemButton>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <ListItemIcon>
                                                        <ExitToAppIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Constancia de traslado"
                                                        onClick={() =>
                                                            onExport({
                                                                id: params.id,
                                                                title: 'Constancia de traslado',
                                                                report: 'proof-student-transfer',
                                                            })
                                                        }
                                                    />
                                                </ListItemButton>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <ListItemIcon>
                                                        <ContactMailIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Exportar carta compromiso"
                                                        onClick={() =>
                                                            onExport({
                                                                id: params.id,
                                                                title: 'Carta compromiso',
                                                                report: 'parents-commitment-letter',
                                                            })
                                                        }
                                                    />
                                                </ListItemButton>
                                            </>
                                        )}
                                        {params.row.status === 0 && (
                                            <>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <ListItemIcon>
                                                        <HowToRegIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Constancia Memorandum para baja"
                                                        onClick={() =>
                                                            onExport({
                                                                id: params.id,
                                                                title: 'Constancia Memorandum para baja',
                                                                report: 'proof-student-unenrollment',
                                                            })
                                                        }
                                                    />
                                                </ListItemButton>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <ListItemIcon>
                                                        <ExitToAppIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Constancia de traslado"
                                                        onClick={() =>
                                                            onExport({
                                                                id: params.id,
                                                                title: 'Constancia de traslado',
                                                                report: 'proof-student-transfer',
                                                            })
                                                        }
                                                    />
                                                </ListItemButton>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <ListItemIcon>
                                                        <ThumbUpAltIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Constancia de buena conducta"
                                                        onClick={() =>
                                                            onExport({
                                                                id: params.id,
                                                                title: 'Constancia de buena conducta',
                                                                report: 'proof-student-good-conduct',
                                                            })
                                                        }
                                                    />
                                                </ListItemButton>
                                            </>
                                        )}
                                    </List>
                                </Collapse>
                            </>
                        )}
                        {params.row.status === 1 && (
                            <>
                                <ListItemButton
                                    onClick={handleClickDisableStudent}
                                >
                                    <ListItemIcon>
                                        <DeleteOutlineIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Dar de baja alumno"
                                        onClick={() =>
                                            showOpenDeleteStudentModal(
                                                params.id
                                            )
                                        }
                                        sx={{ fontSize: '14px' }}
                                    />
                                </ListItemButton>
                            </>
                        )}
                    </List>
                </Menu>
            </div>
        );
    };

    const handleDataGridFilterChange = (event) => {
        let newFilterValues = {};

        event.items.forEach((filter) => {
            const { columnField, operatorValue, value } = filter;

            newFilterValues = {
                columnField,
                operatorValue,
                value,
            };
        });
        dispatch(selectColumnField(newFilterValues.columnField));
        dispatch(selectOperatorValue(newFilterValues.operatorValue));
        dispatch(selectValue(newFilterValues.value));
    };
    //////////////////// SPLITED BUTTON //////////////////////

    const options = [
        { label: 'Informe con Fotografía', icon: <ContactEmergencyIcon /> },
        { label: 'Informe con Peso y Talla', icon: <FeedIcon /> },
        { label: 'Forma 911', icon: <LocalPoliceIcon /> },
        { label: 'Reporte de Padres de Familia', icon: <FamilyRestroomIcon /> },
        { label: 'Reporte de Inicio de Ciclo', icon: <AssessmentIcon /> },
        { label: 'Informe general de alumnos', icon: <SchoolIcon /> },
    ];

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [buttonText, setButtonText] = useState(options[0]);

    const handleClickStudentReports = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClickStudentReports = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);

        switch (index) {
            case 0:
                onExport({
                    title: 'Informe de Alumnos (Con o sin fotografia)',
                    report: 'students-report-photo',
                });
                setButtonText('Informe con Fotografía');
                break;
            case 1:
                onExport({
                    title: 'Medidas Antropométricas',
                    report: 'students-report-imc',
                });
                setButtonText('Informe con Peso y Talla');
                break;
            case 2:
                onExport({
                    title: 'Forma 911',
                    report: 'students-911-form-report',
                });
                setButtonText('Forma 911');
                break;
            case 3:
                onExport({
                    title: 'Reporte de Padres de Familia',
                    report: 'parents-report',
                });
                setButtonText('Reporte de Padres de Familia');
                break;
            case 4:
                onExport({
                    title: 'Reporte de Inicio de Ciclo',
                    report: 'cycle-start-report',
                });
                setButtonText('Reporte de Inicio de Ciclo');
                break;
            case 5:
                onExport({
                    title: 'Información de alumnos en la escuela',
                    report: 'students-in-school-info',
                });
                setButtonText('Información de alumnos en la escuela');
                break;
            default:
                setButtonText(options[0]);
                break;
        }
    };

    const handleCloseStudentReports = () => {
        setAnchorEl(null);
    };

    const studentColumns = [
        {
            field: 'status',
            headerName: 'Estatus',
            flex: 0.5,
            minWidth: 50,
            filterOperators: switchOnlyOperators,
            editable: false,
            hide: true,
        },
        {
            field: 'url_photo_profile',
            headerName: 'Foto',
            flex: 0.3,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => {
                const { gender, url_photo_profile, student_id } = row;

                const generoS =
                    gender === 'H' ? DefaultMaleImage : DefaultFemaleImage;
                const comparado =
                    url_photo_profile == null ? generoS : url_photo_profile;

                return (
                    <>
                        <IconButton>
                            <Avatar
                                alt="Imagen de usuario"
                                src={comparado}
                                onClick={() => handleAvatarClick(student_id)}
                            />
                        </IconButton>
                        <input
                            type="file"
                            id={`upload-avatar-${student_id}`}
                            style={{ display: 'none' }}
                            onChange={(event) =>
                                handleImageUpload(event, student_id)
                            }
                            data-student-id={student_id}
                        />
                    </>
                );
            },
        },
        {
            field: 'student_id',
            headerName: 'Id',
            flex: 0.4,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const FullId = `${params.row.student_id}`;
                return (
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                        }}
                    >
                        {FullId}
                    </div>
                );
            },
        },
        {
            field: 'folio',
            headerName: 'Matricula',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const FullFolio = params.row.folio
                    ? `${params.row.folio}`
                    : 'Sin Matricula';
                return (
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                        }}
                    >
                        {FullFolio}
                    </div>
                );
            },
        },
        {
            field: 'curp',
            headerName: 'CURP',
            flex: 0.9,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const curpValue =
                    params.row && params.row.curp
                        ? `${params.row.curp}`
                        : 'Sin CURP';
                return (
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                        }}
                    >
                        {curpValue}
                    </div>
                );
            },
            filterOperators: [...curpSwitchOperator, seatchCURPOperatos],
        },
        {
            field: 'Nombre',
            headerName: 'Nombre',
            flex: 0.8,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.last_name || ''} ${
                    params.row.second_last_name || ''
                } ${params.row.name || ''}`.replace('  ', ' ');
            },
            renderCell: (params) => {
                const FullName = `${params.row.last_name || ''} ${
                    params.row.second_last_name || ''
                } ${params.row.name || ''}`;
                return (
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                        }}
                    >
                        {FullName}
                    </div>
                );
            },
        },
        {
            field: 'principalgroup',
            headerName: 'Grupo principal',
            flex: 0.6,
            minWidth: 100,
            editable: false,
            filterOperators: PrincipalGroupOperators,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                const groupInfo = params.row.groupInfo;

                if (!groupInfo) {
                    return 'Sin Grupo';
                }

                const groupValue = groupInfo?.group || '';
                const grade = groupInfo?.grade || '';

                return `${grade}° ${groupValue} ${getTurnLevel(
                    groupInfo.turn
                )}`;
            },
        },
        {
            field: 'groups',
            headerName: 'Grupos',
            flex: 0.4,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Tooltip title="Mostrar grupos">
                    <Button
                        variant="outlined"
                        startIcon={<ListIcon />}
                        onClick={showModal(params.id, 'groups')}
                        disabled={params.row.status == 0}
                    >
                        {params.row.groups}
                    </Button>
                </Tooltip>
            ),
        },
        {
            field: 'amount_subjects',
            headerName: 'Materias',
            flex: 0.4,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Tooltip title="Mostrar materias">
                    <Button
                        variant="outlined"
                        startIcon={<ListIcon />}
                        onClick={showModal(params.id, 'subjects')}
                        disabled={params.row.status == 0}
                    >
                        {params.row.amount_subjects}
                    </Button>
                </Tooltip>
            ),
        },
        {
            field: 'relationships',
            headerName: 'Parentescos',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                let buttonColor, iconColor, StartIcon;
                if (params.row.relationships === 0) {
                    buttonColor = red[500];
                    iconColor = red[500];
                    StartIcon = ErrorRoundedIcon;
                } else if (params.row.relationships === 1) {
                    buttonColor = yellow[700];
                    iconColor = yellow[700];
                    StartIcon = WarningOutlinedIcon;
                } else {
                    buttonColor = green[500];
                    iconColor = green[500];
                    StartIcon = CheckCircleRoundedIcon;
                }
                return (
                    <Tooltip title="Mostrar parentescos">
                        <Button
                            style={{
                                borderColor: buttonColor,
                                color: buttonColor,
                            }}
                            variant="outlined"
                            startIcon={
                                <StartIcon style={{ color: iconColor }} />
                            }
                            onClick={showModal(params.id, 'parents')}
                            disabled={params.row.status == 0}
                        >
                            {params.row.relationships}
                        </Button>
                    </Tooltip>
                );
            },
        },
        {
            field: 'average_rating',
            headerName: 'Promedio',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'total_absence',
            headerName: 'Faltas',
            flex: 0.3,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'total_presence',
            headerName: 'Asistencias',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'Render',
            headerName: 'Acciones',
            sortable: false,
            flex: 0.5,
            minWidth: 50,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => <BasicMenu params={params} />,
        },
    ];

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            <StudentGroupsModal
                open={openGroupsModal}
                setOpen={setOpenGroupsModal}
                onDelete={showDeleteGroupModal}
            />

            <StudentSubjectsModal
                open={openSubjectsModal}
                setOpen={setOpenSubjectsModal}
            />

            <StudentParentsModal
                open={openParentsModal}
                setOpen={setOpenParentsModal}
                onEdit={showEditParentModal}
                onDelete={showDeleteParentModal}
            />

            <DeleteParentModal
                openModal={openDeleteParentModal}
                setOpenModal={setOpenDeleteParentModal}
                {...userData}
            />

            <AddTracedStudentModal
                openTraced={openAddTracedStudentModal}
                setOpenTraced={setAddOpenTracedStudentModal}
            />

            <DeleteStudentModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                studentId={studentId}
                data={reportData}
            />

            <AddParentModal
                openModal={openRegistaerParentModal}
                setOpenModal={setOpenRegistaerParentModal}
                studentId={studentId}
            />

            <UpdateParentModal
                openModal={openUpdateParentModal}
                setOpenModal={setOpenUpdateParentModal}
                {...userData}
            />

            <DeleteStudentFromGroupModal
                open={openDeleteStudentGroupModal}
                setOpen={() => {
                    setOpenDeleteStudentGroupModal(false);
                }}
                {...deleteGroupData}
                onStudentDeleted={onStudentStudentDeleted}
            />

            <AssignGroupsStudentsModal
                open={openAssignGroupsStudentsModal}
                setOpen={setOpenAssignGroupsStudentsModal}
                studentId={studentId}
            />

            <ChangeSpecialGroupsModal
                open={openChangeSpecialGroupsModal}
                setOpen={setOpenChangeSpecialGroupsModal}
                studentId={studentId}
            />

            <AddSpecialGroupsModal
                open={openAddSpecialGroupsModal}
                setOpen={setOpenAddSpecialGroupsModal}
                studentId={studentId}
            />

            <ActivateStudentModal
                open={openActivateModal}
                setOpen={setOpenActivateModal}
                studentId={studentId}
            />

            {featureFlagEnabled ? (
                <ExportablesModal
                    show={open}
                    data={reportData}
                    selectedTitle={selectedTitle}
                    onClose={onCloseExportableModal}
                />
            ) : (
                <ExportableModal
                    show={open}
                    data={reportData}
                    onClose={onCloseExportableModal}
                />
            )}

            {FeatureFlags.isFeatureFlagActive('NEW_STUDENT_UI') && (
                <UpdateStudentStepperModal
                    open={openAddStudentStepperModal}
                    setOpen={setOpenAddStudentStepperModal}
                    student={studentSelected}
                />
            )}

            {FeatureFlags.isFeatureFlagActive('NEW_STUDENT_UI') ? (
                <AddStudentStepperModal
                    open={openaddStudentModal}
                    setOpen={setOpenaddStudentModal}
                />
            ) : (
                <AddStudentModal
                    open={openaddStudentModal}
                    setOpen={setOpenaddStudentModal}
                    student={studentSelected}
                />
            )}

            {statusServer == 'pending' && <SkeletonPage />}

            {statusServer == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {statusServer == 'fulfilled' && (
                <>
                    <Box
                        style={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <ButtonGroup
                            variant="contained"
                            color="primary"
                            aria-label="split button"
                        >
                            <Button
                                size="small"
                                onClick={handleClickStudentReports}
                                style={{ borderRadius: '3px' }}
                            >
                                {'Seleccionar informe'}
                            </Button>
                            <Menu
                                id="split-button-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleCloseStudentReports}
                            >
                                {options.map((option, index) => (
                                    <MenuItem
                                        key={option.label}
                                        selected={selectedIndex === index}
                                        onClick={(event) =>
                                            handleMenuItemClickStudentReports(
                                                event,
                                                index
                                            )
                                        }
                                    >
                                        {option.icon}{' '}
                                        <span style={{ marginLeft: '8px' }}>
                                            {option.label}
                                        </span>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </ButtonGroup>

                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                sx={{ ml: 2 }}
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}
                            >
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>

                    <Card sx={{ mt: 5 }}>
                        <DataGrid
                            localeText={
                                esES.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                            getRowId={({ student_id }) => student_id}
                            rows={combinedData}
                            columns={studentColumns}
                            pageSize={20}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            autoHeight
                            components={{ Toolbar: StudentsToolBar }}
                            componentsProps={{
                                panel: {
                                    placement: 'bottom-end',
                                },
                            }}
                            onFilterModelChange={handleDataGridFilterChange}
                            initialState={{
                                filter: {
                                    filterModel: {
                                        items: [
                                            {
                                                ...(columnField &&
                                                operatorValue &&
                                                filterValue
                                                    ? {
                                                          columnField:
                                                              columnField,
                                                          operatorValue:
                                                              operatorValue,
                                                          value: filterValue,
                                                      }
                                                    : {
                                                          columnField: 'status',
                                                          operatorValue:
                                                              'active',
                                                          value: '1',
                                                      }),
                                            },
                                        ],
                                    },
                                },
                            }}
                            disableDensitySelector
                            getRowClassName={(GridRowParams) => {
                                if (GridRowParams.row.status == 0) {
                                    return `super-app-theme--disabled`;
                                }
                            }}
                        />
                        <CardActions
                            sx={{
                                direction: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    showAddTracedStudentModal();
                                }}
                            >
                                Alumno CURA
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    showAddStudentModal(null);
                                }}
                            >
                                Alumno nuevo
                            </Button>
                        </CardActions>
                    </Card>
                </>
            )}
        </Box>
    );
};

export default Alumnos;
