import {
    faBook,
    faBullhorn,
    faExclamationTriangle,
    faPaste,
    faSchool,
    faUserFriends,
    faUserGraduate,
    faUsers,
    faHome,
    faWallet,
    faChartColumn,
    faClockRotateLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import BallotIcon from '@mui/icons-material/Ballot';
import {
    faCog,
    faChartBar,
    faBell,
    faCalendarAlt,
    faFolderOpen,
    faArchive,
    faChalkboardTeacher,
    faTheaterMasks,
    faChild,
    faAddressCard,
    faPeopleGroup,
} from '@fortawesome/free-solid-svg-icons';

import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import FeatureFlags from '../service/FeatureFlags';

export const LEVEL_KINDERGARTEN = 1;
export const LEVEL_ELEMENTARY_SCHOOL = 2;
export const LEVEL_HIGH_SCHOOL = 3;
export const LEVEL_PREPARATORY = 4;
export const LEVEL_UNIVERSITY = 5;
export const LEVEL_MASTER = 6;
export const LEVEL_POSGRADE = 7;
export const LEVEL_DOCTORATE = 8;
export const LEVEL_CERTIFICATION = 9;

export const MATUTINE = 1;
export const EVENING = 2;
export const NIGHT = 3;

// NIVELES PARA LAS ACTIVIDADES
export const ACTIVITY_LEVEL_GENERAL = 0;
export const ACTIVITY_LEVEL_KINDERGARTEN = 1;
export const ACTIVITY_LEVEL_ELEMENTARY_SCHOOL = 2;
export const ACTIVITY_LEVEL_HIGH_SCHOOL = 3;
export const ACTIVITY_LEVEL_PREPARATORY = 4;
export const ACTIVITY_LEVEL_UNIVERSITY = 5;
export const ACTIVITY_LEVEL_MASTER = 6;
export const ACTIVITY_LEVEL_POSGRADE = 7;
export const ACTIVITY_LEVEL_DOCTORATE = 8;
export const ACTIVITY_LEVEL_CERTIFICATION = 9;

/**
 * Valores para estatus de especial en grupos y materias
 */
export const GROUP_SPECIAL_NORMAL = 1;
export const GROUP_SPECIAL_SPECIAL = 2;
export const GROUP_EXTRAESCOLAR = 3;
export const GROUP_MODULE = 4;

/**
 * Valores para Modalidad de lista de asistencia
 */
export const MODE_TEACHER_WITH_SUBJECTS = 1;
export const MODE_GROUPED_SUBJECTS_FOR_TEACHERS_GROUPS = 2;
export const MODE_GROUPED_SUBJECTS_FOR_TEACHERS_MORE_GROUPS = 3;

/**
 * Valores para estatus de los parentescos
 */
export const RELATIONSHIP_PARENT = 1;
export const RELATIONSHIP_MOTHER = 2;
export const RELATIONSHIP_OTHER = 3;

export const roles = {
    Administrador: '10000000000000000000',
    Profesor: '01000000000000000000',
    Asesor: '00100000000000000000',
    Padre: '00010000000000000000',
    Vocero: '00001000000000000000',
    Director: '00000100000000000000',
    Administrativo: '00000010000000000000',
    Tutor: '00000001000000000000',
    AdminTech: '00000000100000000000',
    SuperAdmin: '00000000010000000000',
};

// /**
//  * Valores de roles de usuarios
//  */
// export const ADMINISTER = '10000000000000000000'
// export const PROFESSOR = '01000000000000000000'
// export const ASSESSOR = '00100000000000000000'
// export const PARENT = '00010000000000000000'
// export const DIRECTOR = '00000100000000000000'
// export const ADMINISTRATIVE = '00000010000000000000'
// export const TUTOR = '00000001000000000000'
// export const ADMIN_TI = '00000000100000000000'
// export const SUPER_ADMIN = '00000000010000000000'

// /**
//  * Roles de usuarios
//  */
// export const userRoles = [
//     {
//         key: ADMINISTER,
//         title: 'Administrador'
//     },
//     {
//         key: PROFESSOR,
//         title: 'Profesor'
//     },
//     {
//         key: ASSESSOR,
//         title: 'Asesor',
//     },
//     {
//         key: PARENT,
//         title: 'Padre/Madre'
//     },
//     {
//         key: DIRECTOR,
//         title: 'Director'
//     },
//     {
//         key: ADMINISTRATIVE,
//         title: 'Administrativo'
//     },
//     {
//         key: TUTOR,
//         title: 'Tutor'
//     },
//     {
//         key: ADMIN_TI,
//         title: 'Administrador TI'
//     },
//     {
//         key: SUPER_ADMIN,
//         title: 'Super Administrador'
//     }
// ]

/**
 * Nombres de los turnos
 */
export const turnsNames = [
    {
        key: MATUTINE,
        title: 'Matutino',
    },
    {
        key: EVENING,
        title: 'Vespertino',
    },
    {
        key: NIGHT,
        title: 'Nocturno',
    },
];

/**
 * Tipos de modalidad de sistencia
 */
export const assistanceModality = [
    {
        value: 1,
        label: 'Por materia',
    },
    {
        value: 2,
        label: 'Por grupo',
    },
    // {
    //     value: 3,
    //     label: 'Multigrado',
    // },
];

/**
 * Nombre de os niveles
 */
export const levelsNames = [
    {
        key: LEVEL_KINDERGARTEN,
        title: 'Preescolar',
    },
    {
        key: LEVEL_ELEMENTARY_SCHOOL,
        title: 'Primaria',
    },
    {
        key: LEVEL_HIGH_SCHOOL,
        title: 'Secundaria',
    },
    {
        key: LEVEL_PREPARATORY,
        title: 'Bachillerato',
    },
    {
        key: LEVEL_UNIVERSITY,
        title: 'Universidad',
    },
    {
        key: LEVEL_MASTER,
        title: 'Maestría',
    },
    {
        key: LEVEL_POSGRADE,
        title: 'Posgrado',
    },
    {
        key: LEVEL_DOCTORATE,
        title: 'Doctorado',
    },
    {
        key: LEVEL_CERTIFICATION,
        title: 'Certificación',
    },
];

const featureFlagEnableNewModalGroups = FeatureFlags.isFeatureFlagActive(
    'NEW_GROUPS_MODULE_WITH_ATTACHED'
);
const featureFlagPaymentsModule = FeatureFlags.isFeatureFlagActive(
    'NEW_PAYMENTS_MODULE'
);

const featureFlagRegularizationsModule = FeatureFlags.isFeatureFlagActive(
    'NEW_REGULARIZATIONS_MODULE'
);

const featureFlagNewDirectors =
    FeatureFlags.isFeatureFlagActive('NEW_DIRECTORS');

/**
 * Nombre de los niveles
 */
let specialNames;

specialNames = [
    {
        key: GROUP_SPECIAL_NORMAL,
        title: 'Principal',
    },
    {
        key: GROUP_SPECIAL_SPECIAL,
        title: 'Especial',
    },
    {
        key: GROUP_EXTRAESCOLAR,
        title: 'Extraescolar',
    },
    {
        key: GROUP_MODULE,
        title: 'Modulado',
    },
];

export { specialNames };
/**
 * Modalidad de lista de asistencia
 */
let modeListofAssistance;

if (featureFlagEnableNewModalGroups) {
    modeListofAssistance = [
        {
            key: MODE_TEACHER_WITH_SUBJECTS,
            title: 'Por materia del profesor',
        },
        {
            key: MODE_GROUPED_SUBJECTS_FOR_TEACHERS_GROUPS,
            title: 'Agrupadas por las materias del profesor en un grupo',
        },
        {
            key: MODE_GROUPED_SUBJECTS_FOR_TEACHERS_MORE_GROUPS,
            title: 'Agrupadas por las materias del profesor en mas de un grupo',
        },
    ];
}

export { modeListofAssistance };

/**
 * Valores disponibles para los parentescos
 */
export const relationShipsNames = [
    {
        key: RELATIONSHIP_PARENT,
        title: 'Padre',
    },
    {
        key: RELATIONSHIP_MOTHER,
        title: 'Madre',
    },
    {
        key: RELATIONSHIP_OTHER,
        title: 'Otro',
    },
];

export const ACTIVITY_LEVELS = [
    {
        key: ACTIVITY_LEVEL_GENERAL,
        title: 'Escuela',
    },
    {
        key: ACTIVITY_LEVEL_KINDERGARTEN,
        title: 'Preescolar',
    },
    {
        key: ACTIVITY_LEVEL_ELEMENTARY_SCHOOL,
        title: 'Primaria',
    },
    {
        key: ACTIVITY_LEVEL_HIGH_SCHOOL,
        title: 'Secundaria',
    },
    {
        key: ACTIVITY_LEVEL_PREPARATORY,
        title: 'Bachillerato',
    },
    {
        key: ACTIVITY_LEVEL_UNIVERSITY,
        title: 'Universidad',
    },
    {
        key: ACTIVITY_LEVEL_MASTER,
        title: 'Maestría',
    },
    {
        key: ACTIVITY_LEVEL_POSGRADE,
        title: 'Posgrado',
    },
    {
        key: ACTIVITY_LEVEL_DOCTORATE,
        title: 'Doctorado',
    },
    {
        key: ACTIVITY_LEVEL_CERTIFICATION,
        title: 'Certificación',
    },
];

//const ADMINISTER =

const userGroupsRegex = [
    {
        id: 'ADMINISTER',
        regex: /^1[0-1]{19}$/,
    },
    {
        id: 'PROFESSOR',
        regex: /^[0-1]{1}1[0-1]{18}$/,
    },
    {
        id: 'ASSESSOR',
        regex: /^[0-1]{2}1[0-1]{17}$/,
    },
    {
        id: 'PARENT',
        regex: /^[0-1]{3}1[0-1]{16}$/,
    },
    {
        id: 'SPOKESMAN',
        regex: /^[0-1]{4}1[0-1]{15}$/,
    },
    {
        id: 'DIRECTOR',
        regex: /^[0-1]{5}1[0-1]{14}$/,
    },
    {
        id: 'ADMINISTRATIVE',
        regex: /^[0-1]{6}1[0-1]{13}$/,
    },
    {
        id: 'TUTOR',
        regex: /^[0-1]{7}1[0-1]{12}$/,
    },
    {
        id: 'ADMIN_TI',
        regex: /^[0-1]{8}1[0-1]{11}$/,
    },
    {
        id: 'SUPER_ADMIN',
        regex: /^[0-1]{9}1[0-1]{10}$/,
    },
];

/**
 * Elementos del menu lateral
 */
export const MENU_ITEMS = [
    // {
    //     key: 1,
    //     text: 'Director',
    //     icon: <FontAwesomeIcon icon={faChartBar} size="lg" />,
    //     path: '/director',
    //     allow: ['DIRECTOR'],
    //     inDraweMenu: true,
    // },
    featureFlagNewDirectors && {
        key: 1,
        text: 'Director',
        icon: <FontAwesomeIcon icon={faChartColumn} size="lg" />,
        path: '/NewDirector/inicio',
        allow: ['DIRECTOR'],
        inDraweMenu: true,
    },
    {
        key: 2,
        text: 'Padres',
        icon: <FontAwesomeIcon icon={faChild} size="lg" />,
        path: '/padres',
        allow: ['PARENT'],
        inDraweMenu: true,
    },
    {
        key: 3,
        text: 'Orientador',
        icon: <FontAwesomeIcon icon={faTheaterMasks} size="lg" />,
        path: '/orientador',
        allow: ['ASSESSOR'],
        inDraweMenu: true,
    },
    {
        key: 4,
        text: 'Profesor',
        icon: <FontAwesomeIcon icon={faChalkboardTeacher} size="lg" />,
        path: '/inicio-maestros',
        allow: ['PROFESSOR'],
        inDraweMenu: true,
    },
    {
        key: 5,
        text: 'Administrativo',
        icon: <FontAwesomeIcon icon={faArchive} size="lg" />,
        path: '/administrativo',
        allow: ['ADMINISTRATIVE'],
        inDraweMenu: true,
    },
    {
        key: 6,
        text: 'Tutor',
        icon: <FontAwesomeIcon icon={faFolderOpen} size="lg" />,
        path: '/tutores',
        allow: ['TUTOR'],
        inDraweMenu: true,
    },
    {
        key: 7,
        text: 'Configuración',
        icon: <FontAwesomeIcon icon={faCog} size="lg" />,
        path: '/configuracion',
        allow: ['ADMINISTER'],
        inDraweMenu: true,
    },
    {
        key: 8,
        text: 'Usuarios',
        icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
        path: '/usuarios',
        allow: ['ADMINISTER'],
        inDraweMenu: true,
    },
    {
        key: 10,
        text: 'Grupos',
        icon: <FontAwesomeIcon icon={faUserFriends} size="lg" />,
        path: '/grupos',
        allow: ['ADMINISTER'],
        inDraweMenu: true,
    },
    {
        key: 11,
        text: 'Materias',
        icon: <FontAwesomeIcon icon={faBook} size="lg" />,
        path: '/materias',
        allow: ['ADMINISTER'],
        inDraweMenu: true,
    },
    {
        key: 12,
        text: 'Reportes',
        icon: <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />,
        path: '/reportes',
        allow: ['ADMINISTER', 'ADMINISTRATIVE', 'TUTOR', 'DIRECTOR'],
        inDraweMenu: true,
    },
    {
        key: 13,
        text: 'Avisos',
        icon: <FontAwesomeIcon icon={faBullhorn} size="lg" />,
        path: '/avisos',
        allow: ['ADMINISTER', 'DIRECTOR', 'SPOKESMAN'],
        inDraweMenu: true,
    },
    {
        key: 14,
        text: 'Alumnos',
        icon: <FontAwesomeIcon icon={faUserGraduate} size="lg" />,
        path: '/alumnos',
        allow: ['ADMINISTER'],
        inDraweMenu: true,
    },
    {
        key: 15,
        text: 'Calificaciones',
        icon: <FontAwesomeIcon icon={faPaste} size="lg" />,
        path: '/calificaciones',
        allow: ['ADMINISTER'],
        inDraweMenu: true,
    },
    {
        key: 16,
        text: 'Calendario',
        icon: <FontAwesomeIcon icon={faCalendarAlt} size="lg" />,
        path: '/calendario',
        allow: [
            'ADMINISTER',
            'DIRECTOR',
            'PROFESSOR',
            'PARENT',
            'SPOKESMAN',
            'TUTOR',
            'ASSESSOR',
            'ADMINISTRATIVE',
        ],
        inDraweMenu: true,
    },
    {
        key: 18,
        text: 'Perfil',
        icon: <FontAwesomeIcon icon={faAddressCard} size="lg" />,
        path: '/perfil',
        allow: [],
        inDraweMenu: false,
    },
    {
        key: 19,
        text: 'Supervisión',
        icon: <FontAwesomeIcon icon={faPeopleGroup} size="lg" />,
        path: '/Supervisiones',
        allow: ['SUPERVISOR'],
        inDraweMenu: true,
    },
    featureFlagPaymentsModule && {
        key: 20,
        text: 'Finanzas',
        icon: <FontAwesomeIcon icon={faWallet} size="lg" />,
        path: '/Finanzas',
        allow: ['ADMINISTER'],
        inDraweMenu: true,
    },
    featureFlagRegularizationsModule && {
        key: 21,
        text: 'Regularizaciones',
        icon: <FontAwesomeIcon icon={faClockRotateLeft} size="lg" />,
        path: '/regularizaciones',
        allow: ['ADMINISTER'],
        inDraweMenu: true,
    },
];

/*
key: 17,
text: "Notificaciones",
icon: <FontAwesomeIcon icon=faBell size="lg" />,
path: "/notificaciones",
allow: ["PROFESSOR", "PARENT", "ASSESSOR"],
inDraweMenu: true
,*/

/////////////////// NOTICIAS ///////////////////

const NOTICE_STATUS_NOT_SEND = 1;
const NOTICE_STATUS_SEND = 2;

export const NOTICE_STATUS_ITEMS = [
    {
        key: NOTICE_STATUS_NOT_SEND,
        title: 'No enviado',
        icon: <ErrorIcon></ErrorIcon>,
        color: 'error',
    },
    {
        key: NOTICE_STATUS_SEND,
        title: 'Enviado',
        icon: <DoneIcon></DoneIcon>,
        color: 'success',
    },
];

const NOTICE_TYPE_NOTICE = 1;
const NOTICE_TYPE_ASSIST = 2;
const NOTICE_TYPE_REPORT = 3;
const NOTICE_TYPE_CALIFICATION = 4;
const NOTICE_TYPE_HOMEWORK = 5;

export const NOTICE_ITEMS = [
    {
        key: NOTICE_TYPE_NOTICE,
        icon: 'newspaper-sharp',
        color: '#01579B',
        title: 'Noticia',
    },
    {
        key: NOTICE_TYPE_ASSIST,
        icon: 'calendar',
        color: '#2E7D32',
        title: 'Asistencia',
    },
    {
        key: NOTICE_TYPE_REPORT,
        icon: 'hand-right',
        color: '#C51162',
        title: 'Reporte',
    },
    {
        key: NOTICE_TYPE_CALIFICATION,
        icon: 'easel',
        color: '#424242',
        title: 'Calificación',
    },
    {
        key: NOTICE_TYPE_HOMEWORK,
        icon: 'create',
        color: '#00c483',
        title: 'Tarea',
    },
];

/////////////////// PAYMENTS ///////////////////

export const CONCEPTS_TYPE = {
    ALL: 0,
    PAID: 1,
    PENDING: 2,
    CANCELED: 3,
};

export const DISCOUNTS_TYPE = {
    ALL: 0,
    ACTIVE: 1,
    CANCELED: 2,
};

/////////////// TIME LIFE PAKCS ////////////////

/**
 * Dias extra despues del dia fin del parcial
 */
export const PARTIAL_ADD_LIMIT_DAYS = 5;

/**
 * Tiempo de expiracion de las configuraciones del escuela
 */
export const SETTINGSUI_EXPIRE_TIME = 60;

/**
 * Tiempo de expiracion para la pagina de usuarios
 */
export const USERSUI_EXPIRE_TIME = 60;

/**
 * Tiempo de expiracion para la pagina de usuarios
 */
export const GROUPSUI_EXPIRE_TIME = 60;

/**
 * Tiempo de expiracion para la pagina de materias
 */
export const SUBJECTSUI_EXPIRE_TIME = 60;

/**
 * Tiempo de expiracion para la pagina de reportes
 */
export const REPORTESUI_EXPIRE_TIME = 60;

/**
 * Tiempo de expiracion para la pagina de reportes
 */
export const NOTICESUI_EXPIRE_TIME = 60;

/**
 * Tiempo de expiracion para la pagina de alumnos
 */
export const STUDENTSUI_EXPIRE_TIME = 60;

/**
 * Tiempo de expiracion para la pagina de alumnos
 */
export const STUDENTSUI_MODALS_EXPIRE_TIME = 60;

/**
 * Tiempo de expiracion para la pagina de calificaciones
 */
export const SCORES_EXPIRE_TIME = 60;

/**
 * Tiempo de expiracion para la pagina de calificaciones
 */
export const BACKGROUND_EXPIRE_TIME = 60;

/**
 * Tiempo de expiracion para homo del modulo de profesores
 */
export const PROFESSORS_HOME_EXPIRE_TIME = 60;

/**
 * Tiempo de expiracion para informacion de la materia
 */
export const PROFESSORS_SUBJECT_EXPIRE_TIME = 60;

/**
 * Tiempo de expiracion para informacion de reportes
 */
export const PROFESSORS_STUDENT_REPORT_EXPIRE_TIME = 60;
/**
 * Tiempo de expiracion para homo del modulo de pagos
 */
export const PAYMENTS_HOME_EXPIRE_TIME = 60;
/**
 * Tiempo de expiracion para homo del modulo de pagos
 */
export const DIRECTORS_HOME_EXPIRE_TIME = 60;
