import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
// import Feedback from '../../../service/Feedback';

import Connection from '../../../../service/Connection';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../../service/const';
import { emptyState } from '../../emptyState';
import Feedback from '../../../../service/Feedback';

const initialState = {
    last_student_with_discount: {
        fetch: {
            ...emptyState,
        },
        data: [],
    },
    last_students_with_payments: {
        fetch: {
            ...emptyState,
        },
        data: [],
    },
    monthly_payments_count: {
        fetch: {
            ...emptyState,
        },
        data: [],
    },
};

////////////////////////// SLICE //////////////////////////
const tablesSlice = createSlice({
    name: 'tables',
    initialState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate_last_student_with_discount: (state) => {
            state.last_student_with_discount.fetch.didInvalidate = true;
        },
        invalidate_last_students_with_payments: (state) => {
            state.last_students_with_payments.fetch.didInvalidate = true;
        },
        invalidate_monthly_payments_count: (state) => {
            state.monthly_payments_count.fetch.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return initialState;
        });

        const pendingServerStatus_last_student_with_discount = (
            state,
            action
        ) => {
            state.last_student_with_discount.fetch.statusServer = 'pending';
        };
        const pendingServerStatus_last_students_with_payments = (
            state,
            action
        ) => {
            state.last_students_with_payments.fetch.statusServer = 'pending';
        };
        const pendingServerStatus_monthly_payments_count = (state, action) => {
            state.monthly_payments_count.fetch.statusServer = 'pending';
        };

        /**
         * Termina la carga de informacion de last_student_with_discount
         */

        builder.addCase(
            LoadUILastStudentWithDiscountTable.fulfilled,
            (state, action) => {
                state.last_student_with_discount.fetch = {
                    ...state.last_student_with_discount.fetch,
                    expireIn: new Date().setMinutes(
                        new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                    ),
                    fetchingAt: Date.now(),
                    didInvalidate: false,
                    statusServer: 'fulfilled',
                };

                state.last_student_with_discount.data =
                    action.payload.data.components;
            }
        );

        builder.addCase(
            LoadUILastStudentWithDiscountTable.pending,
            pendingServerStatus_last_student_with_discount
        );
        builder.addCase(
            LoadUILastStudentWithDiscountTable.rejected,
            (state, action) => {
                state.last_student_with_discount.fetch.statusServer =
                    'rejected';
                state.last_student_with_discount.fetch.error = action.error;
            }
        );

        /**
         * Termina la carga de informacion de last_students_with_payments
         */
        builder.addCase(
            loadUILastStudentsWithPayments.fulfilled,
            (state, action) => {
                state.last_students_with_payments.fetch = {
                    ...state.last_students_with_payments.fetch,
                    expireIn: new Date().setMinutes(
                        new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                    ),
                    fetchingAt: Date.now(),
                    didInvalidate: false,
                    statusServer: 'fulfilled',
                };

                state.last_students_with_payments.data =
                    action.payload.data.components;
            }
        );
        builder.addCase(
            loadUILastStudentsWithPayments.pending,
            pendingServerStatus_last_students_with_payments
        );
        builder.addCase(
            loadUILastStudentsWithPayments.rejected,
            (state, action) => {
                state.last_students_with_payments.fetch.statusServer =
                    'rejected';
                state.last_students_with_payments.fetch.error = action.error;
            }
        );
        /**
         * Termina la carga de informacion de monthly_payments_count
         */
        builder.addCase(
            loadUIMonthlyPaymentsCount.fulfilled,
            (state, action) => {
                state.monthly_payments_count.fetch = {
                    ...state.monthly_payments_count.fetch,
                    expireIn: new Date().setMinutes(
                        new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                    ),
                    fetchingAt: Date.now(),
                    didInvalidate: false,
                    statusServer: 'fulfilled',
                };
                state.monthly_payments_count.data =
                    action.payload.data.components;
            }
        );
        builder.addCase(
            loadUIMonthlyPaymentsCount.pending,
            pendingServerStatus_monthly_payments_count
        );
        builder.addCase(
            loadUIMonthlyPaymentsCount.rejected,
            (state, action) => {
                state.monthly_payments_count.fetch.statusServer = 'rejected';
                state.monthly_payments_count.fetch.error = action.error;
            }
        );
    },
});
export const {
    invalidate_last_student_with_discount,
    invalidate_last_students_with_payments,
    invalidate_monthly_payments_count,
} = tablesSlice.actions;

export default tablesSlice.reducer;

///////////// THUNKS  ////////////

/**
 * Cargar informacion de la UI del ultimo estudiante con descuento
 */

export const LoadUILastStudentWithDiscountTable = createAsyncThunk(
    'fetch/lastStudentWithDiscountTable/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const lastStudentsDiscounts =
                await Connection.getPaymentsInformation({
                    school_id: schoolId,
                    data: {
                        page: 'home',
                        type: 'table',
                        idKey: 'last_student_with_discount',
                    },
                }).then((response) => response.data);

            return lastStudentsDiscounts;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectTable_last_student_with_discount_table(getState());
            let valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

/**
 * Cargar informacion de la UI de los ultimos estudiantes con pagos
 */
export const loadUILastStudentsWithPayments = createAsyncThunk(
    'homeUI/lastStudentsWithPayments/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const lastSudentsPayments = await Connection.getPaymentsInformation(
                {
                    school_id: schoolId,
                    data: {
                        page: 'home',
                        type: 'table',
                        idKey: 'last_students_with_payments',
                    },
                }
            ).then((res) => res.data);

            return lastSudentsPayments;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },

    {
        // Condición para ejecutar el thunk solo si es necesario
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectTable_last_students_with_payments(getState());

            let valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

/**
 * Cargar informacion de la UI de los pagos mensuales
 */
export const loadUIMonthlyPaymentsCount = createAsyncThunk(
    'homeUI/monthlyPaymentsCount/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const monthlyPayments = await Connection.getPaymentsInformation({
                school_id: schoolId,
                data: {
                    page: 'home',
                    type: 'table',
                    idKey: 'monthly_payments_count',
                },
            }).then((res) => res.data);

            return monthlyPayments;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn, status } =
                selectTable_monthly_payments_count(getState());

            let valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

// //////////////////////////////////// SELECTORES // ////////////////////////////////////

const selectTable = (state) => state.paymentsModule.home.tables;

/**
 * Selector para recuperar el estado de la petición del ultimo estudiante con descuento
 */

export const selectTable_last_student_with_discount_table = createSelector(
    selectTable,
    (state) => state.last_student_with_discount.fetch
);

export const selectTableStatus_last_student_with_discount_table =
    createSelector(
        [selectTable_last_student_with_discount_table],
        (fetch) => fetch.statusServer
    );

export const selectLastStudentWithDiscountTable = createSelector(
    [selectTable],
    (table) => table.last_student_with_discount.data
);

/**
 * Selector para recuperar el estado de la petición de los ultimos estudiantes con pagos
 */

export const selectTable_last_students_with_payments = createSelector(
    selectTable,
    (state) => state.last_students_with_payments.fetch
);
export const selectTableStatus_last_students_with_payments = createSelector(
    [selectTable_last_students_with_payments],
    (fetch) => fetch.statusServer
);

export const selectLastStudentsWithPayments = createSelector(
    [selectTable],
    (table) => table.last_students_with_payments.data
);

/**
 * Selector para recuperar el estado de la petición de los pagos mensuales
 */

export const selectTable_monthly_payments_count = createSelector(
    selectTable,
    (state) => state.monthly_payments_count.fetch
);

export const selectTableStatus_monthly_payments_count = createSelector(
    [selectTable_monthly_payments_count],
    (fetch) => fetch.statusServer
);

export const selectMonthlyPaymentsCount = createSelector(
    [selectTable],
    (table) => table.monthly_payments_count.data.value
);
