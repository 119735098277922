import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../hooks';
import { LoadingButton } from '@mui/lab';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Box,
    Skeleton,
    Stack,
} from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import * as Style from '../../../styles/index';
import { getLevelName } from '../../../libs/utils';
import { selectAllSubjects } from '../../../store/slices/entities/subjects';
import {
    selectAllSubjectcatalogs,
    selectSubjectCatalogBySubject,
    selectSubjectCatalogsBySubjects,
} from '../../../store/slices/entities/subjectcatalogs';
import {
    getProfessorsSubject,
    selectProfessorsSubjectServerStatusServer,
} from '../../../store/slices/usersUI';

const SubjectModal = ({ open, setOpen, professorId }) => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const classes = Style.tableModalStyle();

    const loading = useSelector(selectProfessorsSubjectServerStatusServer);
    const subjects = useSelector(selectAllSubjects);
    const catalogSubjects = useSelector(selectAllSubjectcatalogs);

    // console.log(professorId, 'ID DEL PROFESOR AL ABRIR EL MODAL');
    // console.log(subjects, 'MATERIAS');
    // console.log(catalogSubjects, 'CATALOGO DE LAS MATERIAS');

    // Paso 1: Filtrar las subjects por el professor_id
    const subjectsProfessor = subjects.filter(
        (subject) => subject.professor_id === professorId
    );
    // console.log(subjectsProfessor, 'Subjects relacionadas con el Profesor');

    // Paso 2: Obtener los catalog_subject_id de las subjects filtradas y guardarlos en catalogSubjectProfessor
    const catalogSubjectProfessor = subjectsProfessor.map(
        (subject) => subject.catalog_subject_id
    );
    // console.log(catalogSubjectProfessor, 'Catalog Subject IDs del Profesor');

    // Paso 3: Comparar los catalog_subject_id con los que vienen en catalogSubjects
    const relatedCatalogSubjects = catalogSubjects.filter((catalog) =>
        catalogSubjectProfessor.includes(catalog.catalog_subject_id)
    );
    // console.log(relatedCatalogSubjects, 'CatalogSubjects relacionados');

    const config = useSelector(selectSettingBySchoolId(schoolId));
    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    useEffect(() => {
        if (open && !loading) {
            dispatch(getProfessorsSubject(professorId));
        }
    }, [open, dispatch, professorId, loading]);

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
        }
        setOpen(false);
    };

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'folio',
            headerName: 'Folio',
            flex: 0.1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'title',
            headerName: 'Nombre de la materia',
            flex: 0.4,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'level',
            headerName: 'Nivel',
            flex: 0.2,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <Typography>{getLevelName(params.row.level)}</Typography>
            ),
        },
    ];

    const rows = [];
    // catalogSubjects.map(catalog => ({
    //     id: catalog.id,
    //     folio: catalog.folio,
    //     title: catalog.title,
    //     level: catalog.level,
    // }));

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="md"
            classes={{ paper: classes.dialogWrapper }}
            onClose={handleClose}
        >
            <DialogTitle
                className={classes.dialogTitle}
                style={{
                    backgroundColor: titleBarBackground?.value,
                    color: fontColor?.value,
                }}
            >
                <Typography>Materias</Typography>
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }}>
                <Box
                    sx={{
                        '& .super-app-theme--header': {
                            backgroundColor: titleBarBackground?.value,
                            color: fontColor?.value,
                        },
                        '& .MuiDataGrid-root': { border: 0 },
                    }}
                >
                    <Box style={{ textAlign: 'center' }}>
                        {loading ? (
                            <Stack spacing={2} sx={{ padding: 2 }}>
                                <Skeleton variant="rectangular" height={70} />
                                <Skeleton variant="rectangular" height={30} />
                                <Skeleton variant="rectangular" height={30} />
                            </Stack>
                        ) : (
                            <DataGrid
                                localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                        .localeText
                                }
                                rows={rows}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[]}
                                disableSelectionOnClick
                                disableDensitySelector
                                autoHeight
                                getRowId={(row) => row.id}
                            />
                        )}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    color="primary"
                    loadingPosition="start"
                    onClick={handleClose}
                    variant="contained"
                >
                    Cerrar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default SubjectModal;
