import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    Card,
    CardContent,
    CircularProgress,
    Grid,
} from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { Tag } from 'antd';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectStudentsWithSubjectsFailed,
} from '../../../store/students/fetchSlice';
import { getStudentsWithSubjectsFailed } from '../../../store/students/thunks';
import DEFAULT_AVATAR_URL from './../../../../assets/images/Unknown_person.jpeg';
import { selectComponentStudentsWithSubjectsFailedStatusServer } from '../../../store/students/componentsStatusSelectors';
import { SkeletonStudentsTables } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';

const StudentsWithSubjectsFailedTable = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentStudentsWithSubjectsFailedStatusServer
    );

    const studentsWithSubjectsFailed = useFetchResource(
        () =>
            getStudentsWithSubjectsFailed({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectStudentsWithSubjectsFailed,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getStudentsWithSubjectsFailed({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectStudentsWithSubjectsFailed,
            [cycleSelected, schoolId]
        );
    };

    const headers = studentsWithSubjectsFailed.value
        ? Object.keys(studentsWithSubjectsFailed.value)
        : [];
    const data = studentsWithSubjectsFailed.value
        ? studentsWithSubjectsFailed.value
        : {};

    const rows =
        data[headers[0]]?.map((_, index) => {
            let row = { id: index };
            headers.forEach((header) => {
                if (header === 'Foto') {
                    row[header] =
                        data[header][index]?.value || DEFAULT_AVATAR_URL;
                } else {
                    row[header] = data[header][index]?.value || '';
                }
            });
            return row;
        }) || [];

    const columns = [
        {
            field: 'ID alumno',
            headerName: 'ID alumno',
            flex: 0.8,
            minWidth: 80,
        },
        {
            field: 'Folio',
            headerName: 'Folio',
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'Foto',
            headerName: 'Foto',
            flex: 0.7,
            minWidth: 80,
            renderCell: (params) => (
                <img
                    src={params.value || DEFAULT_AVATAR_URL}
                    alt="Foto del estudiante"
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: '50%',
                        objectFit: 'cover',
                    }}
                />
            ),
            disableExport: true,
        },
        {
            field: 'Nombre',
            headerName: 'Nombre',
            flex: 2,
            minWidth: 150,
            renderCell: (params) => (
                <Box
                    sx={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                    }}
                >
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'Grado',
            headerName: 'Grado',
            flex: 0.5,
            minWidth: 50,
        },
        {
            field: 'Grupo',
            headerName: 'Grupo',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'Turno',
            headerName: 'Turno',
            flex: 0.8,
            minWidth: 90,
        },
        {
            field: 'Nivel',
            headerName: 'Nivel',
            flex: 0.8,
            minWidth: 90,
        },
        {
            field: 'Promedio',
            headerName: 'Promedio',
            flex: 0.7,
            minWidth: 80,
            renderCell: (params) => (
                <Tag
                    color="blue"
                    style={{
                        width: '100px',
                        textAlign: 'center',
                    }}
                >
                    {params.value || '0'}
                </Tag>
            ),
        },
        {
            field: 'Materias reprobadas',
            headerName: 'Numero de materias reprobadas',
            flex: 1,
            minWidth: 230,
            renderCell: (params) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <Tag
                        color="error"
                        style={{
                            width: '100px',
                            textAlign: 'center',
                        }}
                    >
                        {params.value || '0'}
                    </Tag>
                </div>
            ),
        },
    ];

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbarColumnsButton {...props} />
                <GridToolbarFilterButton {...props} />
                <GridToolbarExport
                    csvOptions={{
                        utf8WithBom: true,
                        delimiter: ',',
                    }}
                />
            </GridToolbarContainer>
        );
    };

    return (
        <Box>
            {fetchingStatus === 'pending' && (
                <SkeletonStudentsTables items={9} />
            )}
            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {fetchingStatus === 'fulfilled' && (
                <Card
                    variant="outlined"
                    sx={{
                        borderRadius: '8px',
                        width: '100%',
                        overflow: 'hidden',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        borderColor: '#e0e0e0',
                        mb: 4,
                    }}
                >
                    <CardContent sx={{ width: '100%', padding: '24px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    color="textPrimary"
                                    gutterBottom
                                >
                                    {studentsWithSubjectsFailed.title ||
                                        'Alumnos con materias reprobadas'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {studentsWithSubjectsFailed.loading ? (
                                    <Box display="flex" justifyContent="center">
                                        <CircularProgress />
                                    </Box>
                                ) : studentsWithSubjectsFailed.error ? (
                                    <Typography color="error">
                                        Error:{' '}
                                        {
                                            studentsWithSubjectsFailed.error
                                                .message
                                        }
                                    </Typography>
                                ) : (
                                    <Box sx={{ height: 700, width: '100%' }}>
                                        <DataGrid
                                            localeText={
                                                esES.components.MuiDataGrid
                                                    .defaultProps.localeText
                                            }
                                            rows={rows}
                                            columns={columns}
                                            pageSize={10}
                                            rowsPerPageOptions={[]}
                                            disableDensitySelector
                                            disableSelectionOnClick
                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                            sx={{
                                                border: 'none',
                                                '& .MuiDataGrid-cell': {
                                                    whiteSpace: 'normal',
                                                    overflow: 'visible',
                                                    textOverflow: 'clip',
                                                    wordBreak: 'break-word',
                                                },
                                                '& .bold-header': {
                                                    fontWeight: 'bold',
                                                    borderBottom:
                                                        '1px solid #e0e0e0',
                                                },
                                            }}
                                        />
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default StudentsWithSubjectsFailedTable;
