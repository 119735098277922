import React, { useState } from 'react';
import {
    Button,
    Switch,
    Card,
    CardContent,
    CardActions,
    Tooltip,
} from '@mui/material';
import ModalUsuarioPassword from '../../components/Modal/ModalUsuarioPassword';
import { DataGrid, esES, GridActionsCellItem } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { selectAssessorsBySchool } from '../../store/slices/entities/users';
import EditIcon from '@mui/icons-material/Edit';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { disableUser, selectUsersUI } from '../../store/slices/usersUI';
import ModalUsuarioCelular from '../../components/Modal/ModalUsuarioCelular';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from '../../hooks';
import { useHistory } from 'react-router-dom';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import useFeedback from '../../hooks/useFeedback';
import { ModalEditUser } from '../../components/Modal/ModalEditUser';
import { ModalCreateUser } from '../../components/Modal/ModalCreateUser';
import FeatureFlags from '../../service/FeatureFlags';
import { OrientadorToolBar } from '../Usuarios/components/ProfesoresToolBar';
import AddCuraUserModal from '../Usuarios/components/AddCuraUserModal';
import { updateFilter } from '../../store/slices/usersUI';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom';
import OrientadorGroupsModal from './components/OrientadorGroupsModal';
import MenuIcon from '@mui/icons-material/Menu';

/**
 * Pagina para orientadores
 *
 * @returns
 */
const UsuariosOrientador = () => {
    const dispatch = useDispatch();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const history = useHistory();
    const feedbackApp = useFeedback();
    const match = useRouteMatch();
    const pathSegments = match.url.split('/');
    const tabKey = pathSegments[pathSegments.length - 1];
    const value = tabKey;

    //////////////// SHARED STATE ////////////////////

    const filtersUI = useSelector(selectUsersUI);
    const orientadores = useSelector(selectAssessorsBySchool(schoolId));
    const assesorsId = orientadores.map((assesor) => assesor.user_id);
    const featureFlagCuraActiveUser = FeatureFlags.isFeatureFlagActive(
        'NEW_ACTIVE_CURA_USER'
    );

    /////////////// LOCAL STATE //////////////////////

    const [openModal, setOpenModal] = useState(false);
    const [openModalNew, setOpenModalNew] = useState(false);
    const [openModalPassword, setOpenModalPassword] = useState(false);
    const [usuarioSelected, setUsuarioSelected] = useState([]);
    const [openModalCellphone, setOpenModalCellphone] = useState(false);
    const [openAddCuraUserModal, setOpenAddCuraUserModal] = useState(false);

    const [groupsSelect, setGroupsSelect] = useState(null);
    const [openGroupsModal, setOpenGroupsModal] = useState(false);

    const handlerShowGroupsModal = (assesorsId) => {
        setGroupsSelect(assesorsId);
        setOpenGroupsModal(true);
    };

    const featureFlagPadres =
        FeatureFlags.isFeatureFlagActive('NEW_COLUMNS_USERS');
    const columns = [];
    if (featureFlagPadres) {
        columns.push(
            {
                field: 'id',
                headerName: 'Id',
                flex: 0.5,
                minWidth: 50,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                    const FullId = params.row.id || ' ';
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {FullId}
                        </div>
                    );
                },
            },

            {
                field: 'enrollment',
                headerName: 'Matrícula',
                flex: 0.5,
                minWidth: 50,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                    const FullEnrollment = params.row.enrollment || ' ';
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {FullEnrollment}
                        </div>
                    );
                },
            },
            {
                field: 'nombre',
                headerName: 'Nombre',
                flex: 1,
                minWidth: 250,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                valueGetter: (params) => {
                    return `${params.row.last_name || ''} ${
                        params.row.second_last_name || ''
                    } ${params.row.name || ''}`.replace('  ', ' ');
                },
                renderCell: (params) => {
                    const FullName = `${params.row.last_name || ''} ${
                        params.row.second_last_name || ''
                    } ${params.row.name || ''}`;
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {FullName}
                        </div>
                    );
                },
            },
            {
                field: 'cellphone',
                headerName: 'Teléfono',
                flex: 0.5,
                minWidth: 100,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                    const FullCellphone = params.row.cellphone || ' ';
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {FullCellphone}
                        </div>
                    );
                },
            },
            {
                field: 'email',
                headerName: 'Correo',
                flex: 1,
                minWidth: 250,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                    const FullEmail = params.row.email || ' ';
                    const emailParts = FullEmail.split('@');
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {emailParts.length > 1 ? (
                                <>
                                    {emailParts[0]}@<br />
                                    {emailParts.slice(1).join('@')}
                                </>
                            ) : (
                                FullEmail
                            )}
                        </div>
                    );
                },
            },
            {
                field: 'domicilio',
                headerName: 'Domicilio',
                flex: 1,
                minWidth: 250,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                valueGetter: (params) => {
                    return `${params.row.colony || ' '} ${
                        params.row.street || ''
                    } ${params.row.outside_number || ''} ${
                        params.row.zipcode || ''
                    }`.replace('  ', ' ');
                },
                renderCell: (params) => {
                    const value = `${params.row.colony || ' '} ${
                        params.row.street || ''
                    }\n${params.row.outside_number || ''} C.P. ${
                        params.row.zipcode || ''
                    }`;

                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {value}
                        </div>
                    );
                },
            },
            {
                field: 'groups',
                headerName: 'Grupos',
                flex: 0.5,
                minWidth: 50,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                    const assesorsId = params.row.user_id;
                    return (
                        <Tooltip title="Mostrar Grupos">
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    handlerShowGroupsModal(assesorsId)
                                }
                            >
                                <MenuIcon />
                            </Button>
                        </Tooltip>
                    );
                },
            },
            {
                field: 'status',
                headerName: 'Activo',
                type: 'singleSelect',
                flex: 0.3,
                minWidth: 50,
                headerAlign: 'center',
                align: 'center',
                valueOptions: ['Activo', 'Inactivo'],
                valueGetter: (params) => {
                    return params.value == 1 ? 'Activo' : 'Inactivo';
                },
                renderCell: (params) => {
                    return (
                        <Tooltip title="Desactivar/Activar usuario">
                            <Switch
                                checked={params.row.status === 1 ? true : false}
                                onChange={(event) => {
                                    setUsuarioSelected(params.row);
                                    cambiarStatus(
                                        params.row,
                                        event.target.value
                                    );
                                }}
                                value={params.row.status}
                                disabled={
                                    Auth.getUserID(params.row) ==
                                    Auth.getUserID()
                                }
                            />
                        </Tooltip>
                    );
                },
            },
            {
                field: 'acciones',
                headerName: 'Acciones',
                sortable: false,
                flex: 0.4,
                minWidth: 50,
                headerAlign: 'center',
                align: 'center',
                type: 'actions',
                getActions: (params) => {
                    if (Auth.getUserID(params.row) == Auth.getUserID()) {
                        return [
                            <GridActionsCellItem
                                icon={<AccountBoxIcon color="primary" />}
                                title="Perfil"
                                label="Perfil"
                                onClick={(event) => {
                                    history.push({ pathname: `/perfil` });
                                }}
                                showInMenu
                            />,
                        ];
                    }

                    const actions = [
                        <GridActionsCellItem
                            icon={<EditIcon color="primary" />}
                            title="Editar"
                            label="Editar"
                            onClick={() => {
                                setUsuarioSelected(params.row);
                                setOpenModalNew(true);
                            }}
                            showInMenu
                        />,
                        <GridActionsCellItem
                            icon={<VpnKeyIcon color="primary" />}
                            title="Cambiar contraseña"
                            label="Cambiar contraseña"
                            onClick={() => {
                                setUsuarioSelected(params.row);
                                setOpenModalPassword(true);
                            }}
                            showInMenu
                        />,
                        <GridActionsCellItem
                            icon={<PhoneAndroidIcon color="primary" />}
                            title="Cambiar teléfono celular"
                            label="Cambiar teléfono celular"
                            onClick={() => {
                                setUsuarioSelected(params.row);
                                setOpenModalCellphone(true);
                            }}
                            showInMenu
                        />,
                    ];

                    return actions;
                },
            }
        );
    } else {
        columns.push(
            {
                field: 'id',
                headerName: 'Id',
                flex: 0.5,
                minWidth: 50,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                    const FullId = params.row.id || ' ';
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {FullId}
                        </div>
                    );
                },
            },

            {
                field: 'enrollment',
                headerName: 'Matrícula',
                flex: 0.5,
                minWidth: 50,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                    const FullEnrollment = params.row.enrollment || ' ';
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {FullEnrollment}
                        </div>
                    );
                },
            },
            {
                field: 'nombre',
                headerName: 'Nombre',
                flex: 1,
                minWidth: 250,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                valueGetter: (params) => {
                    return `${params.row.last_name || ''} ${
                        params.row.second_last_name || ''
                    } ${params.row.name || ''}`.replace('  ', ' ');
                },
                renderCell: (params) => {
                    const FullName = `${params.row.last_name || ''} ${
                        params.row.second_last_name || ''
                    } ${params.row.name || ''}`;
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {FullName}
                        </div>
                    );
                },
            },
            {
                field: 'cellphone',
                headerName: 'Teléfono',
                flex: 0.5,
                minWidth: 100,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                    const FullCellphone = params.row.cellphone || ' ';
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {FullCellphone}
                        </div>
                    );
                },
            },
            {
                field: 'email',
                headerName: 'Correo',
                flex: 1,
                minWidth: 250,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                    const FullEmail = params.row.email || ' ';
                    const emailParts = FullEmail.split('@');
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {emailParts.length > 1 ? (
                                <>
                                    {emailParts[0]}@<br />
                                    {emailParts.slice(1).join('@')}
                                </>
                            ) : (
                                FullEmail
                            )}
                        </div>
                    );
                },
            },
            {
                field: 'domicilio',
                headerName: 'Domicilio',
                flex: 1,
                minWidth: 250,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                valueGetter: (params) => {
                    return `${params.row.colony || ' '} ${
                        params.row.street || ''
                    } ${params.row.outside_number || ''} ${
                        params.row.zipcode || ''
                    }`.replace('  ', ' ');
                },
                renderCell: (params) => {
                    const value = `${params.row.colony || ' '} ${
                        params.row.street || ''
                    }\n${params.row.outside_number || ''} C.P. ${
                        params.row.zipcode || ''
                    }`;

                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {value}
                        </div>
                    );
                },
            },
            {
                field: 'status',
                headerName: 'Activo',
                type: 'singleSelect',
                flex: 0.3,
                minWidth: 50,
                headerAlign: 'center',
                align: 'center',
                valueOptions: ['Activo', 'Inactivo'],
                valueGetter: (params) => {
                    return params.value == 1 ? 'Activo' : 'Inactivo';
                },
                renderCell: (params) => {
                    return (
                        <Tooltip title="Desactivar/Activar usuario">
                            <Switch
                                checked={params.row.status === 1 ? true : false}
                                onChange={(event) => {
                                    setUsuarioSelected(params.row);
                                    cambiarStatus(
                                        params.row,
                                        event.target.value
                                    );
                                }}
                                value={params.row.status}
                                disabled={
                                    Auth.getUserID(params.row) ==
                                    Auth.getUserID()
                                }
                            />
                        </Tooltip>
                    );
                },
            },
            {
                field: 'acciones',
                headerName: 'Acciones',
                sortable: false,
                flex: 0.4,
                minWidth: 50,
                headerAlign: 'center',
                align: 'center',
                type: 'actions',
                getActions: (params) => {
                    if (Auth.getUserID(params.row) == Auth.getUserID()) {
                        return [
                            <GridActionsCellItem
                                icon={<AccountBoxIcon color="primary" />}
                                title="Perfil"
                                label="Perfil"
                                onClick={(event) => {
                                    history.push({ pathname: `/perfil` });
                                }}
                                showInMenu
                            />,
                        ];
                    }

                    const actions = [
                        <GridActionsCellItem
                            icon={<EditIcon color="primary" />}
                            title="Editar"
                            label="Editar"
                            onClick={() => {
                                setUsuarioSelected(params.row);
                                setOpenModalNew(true);
                            }}
                            showInMenu
                        />,
                        <GridActionsCellItem
                            icon={<VpnKeyIcon color="primary" />}
                            title="Cambiar contraseña"
                            label="Cambiar contraseña"
                            onClick={() => {
                                setUsuarioSelected(params.row);
                                setOpenModalPassword(true);
                            }}
                            showInMenu
                        />,
                        <GridActionsCellItem
                            icon={<PhoneAndroidIcon color="primary" />}
                            title="Cambiar teléfono celular"
                            label="Cambiar teléfono celular"
                            onClick={() => {
                                setUsuarioSelected(params.row);
                                setOpenModalCellphone(true);
                            }}
                            showInMenu
                        />,
                    ];

                    return actions;
                },
            }
        );
    }

    /**
     * Cambiar el status de un usuario
     *
     * @param {*} info
     * @param {*} status
     */
    const cambiarStatus = async (info, status) => {
        let statusFinal = status === '1' ? 0 : 1;
        let data = {
            status: statusFinal,
        };

        dispatch(
            disableUser({
                user: info,
                userId: info.user_id,
                data,
            })
        )
            .unwrap()
            .then((response) => {
                feedbackApp.showFeedback({
                    title: 'Usuario actualizado',
                });
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const showAddCuraUserModal = () => {
        setOpenAddCuraUserModal(true);
    };

    const sortedDataGrid = orientadores?.sort((a, b) => {
        const compareText = (propA, propB) => {
            const textA = propA.toUpperCase();
            const textB = propB.toUpperCase();
            if (textA < textB) {
                return -1;
            }
            if (textA > textB) {
                return 1;
            }
            return 0;
        };

        if (b.status !== a.status) {
            return b.status - a.status;
        }

        const lastNameComparison = compareText(a.last_name, b.last_name);
        if (lastNameComparison !== 0) {
            return lastNameComparison;
        }

        const secondLastNameComparison = compareText(
            a.second_last_name,
            b.second_last_name
        );
        if (secondLastNameComparison !== 0) {
            return secondLastNameComparison;
        }

        return compareText(a.name, b.name);
    });

    const dataGrid = sortedDataGrid?.map((row) => ({
        ...row,
        id: row.user_id,
    }));

    const handleFilterChange = (event) => {
        let newFilterValues = {};
        event.items.forEach((filter) => {
            const { columnField, operatorValue, value } = filter;

            newFilterValues = {
                columnField,
                operatorValue,
                value,
            };
        });
        dispatch(updateFilter({ value, filter: newFilterValues }));
    };

    const filterSetterValues = () => {
        const filtersForValue = filtersUI[value] || {};
        const filtersModified =
            filtersForValue &&
            (filtersForValue.columnField !== '' ||
                filtersForValue.operatorValue !== '' ||
                filtersForValue.value !== '');

        return {
            initialState: {
                filter: {
                    filterModel: (() => {
                        const items = [];
                        if (filtersModified) {
                            if (
                                filtersForValue.columnField &&
                                filtersForValue.operatorValue &&
                                filtersForValue.value
                            ) {
                                items.push({
                                    columnField: filtersForValue.columnField,
                                    operatorValue:
                                        filtersForValue.operatorValue,
                                    value: filtersForValue.value,
                                });
                            }
                        }
                        return { items };
                    })(),
                },
            },
        };
    };

    ////////////////////// VIEW //////////////////////////

    return (
        <>
            <>
                <ModalEditUser
                    title="Orientador"
                    openModal={openModalNew}
                    setOpenModal={setOpenModalNew}
                    user={usuarioSelected}
                    tipoUsuario="ASSESSOR"
                />

                <ModalCreateUser
                    title="Orientador"
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    tipoUsuario="ASSESSOR"
                />
            </>

            <OrientadorGroupsModal
                open={openGroupsModal}
                setOpen={setOpenGroupsModal}
                assesorsId={groupsSelect}
            />

            {featureFlagCuraActiveUser && (
                <AddCuraUserModal
                    openModal={openAddCuraUserModal}
                    setOpenModal={setOpenAddCuraUserModal}
                />
            )}

            <ModalUsuarioPassword
                openModalPassword={openModalPassword}
                setOpenModalPassword={setOpenModalPassword}
                user={usuarioSelected}
            />

            <ModalUsuarioCelular
                openModal={openModalCellphone}
                setOpenModal={setOpenModalCellphone}
                user={usuarioSelected}
            />

            <Card sx={{ mt: 5 }}>
                <CardContent
                    sx={{
                        pt: 0,
                        pl: 0,
                        pr: 0,
                        pb: 0,
                        ' & .MuiDataGrid-root': {
                            border: 'none',
                        },
                        ' & .MuiDataGrid-toolbarContainer': {
                            justifyContent: 'flex-end',
                        },
                    }}
                >
                    <DataGrid
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        rows={dataGrid}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        autoHeight
                        components={{ Toolbar: OrientadorToolBar }}
                        componentsProps={{
                            panel: {
                                placement: 'bottom-end',
                            },
                        }}
                        onFilterModelChange={(event) =>
                            handleFilterChange(event)
                        }
                        {...filterSetterValues()}
                        disableDensitySelector
                    />
                </CardContent>
                <CardActions
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    {featureFlagCuraActiveUser && (
                        <Button
                            size="small"
                            startIcon={<AddIcon />}
                            variant="contained"
                            onClick={() => {
                                showAddCuraUserModal();
                            }}
                        >
                            Usuario CURA
                        </Button>
                    )}
                    <Button
                        size="small"
                        startIcon={<AddIcon />}
                        variant="contained"
                        onClick={() => {
                            setUsuarioSelected(null);
                            setOpenModal(true);
                        }}
                    >
                        Agregar
                    </Button>
                </CardActions>
            </Card>
        </>
    );
};

export default UsuariosOrientador;
