import React, { useState } from 'react';
import {
    Box,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    Divider,
} from '@mui/material';
import { ReactComponent as WatchIcon } from '../../../assets/icons/delete-trash-svgrepo-com.svg';
import { PopConfirm } from '../../../components/Tooltips/PopConfirm';
import { Space } from 'antd';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useFeedback } from '../../../../hooks';
import { selectCatConcepts } from '../../../store/cat_concepts/selectors';
import { DataGrid, esES } from '@mui/x-data-grid';
import CreateCatalog from './CreateCatalog';
import { DeleteCatConcepts } from '../../../store/cat_concepts/operations';
import useThemeColors from '../../../../theme/themes';
import DeleteSweepTwoToneIcon from '@mui/icons-material/DeleteSweepTwoTone';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CustomPagination from '../../../components/utilities/CustomPagination';
import { Typography as AntTypography } from 'antd';
import palomita from '../../../../public/assets/palomita.svg';
import { GridToolBar } from '../../../components/utilities/GridToolBar';

const CatalogModal = ({ open, onclose }) => {
    ///////////////// LOCAL STATE /////////////

    const [openModal, setOpenModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    ///////////// SHERD STATE /////////////

    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    const catConcepts = useSelector(selectCatConcepts);
    const colors = useThemeColors();
  

    ///////////// HANDLERS /////////////

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const handleDelete = async (id) => {
        await dispatch(DeleteCatConcepts({ catalogconcepts_id: id }))
            .unwrap()
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'Catalogo Eliminado con éxito',
                    severity: 'success',
                });
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title: error.feedback.title,
                    severity: error.feedback.severity,
                });
            });
    };

    ///////////// FUNCTIONS /////////////

    const filteredConcepts = catConcepts
        .filter((concept) => concept.active)
        .filter((concept) =>
            Object.values(concept)
                .join(' ')
                .toLowerCase()
                .includes(searchText.toLowerCase())
        );

    ///////////// RENDER /////////////

    const columns = [
        {
            field: 'concept_catalog_id',
            headerName: 'Folio',
            flex: 0.5,
            minWidth: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <Typography
                                color="primary"
                                sx={{
                                    fontWeight: 'bold',
                                }}>
                                {params.value}
                            </Typography>
                        </Box>
                    </>
                );
            },
        },
        {
            field: 'concept',
            headerName: 'Concepto',
            flex: 1,
            minWidth: 300,
            headerAlign: 'center',
        },
        {
            field: 'value',
            headerName: 'Importe',
            flex: 1,
            minWidth: 100,
            headerAlign: 'center',

            renderCell: (params) => (
                <>
                    <AttachMoneyOutlinedIcon
                        sx={{
                            color: colors.orange[500],
                            fontSize: '1.2rem',
                        }}
                    />
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            textAlign: 'right',
                            width: 'auto',
                            fontSize: '1rem',
                        }}>
                        {params.value}
                    </Typography>
                </>
            ),
        },
        {
            field: 'actions',
            headerName: 'Acciones',
            flex: 0.5,
            renderCell: (params) => (
              
                    <Space>
                    <PopConfirm
                          title="¿Estás seguro de que deseas continuar?"
                          message="Confirma tu decisión y sigamos adelante. Recuerda No podrás recuperar este registro después de eliminarlo."
                          icon={WatchIcon}
                          IconButton={DeleteSweepTwoToneIcon}
                          textButtonConfirm="Confirmar"
                          textButtonCancel="Cancelar"
                          messageButton={'Eliminar'}
                          color="#e24141"
                          iconSize={80}
                          placement='right'
                          topIcon='-17%'
                      onConfirm={() =>
                          handleDelete(params.row.catalogconcepts_id)
                          
                      }
                  />
              </Space>
              
                )
             
            
        },
    ];

    const rows = filteredConcepts
        .map((catalog) => ({
            id: catalog.concept_catalog_id,
            ...catalog,
        }))
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    return (
        <>
            <Dialog
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onclose();
                    }
                }}
                disableEscapeKeyDown
                sx={{
                    '& .MuiDialog-paper': {
                        borderRadius: 5,
                        width: '90%',
                        maxWidth: 900,
                        maxHeight: 'auto',
                        minHeight: 550,
                   
                    },
                }}>
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 1,
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                        }}>
                        <Box
                            component="img"
                            src={palomita}
                            alt="Descripción de la imagen"
                            sx={{
                                width: '15%',
                                height: 'auto',
                            }}
                        />
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            sx={{
                                display: 'flex',
                                ml: 2,
                                mr: 2,
                            }}
                        />
                        <Box>
                            <AntTypography.Title level={5}>
                                Catalogo de Conceptos
                            </AntTypography.Title>
                            <Typography
                                variant="body2"
                                sx={{
                                    width: '100%',
                                    mt: -1,
                                }}>
                                Aquí podrás observar el catalogo de los
                                conceptos
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                        }}>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            sx={{
                                display: 'flex',
                                ml: 2,
                                mr: 2,
                            }}
                        />
                        <IconButton
                            onClick={() => {
                                onclose();
                            }}>
                            <CloseIcon
                                sx={{
                                    color: '#000000ff',
                                    width: 20,
                                }}
                            />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Divider
                    sx={{
                        width: '95%',
                    }}
                    flexItem
                    variant="middle"
                />
                <DialogContent>
                    <Card
                        style={{
                            color: 'white',
                            borderRadius: '15px',
                            width: '100%',
                            height: '100%',
                            paddingTop: '10px',
                        }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            autoHeight
                            pageSize={10}
                            rowsPerPageOptions={[5, 10, 30]}
                            disableColumnReorder
                            localeText={
                                esES.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                            components={{
                                Toolbar: GridToolBar,
                                Pagination: CustomPagination,
                            }}
                            componentsProps={{
                                toolbar: {
                                    onAddButtonClick: handleOpen,
                                    ButtonText: 'Catálogo',
                                },
                            }}
                        />
                    </Card>
                </DialogContent>
            </Dialog>

            <CreateCatalog open={openModal} onclose={handleClose} />
        </>
    );
};

export default CatalogModal;
