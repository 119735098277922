import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Tooltip,
    CircularProgress,
} from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectTotalStudentsDiversity,
} from '../../../store/groups/fetchSlice';
import { getTotalStudentsDiversity } from '../../../store/groups/thunks';
import { selectComponentGroupsTotalStudentsDiversityStatusServer } from '../../../store/groups/componentStatusSelectors';
import { SkeletonGroupsTables } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';

const StudentsDiversityTable = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentGroupsTotalStudentsDiversityStatusServer
    );
    const studentsDiversity = useFetchResource(
        () =>
            getTotalStudentsDiversity({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectTotalStudentsDiversity,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getTotalStudentsDiversity({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectTotalStudentsDiversity,
            [cycleSelected, schoolId]
        );
    };

    const columns = [
        {
            field: 'Grado',
            headerName: 'Grado',
            minWidth: 100,
            flex: 1,
            headerClassName: 'custom-header',
        },
        {
            field: 'Grupo',
            headerName: 'Grupo',
            minWidth: 100,
            flex: 1,
            headerClassName: 'custom-header',
        },
        {
            field: 'Extranjeros',
            headerName: 'Extranjeros',
            minWidth: 150,
            flex: 1,
            headerClassName: 'custom-header',
        },
        {
            field: 'Foráneos',
            headerName: 'Foráneos',
            minWidth: 150,
            flex: 1,
            headerClassName: 'custom-header',
        },
        {
            field: 'Indígenas',
            headerName: 'Indígenas',
            minWidth: 150,
            flex: 1,
            headerClassName: 'custom-header',
        },
        {
            field: 'Nacionales',
            headerName: 'Nacionales',
            minWidth: 150,
            flex: 1,
            headerClassName: 'custom-header',
        },
        {
            field: 'Turno',
            headerName: 'Turno',
            minWidth: 100,
            flex: 1,
            headerClassName: 'custom-header',
        },
    ];

    const rows = studentsDiversity?.value
        ? studentsDiversity?.value['Grado'].map((_, index) => {
              const row = { id: index };
              columns.forEach((col) => {
                  row[col.field] =
                      studentsDiversity.value[col.field][index]?.value || 'N/A';
              });
              return row;
          })
        : [];

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbarColumnsButton {...props} />
                <GridToolbarFilterButton {...props} />
                <GridToolbarExport
                    csvOptions={{
                        utf8WithBom: true,
                        delimiter: ',',
                    }}
                />
            </GridToolbarContainer>
        );
    };

    const renderDataGrid = () => {
        return (
            <Box sx={{ minHeight: 690, width: '100%', mt: 2 }}>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[]}
                    disableSelectionOnClick
                    disableDensitySelector
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{
                        panel: {
                            placement: 'bottom-end',
                        },
                    }}
                    getRowHeight={() => 'auto'}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            overflow: 'visible',
                            lineHeight: '3',
                        },
                        '&.MuiDataGrid-root': {
                            border: 'none',
                        },
                    }}
                    autoHeight
                />
            </Box>
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && <SkeletonGroupsTables items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                    }}
                >
                    <CardContent>
                        <Tooltip
                            title={
                                studentsDiversity?.description ||
                                'Diversidad de alumnos por grupo'
                            }
                            followCursor
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ color: '#333' }}
                            >
                                {studentsDiversity?.title ||
                                    'Diversidad de alumnos por grupo'}
                            </Typography>
                        </Tooltip>
                        {studentsDiversity?.loading ? (
                            <CircularProgress
                                sx={{
                                    margin: '20px',
                                    display: 'block',
                                    mx: 'auto',
                                }}
                            />
                        ) : studentsDiversity?.error ? (
                            <Typography
                                color="error"
                                sx={{ textAlign: 'center' }}
                            >
                                Error: {studentsDiversity.error.message}
                            </Typography>
                        ) : (
                            renderDataGrid()
                        )}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default StudentsDiversityTable;
