import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Tooltip,
    CircularProgress,
} from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import useFetchResource from '../../../components/HelperHook';
import { useAuth } from '../../../../hooks';
import {
    getCycleSelected,
    selectBestScoreGroup,
} from '../../../store/groups/fetchSlice';
import { getBestScoreGroup } from '../../../store/groups/thunks';
import { selectComponentGroupsBestScoreGroupStatusServer } from '../../../store/groups/componentStatusSelectors';
import { SkeletonGroupsSmallCards } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { Tag } from 'antd';

const BestScoreGroupTable = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentGroupsBestScoreGroupStatusServer
    );

    const bestScoreGroup = useFetchResource(
        () =>
            getBestScoreGroup({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectBestScoreGroup,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getBestScoreGroup({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectBestScoreGroup,
            [cycleSelected, schoolId]
        );
    };

    const [pageSize, setPageSize] = useState(10);

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbarColumnsButton {...props} />
                <GridToolbarFilterButton {...props} />
                <GridToolbarExport
                    csvOptions={{
                        utf8WithBom: true,
                        delimiter: ',',
                    }}
                />
            </GridToolbarContainer>
        );
    };

    const renderDataGrid = () => {
        const columns = [
            {
                field: 'group',
                headerName: 'Grupo',
                flex: 1,
            },
            {
                field: 'score',
                headerName: 'Puntaje',
                flex: 0.7,
                renderCell: (params) => (
                    <Tag
                        color="success"
                        style={{
                            width: '100px',
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                        }}
                    >
                        {params.value}
                    </Tag>
                ),
            },
        ];

        const rows = bestScoreGroup?.value
            ? Object.keys(bestScoreGroup?.value).map((key, index) => ({
                  id: index,
                  group: bestScoreGroup.value[key].label,
                  score:
                      bestScoreGroup.value[key].data !== 'N/A'
                          ? bestScoreGroup.value[key].data
                          : 'N/A',
              }))
            : [];

        return (
            <Box sx={{ height: 210, width: '100%', mt: 2 }}>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[]}
                    disableSelectionOnClick
                    disableDensitySelector
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{
                        panel: {
                            placement: 'bottom-end',
                        },
                    }}
                    getRowHeight={() => 'auto'}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            overflow: 'visible',
                            lineHeight: '3',
                        },
                        '&.MuiDataGrid-root': {
                            border: 'none',
                        },
                    }}
                    autoHeight
                />
            </Box>
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonGroupsSmallCards items={9} />
            )}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        maxWidth: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                    }}
                >
                    <CardContent>
                        <Tooltip
                            title={
                                bestScoreGroup?.description ||
                                'Grupos con mejor promedio en la escuela'
                            }
                            followCursor
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ color: '#333' }}
                            >
                                {bestScoreGroup?.title ||
                                    'Grupos con mejor promedio'}
                            </Typography>
                        </Tooltip>
                        {bestScoreGroup?.loading ? (
                            <CircularProgress
                                sx={{
                                    margin: '20px',
                                    display: 'block',
                                    mx: 'auto',
                                }}
                            />
                        ) : bestScoreGroup?.error ? (
                            <Typography
                                color="error"
                                sx={{ textAlign: 'center' }}
                            >
                                Error: {bestScoreGroup?.error.message}
                            </Typography>
                        ) : (
                            renderDataGrid()
                        )}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default BestScoreGroupTable;
