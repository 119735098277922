import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeedback } from '../../../../hooks';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    IconButton,
    Tooltip,
} from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import LinkIcon from '@mui/icons-material/Link';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import SkeletonPage from '../../../components/SkeletonPage';
import UsersModal from './UsersModal';
import GridCell from './GridCellExpand';
import { Error } from '../../../../components/Feedback';
import { selectAllNoticesItems } from '../../../store/home/itemsSlice';
import {
    fetchHomeNoticesData,
    selectFetchingStatus,
} from '../../../store/home/fetchSlice';
import {
    noticeSelected,
    selectHomeNoticesFilter,
    updateHomeFilters,
} from '../../../store/home/uiSlice';

const HomeTable = ({ schoolId, type }) => {
    /////////////////// HOOKS ///////////////
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    //////////////// SHARED STATE ////////////////
    const notices = useSelector(selectAllNoticesItems);
    const fetchingStatus = useSelector(selectFetchingStatus);
    const noticesFilter = useSelector(selectHomeNoticesFilter);

    ////////////////// LOCAL STATE ///////////////
    const [openUsersModal, setOpenUsersModal] = useState(false);
    const [currentViewModel, setCurrentViewModel] = useState({});
    const [modalType, setModalType] = useState('');
    const columns = [
        {
            field: 'emisor',
            headerName: 'Emisor',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ row }) => {
                let emmiterName = 'Usuario no encontrado';

                if (row.emitter) {
                    let emitter = row.emitter;
                    emmiterName = `${emitter.last_name} ${emitter.second_last_name} ${emitter.name}`;
                }

                return emmiterName;
            },
            renderCell: (params) => {
                let emitter = params.row.emitter;

                if (!emitter) {
                    const Fullemitter = params.value;
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {Fullemitter}
                        </div>
                    );
                }

                return (
                    <>
                        <Avatar
                            sx={{ width: 24, height: 24, mr: 1 }}
                            src={emitter.url_photo_profile}
                        />
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {params.value}
                        </div>
                    </>
                );
            },
        },
        {
            field: 'title',
            headerName: 'Título',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const FullTitle = `${params.row.title}`;
                return (
                    <div
                        style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
                    >
                        {FullTitle}
                    </div>
                );
            },
        },
        {
            field: 'subtitle',
            headerName: 'Subtítulo',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: function renderCellExpand(params) {
                return (
                    <GridCell
                        value={params.value || ''}
                        width={params.colDef.computedWidth}
                    />
                );
            },
        },
        {
            field: 'description',
            headerName: 'Descripción',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: function renderCellExpand(params) {
                return (
                    <GridCell
                        value={params.value || ''}
                        width={params.colDef.computedWidth}
                    />
                );
            },
        },
        {
            field: 'created_at',
            headerName: 'Creación',
            flex: 0.8,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => value,
            valueFormatter: ({ value }) => {
                return value;
            },
        },
        {
            field: 'url',
            headerName: 'Link',
            type: 'date',
            flex: 0.8,
            maxWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => value,
            renderCell: ({ value }) => {
                if (value) {
                    return (
                        <IconButton href={value} target="_blank">
                            <LinkIcon color="primary" />
                        </IconButton>
                    );
                } else {
                    return <ErrorOutlineIcon color="error" />;
                }
            },
        },
        {
            field: 'sentNotifications',
            headerName: 'Enviado',
            flex: 1,
            maxWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => {
                return value.length;
            },
            renderCell: (params) => (
                <>
                    <Tooltip title="Mostrar notificaciones enviadas">
                        <Button
                            size="small"
                            variant="outlined"
                            startIcon={<ListIcon />}
                            onClick={() => {
                                dispatch(
                                    noticeSelected(params.row.viewModel.id)
                                );
                                openModal(params.row.viewModel, 'sent');
                            }}
                        >
                            {params.value}
                        </Button>
                    </Tooltip>
                </>
            ),
        },
        {
            field: 'noticedNotifications',
            headerName: 'Enterado',
            flex: 1,
            maxWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => {
                return value.length;
            },
            renderCell: (params) => (
                <>
                    <Tooltip title="Mostrar notificaciones enviadas">
                        <Button
                            size="small"
                            variant="outlined"
                            startIcon={<ListIcon />}
                            onClick={() => {
                                dispatch(
                                    noticeSelected(params.row.viewModel.id)
                                );
                                openModal(params.row.viewModel, 'noticed');
                            }}
                        >
                            {params.value}
                        </Button>
                    </Tooltip>
                </>
            ),
        },
        {
            field: 'readNotifications',
            headerName: 'Leído',
            flex: 1,
            maxWidth: 90,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => {
                return value.length;
            },
            renderCell: (params) => (
                <>
                    <Tooltip title="Mostrar notificaciones enviadas">
                        <Button
                            size="small"
                            variant="outlined"
                            startIcon={<ListIcon />}
                            onClick={() => {
                                dispatch(
                                    noticeSelected(params.row.viewModel.id)
                                );
                                openModal(params.row.viewModel, 'read');
                            }}
                        >
                            {params.value}
                        </Button>
                    </Tooltip>
                </>
            ),
        },
    ];

    /////////////////////// HANDLERS //////////////////////
    useEffect(() => {
        dispatch(
            fetchHomeNoticesData({
                schoolId,
                type,
            })
        );
    }, []);

    /**
     * Funcion para recargar los avisos
     */
    const reload = () => {
        dispatch(
            fetchHomeNoticesData({
                schoolId,
                type,
            })
        )
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const openModal = (vm, type) => {
        setCurrentViewModel(vm);
        setModalType(type);
        setOpenUsersModal(true);
    };

    const handleFilterChange = (event, value) => {
        let newFilterValues = {};

        event.items.forEach((filter) => {
            const { columnField, operatorValue, value } = filter;

            newFilterValues = {
                columnField,
                operatorValue,
                value,
            };
        });
        dispatch(updateHomeFilters({ filter: newFilterValues }));
    };

    return (
        <>
            <Box>
                {fetchingStatus == 'pending' && <SkeletonPage items={9} />}

                {fetchingStatus == 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}
                {fetchingStatus === 'fulfilled' && (
                    <>
                        <Card sx={{ mt: 5 }}>
                            <CardContent
                                sx={{
                                    pt: 0,
                                    pl: 0,
                                    pr: 0,
                                    pb: '0 !important',
                                    ' & .MuiDataGrid-root': {
                                        border: 'none',
                                    },
                                    ' & .MuiDataGrid-toolbarContainer': {
                                        justifyContent: 'flex-end',
                                    },
                                }}
                            >
                                <DataGrid
                                    localeText={
                                        esES.components.MuiDataGrid.defaultProps
                                            .localeText
                                    }
                                    rows={notices}
                                    getRowId={(row) => row.notice_id}
                                    columns={columns}
                                    pageSize={10}
                                    disableSelectionOnClick
                                    autoHeight
                                    components={{
                                        Toolbar: () => (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <GridToolbar />
                                            </div>
                                        ),
                                    }}
                                    componentsProps={{
                                        panel: {
                                            placement: 'bottom-end',
                                        },
                                    }}
                                    onFilterModelChange={(event) =>
                                        handleFilterChange(event)
                                    }
                                    initialState={{
                                        filter: {
                                            filterModel: {
                                                items: noticesFilter
                                                    ? [
                                                          {
                                                              columnField:
                                                                  noticesFilter.columnField ||
                                                                  '',
                                                              operatorValue:
                                                                  noticesFilter.operatorValue ||
                                                                  '',
                                                              value:
                                                                  noticesFilter.value ||
                                                                  '',
                                                          },
                                                      ]
                                                    : [],
                                            },
                                        },
                                    }}
                                    disableDensitySelector
                                />
                            </CardContent>
                        </Card>
                    </>
                )}
            </Box>

            {openUsersModal && (
                <UsersModal
                    open={openUsersModal}
                    setOpen={setOpenUsersModal}
                    viewModel={currentViewModel}
                    type={modalType}
                />
            )}
        </>
    );
};

export default HomeTable;
