import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../../service/Connection';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../../service/const';
import Feedback from '../../../../service/Feedback';
import { emptyState } from '../../emptyState';

const initialState = {
    Top_Debtors: {
        fetch: {
            ...emptyState,
        },
        data: [],
    },
};

////////////////////////////////////// SLICE // ////////////////////////////////////
const topsSlice = createSlice({
    name: 'tops',
    initialState,
    reducers: {
        invalidate_Top_Debtors: (state) => {
            state.Top_Debtors.fetch.didInvalidate = true;
        },
    },

    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return initialState;
        });

        const pendingServerStatus_Top_Debtors = (state, action) => {
            state.Top_Debtors.fetch.statusServer = 'pending';
        };

        /**
         * Termina la carga de informacion
         */

        builder.addCase(loadUITopDebtors.fulfilled, (state, action) => {
            state.Top_Debtors.fetch = {
                ...state.Top_Debtors.fetch,
                expireIn: new Date().setMinutes(
                    new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                ),
                fetchingAt: Date.now(),
                didInvalidate: false,
                statusServer: 'fulfilled',
            };

            state.Top_Debtors.data = action.payload.debtors.data.components;
        });
        builder.addCase(
            loadUITopDebtors.pending,
            pendingServerStatus_Top_Debtors
        );
        builder.addCase(loadUITopDebtors.rejected, (state, action) => {
            state.Top_Debtors.fetch.statusServer = 'rejected';
            state.Top_Debtors.fetch.error = action.error;
        });
    },
});

export const { invalidate_Top_Debtors } = topsSlice.actions;

export default topsSlice.reducer;

///////////// THUNKS  ////////////

export const loadUITopDebtors = createAsyncThunk(
    'homeUI/TopDebtors/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const debtors = await Connection.getPaymentsInformation({
                school_id: schoolId,
                data: {
                    page: 'home',
                    type: 'table',
                    idKey: 'top_debtors',
                },
            }).then((response) => response.data);

            return { debtors };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState }) => {
            let { didInvalidate, expireIn } = selectTop_Top_Debtors(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

///////////// SELECTORS  ////////////

export const selectTop = (state) => state.paymentsModule.home.tops;

/**
 * Selector para recuperar el estado de la petición de los ultimos  estudiantes con descuento
 */
export const selectTop_Top_Debtors = createSelector(
    [selectTop],
    (top) => top.Top_Debtors.fetch
);

export const selectTopStatus_Top_Debtors = createSelector(
    [selectTop_Top_Debtors],
    (fetch) => fetch.statusServer
);

export const selectTopDebtors = createSelector(
    [selectTop],
    (top) => top.Top_Debtors.data
);
