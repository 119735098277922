import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../../service/Connection';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../../service/const';
import Feedback from '../../../../service/Feedback';
import { emptyState } from '../../emptyState';

const initialState = {
    Discount_Student: {
        fetch: {
            ...emptyState,
        },
        data: [],
    },
};

////////////////////////////////////// SLICE // ////////////////////////////////////
const cardsSlice = createSlice({
    name: 'homeUI/cards/server',
    initialState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate_lastStudentWithDiscount: (state) => {
            state.Discount_Student.fetch.didInvalidate = true;
        },
    },

    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return initialState;
        });

        const pendingServerStatus = (state, action) => {
            state.Discount_Student.fetch.statusServer = 'pending';
        };

        /**
         * Termina la carga de informacion
         */

        builder.addCase(
            loadUILastStudentWithDiscountCard.fulfilled,
            (state, action) => {
                state.Discount_Student.fetch = {
                    ...state.Discount_Student.fetch,
                    expireIn: new Date().setMinutes(
                        new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                    ),
                    fetchingAt: Date.now(),
                    didInvalidate: false,
                    statusServer: 'fulfilled',
                };
                state.Discount_Student.data = action.payload.data.components;
            }
        );
        builder.addCase(
            loadUILastStudentWithDiscountCard.pending,
            pendingServerStatus
        );
        builder.addCase(
            loadUILastStudentWithDiscountCard.rejected,
            (state, action) => {
                state.Discount_Student.fetch.statusServer = 'rejected';
                state.Discount_Student.fetch.error = action.error;
            }
        );
    },
});

export const { invalidate_lastStudentWithDiscount } = cardsSlice.actions;

export default cardsSlice.reducer;

////////////////////////////////////// THUNKS // ////////////////////////////////////
/**
 * Cargar informacion de la UI de los ultimos estudiantes con descuento
 */
export const loadUILastStudentWithDiscountCard = createAsyncThunk(
    'homeUI/lastStudentWithDiscountCard/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const lastStudentWithDiscount =
                await Connection.getPaymentsInformation({
                    school_id: schoolId,
                    data: {
                        page: 'home',
                        type: 'card',
                        idKey: 'last_student_with_discount',
                    },
                }).then((res) => res.data);

            return lastStudentWithDiscount;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectCard_Discount_Student(
                getState()
            );

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

// //////////////////////////////////// SELECTORES // ////////////////////////////////////

export const selectCard = (state) => state.paymentsModule.home.cards;

/**
 * Selector para recuperar el estado de la petición de los ultimos  estudiantes con descuento
 */
export const selectCard_Discount_Student = createSelector(
    [selectCard],
    (card) => card.Discount_Student.fetch
);

export const selectCardStatus_Discount_Student = createSelector(
    [selectCard_Discount_Student],
    (fetch) => fetch.statusServer
);

export const selectLastStudentWithDiscounts = createSelector(
    [selectCard],
    (card) => card.Discount_Student.data
);
