import React from 'react';
import { Box, Button, Typography, Paper } from '@mui/material';
import PencilError from '../../assets/img/PencilError.png';
import ErrorIncon from '../../assets/icons/computer-fail-svgrepo-com.svg';
const ErrorPage = (props) => {
    const { onRetry, message, maxWidth, mt, alingContent } = props;

    const onClickRetry = () => {
        onRetry();
    };

    return (
        <Box
            position="relative"
            sx={{
                textAlign: 'center',
                borderRadius: '16px',
                height: '100%',
                shadow: 3,
            }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1,
                    backgroundColor: '#ed6a4e',
                    borderTopLeftRadius: '16px',
                    borderTopRightRadius: '16px',
                    height: '50%',
                }}>
                <img src={ErrorIncon} alt="Error" width={100} height={150} />
            </Box>

            {/* Caja de error */}
            <Paper
                elevation={3}
                sx={{
                    padding: '2rem',
                    textAlign: 'center',
                    borderBottomLeftRadius: '16px',
                    borderBottomRightRadius: '16px',
                    paddingTop: '2rem',
                    shadow: 3,
                    height: '50%',
                }}>
                <Box
                    sx={{
                        marginTop: '1rem',
                        marginBottom: '1rem',
                        textAlign: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 1)',
                    }}>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                            fontSize: '0.7rem',
                        }}>
                        {message}
                    </Typography>
                </Box>
                {/* Descripción */}

                {/* Botón */}
                <Button
                    variant="contained"
                    onClick={onClickRetry}
                    sx={{
                        position: 'absolute',
                        bottom: '-10px', // Ajusta este valor según sea necesario para mover el botón más abajo del contenedor
                        left: '50%',
                        transform: 'translateX(-50%)',
                        borderRadius: '30px',
                        fontWeight: 'bold',
                        backgroundColor: '#ed6a4e',
                        '&:hover': {
                            backgroundColor: '#d65a42',
                        },
                    }}>
                    Intentar de nuevo
                </Button>
            </Paper>
        </Box>
    );
};

export default ErrorPage;
