import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Stack,
} from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid, esES } from '@mui/x-data-grid';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSettingByKey } from '../../../../../store/slices/entities/settings';
import { selectStudentCalificationsView } from '../../../../../views/Scores/store/ItemsSlice';
import EditIcon from '@mui/icons-material/Edit';
import RequestPermission from '../../../../../components/RequestPermission';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import SaveIcon from '@mui/icons-material/Save';
import DnsIcon from '@mui/icons-material/Dns';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import Feedback from '../../../../../service/Feedback';
import Services from '../../../../../service/Connection';
import { upsertManyCalifications } from '../../../../../store/slices/entities/califications';
import { upsertManySubjects } from '../../../../../store/slices/entities/subjects';
import { upsertOneStudent } from '../../../../../store/slices/entities/students';
import ScoresToolBar from './ScoresToolBar';
import useFeedback from '../../../../../hooks/useFeedback';
import SubjectCell from './SubjectCell';
import ScoreNotEditableCell from './ScoreNotEditableCell';
import { truncate } from '../../../../../helper';
import { DateTime } from 'luxon';
import ScoreEditableCell from './ScoreEditableCell';
import { getFinalScoreUsingRegularization } from '../../../utils';
import { upsertOneGroup } from '../../../../../store/slices/entities/groups';
import FeatureFlags from '../../../../../service/FeatureFlags';

/**
 * Componente para mostrar las calificaciones de un alumno
 */
const ScoresCard = ({ studentId, groupId }) => {
    /////////////// HOOKS ////////////////////////

    const dispatch = useDispatch();
    let FeedbackService = new Feedback();
    const feedbackApp = useFeedback();

    /////////////// SHARED STATE //////////////

    const scoreMinimum = useSelector(
        selectSettingByKey('calification-minimum')
    );

    const newData = useSelector(
        selectStudentCalificationsView(parseInt(studentId))
    );

    const featureFlagWeighing = FeatureFlags.isFeatureFlagActive(
        'NEW_WEIGHING_VALUES'
    );

    /////////////// LOCAL STATE ///////////////

    const [isEditMode, setIsEditMode] = useState(false);
    const [openRequestPermission, setOpenRequestPermission] = useState(false);

    const [calificationsRows, setCalificationsRows] = useState([]);
    const [calificationsColumns, setCalificationsColumns] = useState([]);

    const [countDown, setCountDown] = useState(0);

    const [saveScoreStatus, setSaveScoreStatus] = useState('idle');

    const updateStatus = {
        1: {
            color: 'grey',
            text: 'Profesor',
            icon: <PersonIcon />,
            class: 'left-status-onhold',
        },
        2: {
            color: '#ffbf43',
            text: 'Administrador',
            icon: <LaptopChromebookIcon />,
            class: 'left-status-processing',
        },
        3: {
            color: '#0a90d3',
            text: 'PIE',
            icon: <DnsIcon />,
            class: 'left-status-imported',
        },
    };

    ////////////// ACTIONS /////////////////////

    useEffect(() => {
        let x = null;

        if (isEditMode) {
            var countDownDate = new Date().setMinutes(
                new Date().getMinutes() + 30
            );

            x = setInterval(function () {
                var now = new Date().getTime();

                var distance = countDownDate - now;

                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor(
                    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                );
                var minutes = Math.floor(
                    (distance % (1000 * 60 * 60)) / (1000 * 60)
                );
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                setCountDown(minutes + '.' + seconds);

                if (distance < 0) {
                    setIsEditMode(false);
                }
            }, 1000);

            return () => {
                clearInterval(x);
            };
        } else {
            clearInterval(x);
        }
    }, [isEditMode]);

    /**
     * Inicia el proceso para dar formato a los datos
     */
    useEffect(() => {
        if (saveScoreStatus == 'idle' || saveScoreStatus == 'fulfilled') {
            const data = buildDataGrid(newData);

            setCalificationsRows(data);
        }
    }, [isEditMode, studentId, saveScoreStatus]);

    /**
     * funcion que permite contruir el data grid
     */
    const buildDataGrid = ({
        group,
        partials,
        subjects,
        subjectCatalog,
        califications,
        regularizations,
    }) => {
        let currentDate = moment().format('YYYY-MM-DD hh:mm:ss');

        let minScoreApproved = scoreMinimum?.value;

        let signatures = subjects.map((subjet) => {
            let catalog = _.find(subjectCatalog, {
                catalog_subject_id: subjet.catalog_subject_id,
            });

            /**
             * Definicion inicial de las asignaturas
             */
            let signature = {
                payload: {
                    ...subjet,
                },
                name: {
                    name: true,
                    value: catalog.title,
                },
            };

            let partialEvaluations = {};
            let regularizationEvaluations = {};

            let inSchoolingProcess =
                partials.filter(
                    (partial) =>
                        DateTime.fromSQL(partial.limit_date) > DateTime.now()
                ).length > 0;

            let scoreValuesSum = 0;
            let scoreTotal = 0;

            let hasWeighing = partials.some((partial) => partial.weighing > 0);
            let hasPonderedScore = false;
            let totalWeighing = partials.reduce(
                (sum, partial) => sum + (partial.weighing || 0),
                0
            );

            let remainingWeighing = 100 - totalWeighing;

            let zeroWeighingScoreSum = 0;
            let zeroWeighingCount = 0;

            for (let partial of partials) {
                let score = _.find(califications, {
                    subject_id: subjet.subject_id,
                    partial_id: partial.partial_id,
                });

                let scoreValue = 0;
                hasPonderedScore =
                    partial.weighing > 0 && score?.calification > 0;

                if (score?.calification) {
                    scoreValue = score.calification;
                    scoreValuesSum += scoreValue;
                    ++scoreTotal;

                    if (hasWeighing && partial.weighing === 0) {
                        zeroWeighingScoreSum += score.calification;
                        zeroWeighingCount++;
                    }
                }

                partialEvaluations[`p-${partial.partial}`] = {
                    partial: true,
                    value: scoreValue,
                    description: score?.description || '',
                    add_description: '',
                    in_recovery: score?.in_recovery || false,
                    calificationItem: score,
                    partialItem: partial,
                    isExists: score != undefined,
                    partialActive:
                        currentDate >= partial.start_date &&
                        currentDate <= partial.limit_date,
                };
            }

            let schoolingScoreAverage;

            if (hasPonderedScore && featureFlagWeighing) {
                let zeroWeighingAverage =
                    zeroWeighingCount > 0
                        ? zeroWeighingScoreSum / zeroWeighingCount
                        : 0;

                let remainingWeighingScore =
                    zeroWeighingAverage * (remainingWeighing / 100);

                let weightedScoreSum = 0;
                partials.forEach((partial) => {
                    if (partial.weighing > 0) {
                        let score = _.find(califications, {
                            subject_id: subjet.subject_id,
                            partial_id: partial.partial_id,
                        });

                        weightedScoreSum +=
                            (score?.calification || 0) *
                            (partial.weighing / 100);
                    }
                });

                schoolingScoreAverage = truncate(
                    remainingWeighingScore + weightedScoreSum,
                    1
                );
            } else {
                schoolingScoreAverage = truncate(
                    scoreValuesSum > 0 ? scoreValuesSum / scoreTotal : 0,
                    1
                );
            }

            let isApprovedInSchoolingProcess =
                schoolingScoreAverage >= minScoreApproved;

            for (let regularization of regularizations) {
                let score = _.find(califications, {
                    subject_id: subjet.subject_id,
                    partial_id: regularization.partial_id,
                });

                let scoreValue = 0;
                let isApproved = false;

                if (score?.calification) {
                    scoreValue = score.calification;
                    isApproved = score.calification >= minScoreApproved;
                }

                regularizationEvaluations[`r-${regularization.partial}`] = {
                    partial: true,
                    value: scoreValue,
                    description: score?.description || '',
                    add_description: '',
                    in_recovery: score?.in_recovery || false,
                    calificationItem: score,
                    partialItem: regularization,
                    isExists: score != undefined,
                    partialActive:
                        currentDate >= regularization.start_date &&
                        currentDate <= regularization.limit_date,
                    isAllowEditing: false,
                    isApproved,
                };
            }

            let finalScore = schoolingScoreAverage;
            let isApprovedInRegularizationProcess = false;

            if (!isApprovedInSchoolingProcess) {
                let scoreInRegularization = getFinalScoreUsingRegularization(
                    Object.values(regularizationEvaluations)
                );

                isApprovedInRegularizationProcess =
                    scoreInRegularization >= minScoreApproved;

                if (scoreInRegularization > 0) {
                    finalScore = scoreInRegularization;
                }
            }

            /**
             * Asignacion de datos adicionales a la asignatura
             */
            signature = {
                ...signature,
                ...partialEvaluations,
                total: {
                    total: true,
                    value: schoolingScoreAverage,
                },
                ...regularizationEvaluations,
                totalreg: {
                    total: true,
                    value: finalScore,
                },
                hasRegularization: true,
                inSchoolingProcess,
                isApprovedInSchoolingProcess,
                isApprovedInRegularizationProcess,
            };

            return signature;
        });

        return signatures;
    };

    /**
     * Efecto para generar nuevas columnas si es que se
     * modificaron los datos de las calificaciones
     */
    useEffect(() => {
        let currentDate = moment().format('YYYY-MM-DD hh:mm:ss');

        let columnsDefinitions = _.chain([
            {
                column: 'name',
                title: 'MATERIAS',
                sticky: true,
                className: '',
            },
            ...newData.partials.map((partial, i) => ({
                column: `p-${i + 1}`,
                title: `P${i + 1}`,
                sticky: false,
                editable: true,
                className: 'parcial-column',
                partialActive:
                    currentDate >= partial.start_date &&
                    currentDate <= partial.limit_date,
            })),
            {
                column: `total`,
                title: `PROMEDIO`,
                sticky: false,
                className: 'parcial-column',
            },
            ...newData.regularizations.map((partial, i) => ({
                column: `r-${i + 1}`,
                title: `R${i + 1}`,
                sticky: false,
                editable: true,
                className: 'parcial-column',
                partialActive:
                    currentDate >= partial.start_date &&
                    currentDate <= partial.limit_date,
            })),
        ])
            .tap((columns) => {
                if (newData.regularizations.length) {
                    columns.push({
                        column: `totalreg`,
                        title: `FINAL`,
                        sticky: false,
                        className: 'parcial-column',
                    });
                }
            })
            .value()
            .map((column) => {
                return {
                    field: column.column,
                    headerName: column.title,
                    flex: 0.4,
                    minWidth: 100,
                    editable: false,
                    headerAlign: 'center',
                    align: 'center',
                    headerClassName: column.partialActive
                        ? 'partial-active--cell'
                        : '',
                    cellClassName: (params) => {
                        let row = params.row[params.field]?.partialActive;

                        if (row) {
                            return 'partial-active--cell';
                        }

                        return '';
                    },
                    valueGetter: (params) => {
                        return params.value.value;
                    },
                    valueFormatter: ({ value }) => {
                        return `${value || ''}`;
                    },
                    renderCell: (params) => {
                        let row = params.row;
                        let columnName = params.field;
                        let currentCell = params.row[columnName];

                        if (columnName === 'name') {
                            return (
                                <SubjectCell value={params.value}></SubjectCell>
                            );
                        }

                        if (currentCell?.partial) {
                            let scoreValues = params.row[params.field];

                            if (isEditMode) {
                                let partial = currentCell.partialItem;

                                let forSchoolingProcess =
                                    !partial.is_regularized &&
                                    row.inSchoolingProcess;
                                let forRegularizationProcess =
                                    partial.is_regularized &&
                                    currentCell.partialActive &&
                                    !row.inSchoolingProcess &&
                                    !row.isApprovedInSchoolingProcess &&
                                    ((row.isApprovedInRegularizationProcess &&
                                        currentCell?.isApproved) ||
                                        !row.isApprovedInRegularizationProcess);

                                const exists = currentCell.isExists;

                                const initialScore =
                                    params.row[params.field].calificationItem;

                                const shouldShowCheckbox =
                                    exists &&
                                    forSchoolingProcess &&
                                    (initialScore?.calification <
                                        scoreMinimum?.value ||
                                        (initialScore?.calification >=
                                            scoreMinimum?.value &&
                                            scoreValues.in_recovery));

                                const isCheckboxEnabled =
                                    exists &&
                                    forSchoolingProcess &&
                                    ((initialScore?.calification <
                                        scoreMinimum?.value &&
                                        scoreValues.value <
                                            scoreMinimum?.value) ||
                                        initialScore?.in_recovery);

                                if (
                                    forSchoolingProcess ||
                                    forRegularizationProcess
                                ) {
                                    return (
                                        <ScoreEditableCell
                                            defaultValue={scoreValues.value}
                                            description={
                                                scoreValues.description
                                            }
                                            in_recovery={
                                                scoreValues.in_recovery
                                            }
                                            add_description={
                                                scoreValues.add_description
                                            }
                                            subjectId={
                                                params.row.payload.subject_id
                                            }
                                            cell={columnName}
                                            key={
                                                params.row.payload.subject_id +
                                                '-' +
                                                partial.partial_id
                                            }
                                            scoreMinimum={scoreMinimum?.value}
                                            shouldShowCheckbox={
                                                shouldShowCheckbox
                                            }
                                            isCheckboxEnabled={
                                                isCheckboxEnabled
                                            }
                                            onBlur={onInputBlur}
                                            onChange={onInputChange}
                                        />
                                    );
                                }
                            }
                        }

                        return (
                            <ScoreNotEditableCell
                                value={params.value == null ? '' : params.value}
                                minScore={scoreMinimum?.value}
                                row={row[columnName]}
                            />
                        );
                    },
                };
            });

        setCalificationsColumns(columnsDefinitions);
    }, [calificationsRows]);

    const getCellClassName = (params) => {
        const cellData = params.row[params.field];
        const currentDate = new Date();

        if (cellData?.partial) {
            const startDate = new Date(cellData.partialItem.start_date);
            const endDate = new Date(cellData.partialItem.limit_date);

            const isActiveOrPastPartial =
                (currentDate >= startDate && currentDate <= endDate) ||
                currentDate > endDate;

            if (isActiveOrPastPartial && params.value === 0) {
                return 'zero-value-cell';
            }
        }
        return '';
    };

    /**
     * Accion solicitar los permisos de edicion de calificaciones
     */
    const onEditScores = () => {
        setOpenRequestPermission(true);
    };

    /**
     * Actualizacion de los inputs de las calificaciones
     */
    const onInputChange =
        ({ subjectId, cell, isCheckbox, isComment }) =>
        (event) => {
            if (event.key === 'r' || event.key === 'c') {
                event.preventDefault();
                return;
            }
            const value = event.target.value;
            const checked = event.target.checked;

            // if (!isNaN(value)) {
            //     if (value < 0) {
            //         value = 0;
            //     }

            //     if (value > 10) {
            //         value = 10;
            //     }
            // } else {
            //     value = 0;
            // }

            let nextRows = calificationsRows.map((row) => {
                const updatedRow = { ...row };
                if (subjectId == row.payload.subject_id) {
                    if (isCheckbox) {
                        updatedRow[cell] = {
                            ...updatedRow[cell],
                            in_recovery: checked,
                            changed: true,
                        };
                    } else if (!isComment) {
                        updatedRow[cell] = {
                            ...updatedRow[cell],
                            value: value,
                            changed: true,
                        };
                    } else {
                        updatedRow[cell] = {
                            ...updatedRow[cell],
                            add_description: value,
                            changed: true,
                        };
                    }
                }
                return updatedRow;
            });
            setCalificationsRows(nextRows);
        };

    const onInputBlur =
        ({ subjectId, cell, in_recovery }) =>
        (e) => {
            let value = e.target.value;

            let nextRows = calificationsRows.map((row) => {
                const updatedRow = { ...row };
                if (subjectId == row.payload.subject_id) {
                    let scoreItem = updatedRow[cell].calificationItem;
                    if (scoreItem) {
                        if (
                            (scoreItem.in_recovery || in_recovery) &&
                            Number(value) < scoreMinimum?.value
                        ) {
                            updatedRow[cell] = {
                                ...updatedRow[cell],
                                value: scoreMinimum?.value,
                                changed: true,
                            };
                        }
                    }
                }
                return updatedRow;
            });
            setCalificationsRows(nextRows);
        };

    /**
     * Habilita la edicion de las calificaciones
     */
    const onAllowPermision = (resolved) => {
        setOpenRequestPermission(false);
        if (resolved == 'allow') {
            setIsEditMode(true);
        }
    };

    /**
     * El usuario cancelo la edicion
     */
    const onCancelEdit = () => {
        setIsEditMode(false);

        let nextRows = calificationsRows.map((row) => {
            let newRow = {};

            for (const column in row) {
                if (row[column]?.partial) {
                    newRow[column] = {
                        ...row[column],
                        value: row[column]?.calificationItem
                            ? row[column].calificationItem.calification
                            : 0,
                    };
                } else {
                    newRow[column] = row[column];
                }
            }

            return newRow;
        });
    };

    /**
     * Se actualizan o se crean ls calificaciones
     */
    const onSaveScores = () => {
        let createRequest = [];
        let updateRequest = [];

        let calificationNotProceced = [];

        /**
         * De cada materia recuperamos sus calificaciones
         */
        for (let subject of calificationsRows) {
            for (const property in subject) {
                if (/p-[0-9]/.test(property) || /r-[0-9]/.test(property)) {
                    calificationNotProceced.push({
                        ...subject[property],
                        subject: subject.payload,
                        changed: subject[property].changed
                            ? subject[property].changed
                            : false,
                    });
                }
            }
        }

        /**
         * Filtramos las materias que posiblemente vallan a ser creadas
         * o editadas
         */
        let califications = calificationNotProceced.filter((item) => {
            return (
                item.value != 0 &&
                item.value != '' &&
                item.value != null &&
                item.changed
            );
        });

        updateRequest.push(
            ...califications
                .filter((item) => item.isExists)
                .map((item) => {
                    return {
                        calification: item.value,
                        calification_id: item.calificationItem.calification_id,
                        description: item.add_description,
                        in_recovery: item.in_recovery,
                    };
                })
        );

        createRequest.push(
            ...califications
                .filter((item) => !item.isExists)
                .map((item) => {
                    return {
                        is_final: 0,
                        calification: item.value,
                        description: item.add_description,
                        in_recovery: item.in_recovery,
                        partial_id: item.partialItem.partial_id,
                        student_id: studentId,
                        subject_id: item.subject.subject_id,
                    };
                })
        );

        const allCalifications = _.concat(updateRequest, createRequest);

        setSaveScoreStatus('pending');

        storeScores(allCalifications)
            .then(({ scores, subjects, student, group }) => {
                dispatch(upsertManyCalifications(scores));
                dispatch(upsertManySubjects(subjects));
                dispatch(upsertOneStudent(student));
                dispatch(upsertOneGroup(group));

                feedbackApp.showFeedback({
                    title: 'Guardado correctamente',
                });

                setSaveScoreStatus('fulfilled');
                setIsEditMode(false);
            })
            .catch((err) => {
                let feedbackError = FeedbackService.getMessage(err);
                setSaveScoreStatus('rejected');

                feedbackApp.showFeedback({
                    title: feedbackError.title,
                });
            });
    };

    /**
     * Actualziaro o crear calificaciones
     *
     * @param {*} scores
     */
    const storeScores = async (scores) => {
        const newScores = await Services.saveMutiCalifications(scores).then(
            (i) => i.data.data
        );

        const subjects = await Services.getSubjectsByStudent(studentId).then(
            (i) => i.data.data
        );
        const student = await Services.getStudentById(studentId).then(
            (i) => i.data.data
        );

        const groupId = student.group_id;

        let group = await Services.getGroupById(groupId).then(
            (i) => i.data.data
        );

        return {
            scores: newScores,
            subjects,
            student,
            group,
        };
    };

    const sortedCalificationsRows = [...calificationsRows].sort((a, b) => {
        const materiaA = a.name.value;
        const materiaB = b.name.value;

        if (materiaA < materiaB) return -1;
        if (materiaA > materiaB) return 1;

        return 0;
    });

    return (
        <div>
            <RequestPermission
                title="Solicitud de permiso"
                content="Para la edición de calificaciones es necesario su autorización"
                open={openRequestPermission}
                OnPermissionGrantedResolved={onAllowPermision}
            />

            <Card sx={{ mt: 4 }}>
                <CardContent
                    sx={{
                        pt: 0,
                        pl: 0,
                        pr: 0,
                        pb: 0,
                        ' & .MuiDataGrid-root': {
                            border: 'none',
                        },
                    }}
                >
                    <Box
                        sx={{
                            '& .partial-active--cell': {
                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                color: '#1a3e72',
                                fontWeight: '600',
                            },
                            '& .zero-value-cell': {
                                backgroundColor: '#ec5353',
                                color: '#ffffff',
                            },
                        }}
                    >
                        <DataGrid
                            localeText={
                                esES.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                            rows={sortedCalificationsRows}
                            columns={calificationsColumns}
                            getRowId={(item) => item.payload.subject_id}
                            pageSize={20}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            autoHeight
                            components={{ Toolbar: ScoresToolBar }}
                            disableDensitySelector
                            experimentalFeatures={{ newEditingApi: true }}
                            getCellClassName={getCellClassName}
                        />
                    </Box>
                </CardContent>
                <CardActions>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Stack
                                direction={'row'}
                                spacing={0.5}
                                justifyContent="start"
                                alignItems="center"
                            >
                                {Object.values(updateStatus).map((status) => {
                                    return (
                                        <Stack
                                            sx={{ color: status.color }}
                                            direction={'row'}
                                            spacing={0.5}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            {status.icon} {status.text}
                                        </Stack>
                                    );
                                })}
                            </Stack>
                        </Grid>
                        <Grid item xs={3}>
                            {isEditMode && (
                                <Box>
                                    Tiempo restante de edición ({countDown})
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={3}>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={2}
                            >
                                {!isEditMode && (
                                    <Button
                                        size="small"
                                        variant="contained"
                                        startIcon={<EditIcon />}
                                        onClick={onEditScores}
                                    >
                                        Editar
                                    </Button>
                                )}
                                {isEditMode && (
                                    <>
                                        <LoadingButton
                                            size="small"
                                            color="primary"
                                            onClick={onSaveScores}
                                            loading={
                                                saveScoreStatus == 'pending'
                                            }
                                            loadingPosition="start"
                                            startIcon={<SaveIcon />}
                                            variant="contained"
                                        >
                                            Guardar
                                        </LoadingButton>
                                        <Button
                                            size="small"
                                            startIcon={<DoDisturbIcon />}
                                            onClick={onCancelEdit}
                                            disabled={
                                                saveScoreStatus == 'pending'
                                            }
                                            variant="text"
                                            color="error"
                                        >
                                            Cancelar edición
                                        </Button>
                                    </>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </div>
    );
};

export default ScoresCard;
